import { Button } from "@wac-ui-dashboard/wac_component_library";
import useStudentOtp from "./useStudentOtp";
import OTPInput from "react-otp-input";
import Style from "../myStudents.module.scss"

const StudentOtp = ({ refetch, closeOtpModal }) => {
  const { otp, errors, enteredEmail, handleSubmit, handleChange } =
    useStudentOtp({ refetch, closeOtpModal });

  return (
    <div className="pro-w-100 pro-py-5 pro-px-2">
      <div
        className=" pro-px-5 pro-pb-3"
        buttonText={"Submit"}
        handleClick={handleSubmit}
      >
        <div className="input-wrap  ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Enter OTP sent to {enteredEmail ?? "your email address"}
          </label>
          <div className={Style.otp_input_wrap}>
          <OTPInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderSeparator={<span>:</span>}
            renderInput={(props) => <input  {...props} />}
            containerStyle={"pro-w-100 pro-d-flex pro-justify-between pro-align-items pro-gap-2"}
          />
          </div>
          {errors && <span className="error-text">{errors}</span>}
          
          
        </div>
        <Button
          className={"pro-btn-primary lg pro-w-100 pro-mt-4"}
          type="submit"
          onClick={handleSubmit}
        >
          {"Verify OTP"}
        </Button>
      </div>
    </div>
  );
};

export default StudentOtp;
