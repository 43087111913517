import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UserFilterForm from "./UserFilterForm";
import { resetExtraFilters, setStoreFormValues } from "../../../../store/slices/user/userSlice";


const useFilter = ({setShowform,refetch, formData}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);

  const validationSchema = Yup.object({
    role: Yup.string().nullable(),
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    status: Yup.string().nullable(),
  });

  const initialValues = {
    role: state.role,
    start_date: state.start_date,
    end_date: state.end_date,
    status: state.status,
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { 
        role, 
        start_date,
        end_date, 
        status
      } = values;
      
      if (
        status === "" &&
        role === "" &&
        start_date === "" &&
        end_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      
      if (values) {
        dispatch(setStoreFormValues(values))
      }
    },
  });
  // useEffect(() => {
  //   // Check if role is not empty before refetching
  //   if (formik.values.role !== '') {
  //     refetch();
  //   }
  // }, [formik.values.role, refetch]);
  
  // useEffect(() => {
  //   // Check if status is not empty before refetching
  //   if (formik.values.status !== '') {
  //     refetch();
  //   }
  // }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <UserFilterForm
        formik={formik}
        handleReset={handleReset}
        formData={formData}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
