import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";

const DashboardHero = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  shimmerCount,
}) => {
  // Calculate the valid data count
  const validDataCount = Object.keys(data || {}).filter((key) => obj?.[key]?.title).length;

  return (
    <div className={`pro-w-100`}>
      <div
        className={`row g-${validDataCount || shimmerCount} row-cols-${validDataCount || shimmerCount} row-cols-lg-${validDataCount || shimmerCount}`}
      >
        {!loading ? (
          Object.keys(data || {}).map((key) => {
            if (obj?.[key]?.title) {
              return (
                <div
                  key={key}
                  className={`col ${
                    !transactions ? Style.dashboardCard_root : ""
                  }`}
                  onClick={
                    !transactions
                      ? () => obj?.[key]?.handleClick(obj?.[key]?.label)
                      : () => {}
                  }
                >
                  <DashboardCard
                    icon={obj?.[key]?.icon}
                    title={obj?.[key]?.title}
                    data={data?.[key]}
                    prev={obj?.[key]?.prev}
                    active={obj?.[key]?.label === listingType}
                    transactions={transactions}
                  />
                </div>
              );
            }
            return null;
          })
        ) : (
          [...Array(validDataCount || shimmerCount)].map((_, i) => (
            <div key={i} className="col">
              <DashboardCardShimmer />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardHero;