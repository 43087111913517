import { getAxiosInstance } from "../../../api";

export const updatePolicy = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/cms/privacy-policy/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
