import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/Announcement/announcementSlice.js";

const useAnnouncementForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.announcement);

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(60, "max 60 characters allowed")
      .required("*Required"),
      description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .max(500, "max 500 characters allowed")
      .required("*Required"),
      expiry_date: Yup.string().required("*Required")
  });


  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
      ? selectedItemsDetails.id
      : "",
      
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      description:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.description
          : "",
      expiry_date:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.expiry_date
          : "",
      
      status: selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : '',
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        name: values.name,
        description: values.description,
        expiry_date: values.expiry_date,
        status: +values?.status
        
      };
      
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        // formData.append("_method", "PUT");
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    is_edit,
  };
};

export default useAnnouncementForm;
