import React from "react";
import UseFaq from "./useFaq";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import FaqForm from "./FaqForm";
import OrderColumn from "../../Global/OrderColumn";

const Faq = () => {
  const {
    faqData,
    faqState,
    isLoading,
    isFetching,
    paginationOptions,
    showEditModal,
    updateTableFields,
    handleEditColumnsClick,
    handleEditAction,
    refetch,
    closeCreateModal,
    handleCreateClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleDelete,
    showDeleteConfirm,
    handleDeleteAction,
    setShowDeleteConfirm
  } = UseFaq();
  return (
    <>
      <HeadingGroup
        title={"FAQ"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditColumnsClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              searchInputProps={{ value: faqState?.search }}
            />
          </div>
        </div>

        {faqData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={faqData?.rows?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleEdit={handleEditAction}
            handleDelete={handleDelete}
            deletable={true}
            clear={faqState?.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={faqData?.field}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={faqState?.currentPageSize}
            showCheckBox={true}
          />
        )}

        {faqData?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={faqState?.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === faqState?.currentPageSize
            )}
            totalPageCount={faqData?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <OffCanvasLayout
        show={faqState?.showCreateModal}
        handleClose={closeCreateModal}
        title={faqState?.is_edit ? "Edit Faq" : "Add Faq"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <FaqForm refetch={refetch} closeModal={closeCreateModal} />
      </OffCanvasLayout>

      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={Object.keys(faqData?.field ?? {}).reduce(
              (filteredObj, property) => {
                filteredObj[property] = faqData?.field[property];
                return filteredObj;
              },
              {}
            )}
            moduleId={faqData?.module_id}
            updateData={updateTableFields}
          />
        </div>
      </ModalLayout>
      <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
    </>
  );
};

export default Faq;
