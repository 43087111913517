import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/TrainingSession/trainingSessionSlice.js";

const useTrainingSessionForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.trainingSession
  );

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(40, "max 40 characters allowed")
      .required("*Required"),
    mentor: Yup.string()
      .min(2, "The mentor name must be at least 2 characters")
      .max(40, "max 40 characters allowed")
      .required("*Required"),
    description: Yup.string(),
    meeting_link: Yup.string().required("*Required"),
    date: Yup.string().required("*Required"),
    start_time: Yup.string().required("*Required"),
    end_time: Yup.string().required("*Required"),
    participants: Yup.array().min(1, "Select one item").required(`Select item`),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== "" ? selectedItemsDetails.id : "",
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",
      mentor: selectedItemsDetails !== "" ? selectedItemsDetails?.mentor : "",
      description:
        selectedItemsDetails !== "" ? selectedItemsDetails?.description : "",
      meeting_link:
        selectedItemsDetails !== "" ? selectedItemsDetails?.meeting_link : "",
      date: selectedItemsDetails !== "" ? selectedItemsDetails?.date : "",
      start_time:
        selectedItemsDetails !== "" ? selectedItemsDetails?.start_time : "",
      end_time:
        selectedItemsDetails !== "" ? selectedItemsDetails?.end_time : "",
      participants:
        selectedItemsDetails && selectedItemsDetails?.participants?.length > 0
          ? selectedItemsDetails?.participants?.map((item) => item)
          : [],

      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("id", is_edit ? selectedId : "");
      formData.append("name", values?.name ?? "");
      formData.append("mentor", values?.mentor ?? "");
      formData.append("meeting_link", values?.meeting_link ?? "");
      formData.append("date", values?.date ?? "");
      formData.append("start_time", values?.start_time ?? "");
      formData.append("end_time", values?.end_time ?? "");
      formData.append("status", +values?.status);

      if (values?.participants?.length > 0) {
        values?.participants?.forEach((value, index) => {
          formData.append(`participants[${index}]`, value);
        });
      }

      if (values?.description?.length > 0 && values?.description !== "") {
        formData.append("description", values?.description);
      }

      // let formData = new FormData();
      // Object.keys(obj).forEach((key) => {
      //   return formData.append(key, obj[key]);
      // });

      if (selectedItemsDetails !== "") {
        // formData.append("_method", "PUT");
        dispatch(updateData({ data: formData })).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    is_edit,
  };
};

export default useTrainingSessionForm;
