import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetBasicDataQuery,
  useGetModuleBasicDataQuery,
} from "../../../../store/queries/global";
import {
  useCreateFaqDataMutation,
  useUdpateFaqDataMutation,
} from "../../../../store/queries/cms";
import { toast } from "react-toastify";

const UseFaqForm = ({ refetch, closeModal }) => {
  const faqState = useSelector((state) => state.faq);
  const { selectedItemsDetails, is_edit, selectedId } = useSelector(
    (state) => state.faq
  );
  const { data: basicData = {} } = useGetBasicDataQuery();
  const { data: moduleBasicData = {} } = useGetModuleBasicDataQuery();
  const [createFaqData] = useCreateFaqDataMutation();
  const [updateFaqData] = useUdpateFaqDataMutation();

  const FaqTypes = [
    {
      label: "Module",
      value: "1",
    },
    {
      label: "Package",
      value: "2",
    },
    {
      label: "Others",
      value: "3",
    },
  ];

  const initialData = {
    type: is_edit ? selectedItemsDetails?.data?.type : "",
    module_id: is_edit
      ? selectedItemsDetails?.data?.module_id !== null &&
        selectedItemsDetails?.data?.module_id
      : "",
    package_id: is_edit
      ? selectedItemsDetails?.data?.package_id !== null &&
        selectedItemsDetails?.data?.package_id
      : "",
    question: is_edit ? selectedItemsDetails?.data?.question : "",
    answer: is_edit ? selectedItemsDetails?.data?.answer : "",
    status: is_edit ? selectedItemsDetails?.data?.status : 0,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      type: Yup.string().required("* Type Required"),
      module_id: Yup.string().when("type", {
        is: (type) => type === "1" || type === 1,
        then: (schema) => schema.required("Module Id is required"),
      }),
      package_id: Yup.string().when("type", {
        is: (type) => type === "2" || type === 2,
        then: (schema) => schema.required("Package Id is required"),
      }),
      question: Yup.string().required("*Question  is Required"),
      answer: Yup.string().required("* Answer is Required"),
    }),

    onSubmit: (values) => {
      const obj = {
        type: +values?.type,
        question: values?.question,
        answer: values?.answer,
      };
      if (values?.type === "1" || values?.type === 1) {
        obj.module_id = +values?.module_id;
      }

      if (values?.type === "2" || values?.type === 2) {
        obj.package_id = +values?.package_id;
      }

      if (is_edit) {
        obj.id = +selectedId;
        obj.status = values?.status ? 1 : 0;
      }

      if (is_edit) {
        updateFaqData(obj).then((res) => {
          if (res?.data?.status_code === 200) {
            refetch();
            closeModal();
            toast.success("Faq updated successfully");
          } else if (res?.data?.status_code === 422) {
            Object.keys(res.data.errors).forEach((field) => {
              formik.setFieldError(field, res.data.errors[field]);
            });
          } else {
            toast.error("Failed to update Faq");
            closeModal();
          }
        });
      } else {
        createFaqData(obj).then((res) => {
          if (res?.data?.status_code === 200) {
            refetch();
            closeModal();
            toast.success("Faq created successfully");
          } else if (res?.data?.status_code === 422) {
            Object.keys(res.data.errors).forEach((field) => {
              formik.setFieldError(field, res.data.errors[field]);
            });
          } else {
            toast.error("Failed to create Faq");
            closeModal();
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleChangeType = (value) => {
    formik?.setFieldValue("type", value?.value || null);
    formik?.setFieldValue("module_id", "");
    formik?.setFieldValue("packge_id", "");
  };

  return {
    basicData,
    moduleBasicData,
    FaqTypes,
    formik,
    faqState,
    handleChangeType,
    getFieldError,
  };
};

export default UseFaqForm;
