import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTestimonialForm from "./useTestimonialForm";
import {
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";
import Style from "../testimonial.module.scss";

const TestimonialForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
    handleImage,
    imagePreview,
    is_edit,
    setIsType,
    handleVideosChange,
    thumbnail
  } = useTestimonialForm({ refetch, closeModal });

  let typeList = [
    {
      label: "Video",
      value: 1,
    },
    {
      label: "Text",
      value: 2,
    }
  ];

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

<div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type *
          </label>
          <Select

            id="type"
            isClearable={true}
            placeholder={"Select"}
            options={typeList}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            className={`pro-input lg ${
              formik.errors.type &&
              formik.touched.type &&
              "error"
            }`}
            value={typeList?.filter(
              (p) => p.value === formik.values.type
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur}
            onChange={(value) =>{
              formik.setFieldValue(`type`, value?.value || null);
              setIsType(value?.value);
            }
            }
          />
          {formik.touched.type && formik.errors.type && (
            <div className="error-text">{formik.errors.type}</div>
          )}
        </div>
      </div>

{formik?.values?.type === 1 && (
  <div className={Style.wrapper}>
        <label className="pro-mb-1 pro-font-sm pro-fw-medium">
          Video*
        </label>
        <div
          className={`pro-d-flex pro-input  pro-justify-center pro-flex-wrap pro-gap-3 pro-p-2 upload-wrap ${
            Style.upload_wrap
          } video-upload-wrap ${thumbnail ? "active" : ""}`}
        >
          <></>
          <label
            for="video-upload"
            className={`pro-w-100 pro-h-100 stretched-label`}
          ></label>
          {/* {showAddButton && ( */}
          <div
            className={`input-wrap  ${
              formik.touched?.video &&
              formik.errors?.video &&
              " error"
            }`}
          >
            {!thumbnail ? (
              <div className="pro-d-flex  pro-justify-center pro-items-center pro-flex-column pro-text-center">
                <span className="material-symbols-outlined x3">backup</span>
                <span className={`${Style.add_text} pro-fw-medium `}>
                  "Add Video"
                </span>
              </div>
            ) : (
              <div className="pro-input pro-h-100 pro-d-flex pro-justify-between pro-items-center">
                <div className="file-name ">{limitStrLength(thumbnail, 30)}</div>
                {/* <button  type="button" className="btn-close"></button> */}
              </div>
            )}
            <input
              type="file"
              className={`${Style.file} pro-d-none`}
              onChange={handleVideosChange}
              id="video-upload"
            />
          </div>
          {/* )} */}
          {formik.touched?.video && formik.errors?.video && (
            <span className="error-text">{formik.errors?.video}</span>
          )}
        </div>
      </div>
  )
}
      

      <Input
        label={"Comment*"}
        type="text"
        id="comment
          "
        name="comment
          "
        className={`pro-input lg ${
          formik.errors.comment && formik.touched.comment && "error"
        }`}
        {...formik.getFieldProps("comment")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.comment && formik.touched.comment}
        errorMessage={formik.errors.comment}
      />

<Input
        label={"Place*"}
        type="text"
        id="place
          "
        name="place
          "
        className={`pro-input lg ${
          formik.errors.place && formik.touched.place && "error"
        }`}
        {...formik.getFieldProps("place")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.place && formik.touched.place}
        errorMessage={formik.errors.place}
      />

<div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image
        </label>
        <div className={`input-drag ${imagePreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            // ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image &&
              formik.errors.image &&
              " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleImage(e)}
          />
          <span className="input-drag-box">
            {!imagePreview ? (
              <IconText
                title={
                  formik?.values?.image !== undefined
                    ? limitStrLength(formik?.values?.image, 30)
                    : selectedItemsDetails !== "" &&
                      selectedItemsDetails?.image
                    ? selectedItemsDetails?.image
                    : `Drop files to attach or browse`
                  // `Drop files to attach or browse`
                }
              />
            ) : (
              <div className="img-wrap">
                <span className="img-close">&#10006;</span>
                <Image
                  width={100}
                  height={100}
                  src={imagePreview}
                  alt={`branch - 01`}
                />
              </div>
            )}
          </span>
          {formik.touched.intro_banner && formik.errors.intro_banner && (
            <span className="error-text">{formik.errors.intro_banner}</span>
          )}
        </div>
      </div>

      {/* toggle button start  */}
      {is_edit && (
        <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      )}
      
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default TestimonialForm;
