import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";
import {
  createNewProgram,
  updateProgram,
} from "../../../../store/slices/program/programFormSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../../utils/functions/table";
import PriceForm from "./PriceForm";
import { updateConfig } from "../../../../store/slices/program/programFormSlice";
import { updateConfig as basincUpdateconfig } from "../../../../store/slices/program/programSlice";
import ModuleForm from "./ModuleForm";

const useMultiTabForm = (setShowform, refetch) => {
  const [moveToTab, setMoveToTab] = useState(false);

  const dispatch = useDispatch();

  const { editProgram, isLoading, selectedData, formActiveTab } = useSelector(
    (state) => state.programForm
  );

  const validationSchema = Yup.object({
    // Define your form field validations here
    category_id: Yup.string().required("Category is required"),
    module_name: Yup.string().required("Program Name is required"),
    module_description: Yup.string().required("Description is required"),
    related_disease: Yup.string().required("Release disease is required"),
    related_disease_description: Yup.string().required(
      "Description is required"
    ),
    colour_code: Yup.string().required("*Color code is required"),
    module_benefits: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("*Required"),
      })
    ),
    module_image: editProgram ? Yup.mixed() : Yup.mixed().required("*Required"),
    web_image: editProgram ? Yup.mixed() : Yup.mixed().required("*Required"),
    web_background_image: editProgram ? Yup.mixed() : Yup.mixed().required("*Required"),
    module_image: editProgram ? Yup.mixed() : Yup.mixed().required("*Required"),
    module_video: editProgram ? Yup.mixed() : Yup.mixed().required("*Required"),

    module_price: Yup.array().of(
      Yup.object().shape({
        country_id: Yup.string().required("*Required"),
        price: Yup.number()
          .typeError("Price must be a number")
          .min(0, "Minimum Price is 0")
          .required("*Required"),
        currency: Yup.string().required("*Required"),
        tax: Yup.string().required("*Required"),
        mrp_amount: Yup.number()
          .typeError("MRP Amount must be a number")
          .min(0, "Minimum MRP amount is 0")
          .required("*Required"),
        offer_percentage: Yup.number()
          .typeError("Offer percentage must be a number")
          .min(0, "Minimum Offer percentage is 0")
          .required("*Required"),
        // price_without_offer: Yup.number()
        //   .typeError("This Price without offer must be a number")
        //   .min(0, "Minimum Price without offer is 0")
        //   .required("*Required"),
        offer_tag: Yup.string().required("*Required"),
        offer_validity: Yup.string().required("Required"),
      })
    ),
    addons: Yup.array().min(1, "Select one item").required(`Select item`)
  });

  const initialData = {
    category_id: editProgram ? selectedData?.category_id : "",
    module_name: editProgram ? selectedData?.module_name : "",
    module_description: editProgram ? selectedData?.module_description : "",
    colour_code: editProgram ? selectedData?.colour_code : "",
    module_benefits:
      editProgram && selectedData?.module_benefits?.length > 0
        ? selectedData?.module_benefits?.map((item, index) => ({
            title: item?.title ?? "",
          }))
        : [
            {
              title: "",
            },
          ],
    module_video: "",
    module_image: "",
    web_background_image: "",
    web_image: "",
    related_disease: editProgram ? selectedData?.related_disease : "",
    related_disease_description: editProgram
      ? selectedData?.related_disease_description
      : "",
    module_image_path: editProgram ? selectedData?.module_image_path : "",
    module_price:
      selectedData && selectedData?.module_prices?.length > 0
        ? selectedData?.module_prices?.map((item, index) => ({
            country_id: item?.country_id ?? "",
            price: item?.price ?? "",
            currency: item?.currency ?? "",
            tax: item?.tax ?? "",
            mrp_amount: item?.mrp_amount ?? "",
            offer_percentage: item?.offer_percentage ?? "",
            // price_without_offer: item?.price_without_offer ?? "",
            offer_tag: item?.offer_tag ?? "",
            offer_validity: getFormatedDate(item?.created_at) ?? "",
          }))
        : [
            {
              country_id: "",
              price: "",
              currency: "",
              tax: "",
              mrp_amount: "",
              offer_percentage: "",
              // price_without_offer: "",
              offer_tag: "",
              offer_validity: "",
            },
          ],
          addons: selectedData && selectedData?.program_addons?.length > 0 ? 
    selectedData?.program_addons?.map((item) => item?.addon_id) : [],
  };
  const formik = useFormik({
    
    initialValues: initialData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("category_id", values?.category_id ?? "");
      formData.append("module_name", values?.module_name ?? "");
      formData.append("module_description", values?.module_description ?? "");
      formData.append("related_disease", values?.related_disease ?? "");
      formData.append(
        "related_disease_description",
        values?.related_disease_description ?? ""
      );
      formData.append("module_image", values?.module_image ?? "");
      formData.append("web_image", values?.web_image ?? "");
      formData.append(
        "web_background_image",
        values?.web_background_image ?? ""
      );
      formData.append("module_video", values?.module_video ?? "");
      formData.append("colour_code", values?.colour_code ?? "");

      if (editProgram) {
        formData.append("id", selectedData?.id);
      }

      if (values?.module_benefits?.length > 0) {
        values?.module_benefits?.forEach((value, index) => {
          formData.append(`module_benefits[${index}][title]`, value?.title);
        });
      }
      if (values?.addons?.length > 0) {
        values?.addons?.forEach((value, index) => {
          formData.append(`addons[${index}]`, value);
        });
      }
      if (values?.module_price?.length > 0) {
        values?.module_price?.forEach((value, index) => {
          formData.append(
            `module_price[${index}][country_id]`,
            value?.country_id
          );
          formData.append(`module_price[${index}][currency]`, value?.currency);
          formData.append(`module_price[${index}][price]`, value?.price);
          formData.append(`module_price[${index}][tax]`, value?.tax);
          formData.append(
            `module_price[${index}][mrp_amount]`,
            value?.mrp_amount
          );
          formData.append(
            `module_price[${index}][offer_percentage]`,
            value?.offer_percentage
          );
          // formData.append(
          //   `module_price[${index}][price_without_offer]`,
          //   value?.price_without_offer
          // );
          formData.append(
            `module_price[${index}][offer_tag]`,
            value?.offer_tag
          );
          formData.append(
            `module_price[${index}][offer_validity]`,
            value?.offer_validity
          );
        });
      }

      // let dispatchAction, successMessage;

      if (editProgram) {
        dispatch(updateProgram(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              setActiveTabByName("Basic");
              setShowform(false);
              resetForm();
              dispatch(
                basincUpdateconfig((state) => {
                  state.editProgram = false;
                  state.clearSelection = true;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.formActiveTab = "Basic";
                  state.selectedData = ""
                })
              );
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewProgram(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              setActiveTabByName("Basic");
              setShowform(false);
              resetForm();
              dispatch(
                basincUpdateconfig((state) => {
                  state.editProgram = false;
                  state.clearSelection = true;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.formActiveTab = "Basic";
                  state.selectedData = ""
                })
              );
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }

      // Handle form submission here
    },
  });

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "category_id",
        "module_name",
        "module_description",
        "module_benefits",
        "module_video",
        "module_image",
        "related_disease_description",
        "related_disease",
        "web_background_image",
        "web_image",
      ],
    },
    {
      label: "Price",
      fields: [
        "module_price",
        // "price",
        // "currency",
        // "tax",
        // "mrp_amount",
        // "price_without_offer",
        // "offer_tag",
        // "offer_validity",
      ],
    },
    {
      label: "Module",
      fields: [
        "addons"
      ],
    },
  ];

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "category_id",
        "module_name",
        "module_description",
        "title",
        "module_image",
        "module_video",
        "related_disease_description",
        "related_disease",
        "web_background_image",
        "web_image",
      ]),
    },
    {
      label: "Price",
      completed: calculateCompletionStatus([
        "country_id",
        "price",
        "currency",
        "tax",
        "mrp_amount",
        "offer_percentage",
        // "price_without_offer",
        "offer_tag",
        "offer_validity",
      ]),
    },
    {
      label: "Module",
      completed: calculateCompletionStatus([
        "addons",
      ]),
    },
  ];

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = updatedTab?.label;
      })
    );
  }

  function calculateCompletionStatus(fields) {
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else if (
        field === "country_id" ||
        field === "price" ||
        field === "offer_validity" ||
        field === "offer_tag" ||
        // field === "price_without_offer" ||
        field === "mrp_amount" ||
        field === "offer_percentage" ||
        field === "tax" ||
        field === "currency"
      ) {
        return !!formik?.values?.module_price?.[0]?.[field];
      } else if (field === "title") {
        return !!formik?.values?.module_benefits?.[0]?.[field];
      } else {
        return !!formik?.values[field];
      }
    });
    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    if (tab.label) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = tab?.label;
        })
      );
    }
  };

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
  }, [formik.errors]);

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab === "Basic") {
      setActiveTabByName("Basic");
      return "";
    }
    if (firstErrorTab === "Price") {
      setActiveTabByName("Price");
      return "";
    }
    if (firstErrorTab === "Module") {
      setActiveTabByName("Module");
      return "";
    }

    if (firstErrorTab && firstErrorTab !== formActiveTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const renderTabContent = () => {
    switch (formActiveTab) {
      case "Basic":
        return (
          <>
            <BasicForm
              formik={formik}
              // setActiveTab={formActiveTab}
              tabs={tabs}
              setShowform={setShowform}
              selectedItemsDetails={editProgram}
              selectedData={selectedData}
            />
          </>
        );
      case "Price":
        return (
          <PriceForm
            formik={formik}
            // setActiveTab={formActiveTab}
            tabs={tabs}
            setShowform={setShowform}
            isLoading={isLoading}
            checkError={handleMoveToErrorTab}
          />
        );
      case "Module":
        return (
          <ModuleForm
          formik={formik}
          tabs={tabs}
          setShowform={setShowform}
          isLoading={isLoading}
          checkError={handleMoveToErrorTab}
          />
        );
      default:
        return null;
    }
  };

  return {
    tabs,
    isLoading,
    activeTab: formActiveTab,
    handleTabClick,
    formik,
    renderTabContent,
    editProgram,
  };
};

export default useMultiTabForm;
