import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import Filter from "./Filter";
import TestimonialForm from "./TestimonialForm";
import useAgents from "./useAgents";
import AddAgent from "./AddAgent";

const Agents = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showEditModal,
    showDeleteConfirm,
    showCreateModal,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    filterShow,
    handleSearch,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDelete,
    setShowDeleteConfirm,
    handleDeleteAction,
    updateTableFields,
    closeModal,
    handleCreateClick,
    handleEditAction,
    handleDashboardRedirect,
    setFilterShow,
    handleClose,
  } = useAgents();

  return (
    <>
      {!dashboard && (
        <HeadingGroup
          title={"Agents"}
          className={`pro-mb-4`}
          buttonTitle={hasCreatePermission === 1 ? "Add new" : "Add new"}
          handleClick={handleCreateClick}
        />
      )}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard && (
          <div className="row">
            <div className="col">
              <SearchFilters
                searchable={true}
                showActions={true}
                onSearchInput={handleSearch}
                handleActionClick={handleEditClick}
                loading={isLoading}
                extraFilters={
                  <div className="col-auto">
                    <button
                      className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                      onClick={() => setFilterShow(true)}
                    >
                      <span className="material-symbols-outlined">tune</span>
                      <span>Filter</span>
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {mainData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div onClick={dashboard ? () => handleDashboardRedirect() : () => {}}>
            <Table
              data={mainData?.data?.rows?.data || []}
              uniqueID={"id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              showCheckBox={hasUpdatePermission === 1 ? true : true}
              deletable={hasDeletePermission}
              handleDelete={handleDelete}
              handleEdit={handleEditAction}
              clear={menuState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.field}
              SortIcon={!dashboard && <FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          </div>
        )}

        {mainData?.data?.rows?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          // title={"Add Branch"}
          title={menuState?.is_edit ? "Update Agents" : "Add Agents"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <TestimonialForm refetch={refetch} closeModal={closeModal} />
        </OffCanvasLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
        <OffCanvasLayout
          centered={true}
          show={filterShow}
          handleClose={() => handleClose()}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={handleClose} refetch={refetch} />
        </OffCanvasLayout>

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          // title={"Add Branch"}
          title={menuState?.is_edit ? "Update Agent" : "Add Agent"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddAgent refetch={refetch} closeModal={closeModal} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Agents;
