import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateBankInfo } from "../../../../store/slices/manageProfile/manageProfileSlice.js";

const useBankInfo = ({ closeModal, updateUserDetails }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.manageProfile);
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  const validation = Yup.object({
    account_name: Yup.string()
      .min(2, "Minium 2 charcters required")
      .max(50, "Maximum 50 Charcters allowed")
      .required("Account Name is Requird"),
    bank_name: Yup.string()
      .min(2, "Minium 2 charcters required")
      .max(50, "Maximum 50 Charcters allowed")
      .required("Bank Name is Requird"),
    ifsc: Yup.string()
      .matches(ifscRegex, "*Invalid IFSC code")
      .required("*Required"),
    bank_account: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      account_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.bank_account_data?.account_name
          : "",
      bank_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.bank_account_data?.bank_name
          : "",
      bank_account:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.bank_account_data?.bank_account_number
          : "",
      ifsc:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.bank_account_data?.ifsc_code
          : "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        bank_account: values?.bank_account,
        ifsc: values?.ifsc,
      };

      dispatch(updateBankInfo(obj)).then((response) => {
        if (response?.payload?.success) {
          updateBankAccountVerification(1);
          resetForm();
          closeModal();
          toast.success(response?.payload?.message);
        } else {
          toast.error(response?.payload?.message);
        }
      });
    },
  });

  const updateBankAccountVerification = (newStatus) => {
    // Retrieve the JSON object from localStorage
    const userDetailsString = localStorage.getItem("USER_DETAILS");

    if (userDetailsString) {
      // Parse the JSON string into a JavaScript object
      const userDetails = JSON.parse(userDetailsString);

      // Update the desired property
      userDetails.is_bank_account_verified = newStatus;
      updateUserDetails({ is_bank_account_verified: newStatus });

      // Convert the updated object back to a JSON string
      const updatedUserDetailsString = JSON.stringify(userDetails);

      // Store the updated JSON string back in localStorage
      localStorage.setItem("USER_DETAILS", updatedUserDetailsString);
    }
  };
  return {
    formik,
  };
};

export default useBankInfo;
