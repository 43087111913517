import {
  Button,
  HeadingGroup,
  ModalLayout,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import useMyTraining from "./useMyTraining";
import { Outlet, Link } from "react-router-dom";
import Style from "./myTraining.module.scss";
import EmptyData from "../../../Global/EmptyData";
import RegisterNowForm from "./RegisterNowForm";
import WebinarShimmer from "../../../Global/Shimmers/WebinarShimmer";

const MyTraining = () => {
  const { navigation, mainData, truncateText, splitDate, registerForm, menuState, closeModal, refetch } = useMyTraining();
  return (
    <>
      <HeadingGroup title={"Latest Webinars"} className={`pro-mb-6`} />
      <div className="pro-mt-4 pro-w-100  row row-cols-3 pro-gap-4 pro-mx-0 pro-mb-5">
        {mainData?.data ? (
         
          mainData?.data?.all_list?.length === 0 ? (
            <EmptyData />
          ) : (
          mainData?.data?.all_list?.map((item) => (
             <>
               <div className={`pro-d-flex col pro-items-center ${Style.training_card}`} key={item?.id}>
                 <div className={`${Style.date_time} pro-d-flex pro-flex-column  pro-items-center`}>
                     {splitDate(item?.date)} {item?.time}
                 </div>
                 <div className={Style.inner_wrap}>
                   <h6 className={`${Style.title} `}>{item?.name}</h6>
                   <p className={`${Style.desc}`}>
                     {truncateText(item?.description, 60)}
                   </p>
                   <p className={`${Style.mentor} pro-m-0`}>
                     {item?.mentor}
                   </p>
                   <div className="pro-mt-5">
                     {item?.is_registered === true ? (
                       <span className="pro-badge badge-warning">Registered</span>
                     ) : (
                       <Button className="pro-btn pro-btn-primary" onClick={() => registerForm(item)}>Register Now</Button>
                     )}
                   </div>
                   
                 </div>
               </div>
             </>
           ))
         )
        ):(
          <WebinarShimmer/>
        )} 
        
      </div>

      <HeadingGroup title={"All Trainings"} className={`pro-mb-4`} />
      <div className={`${Style.main_container} pro-pt-4 `}>
        <div className={` tab_wrapper`}>
          <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
          <Outlet />
        </div>
      </div>
      <ModalLayout
          show={menuState.showCreateModal}
          handleClose={closeModal}
          title={
            "Training: Register Now!"
          }
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          className={`modal-register ${Style.register_modal}`}
        >
          <RegisterNowForm refetch={refetch} closeModal={closeModal} />
        </ModalLayout>
    </>
  );
};

export default MyTraining;
