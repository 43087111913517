import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  startDate: "",
  endDate: "",
  status: "",
};

export const getEditDataByID = createAsyncThunk(
  "/v1/agent-coupon/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/agent-coupon/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewEntry = createAsyncThunk(
  "/v1/agent-coupon/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/agent-coupon/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/v1/agent-coupon/update",
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent-coupon/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/agent-coupon/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/agent-coupon/delete?id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendPayouts = createAsyncThunk(
  "/v1/admin-agent/send-payout",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-agent/send-payout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generatePayList = createAsyncThunk(
  "/v1/admin-agent/genarate-payout",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-agent/genarate-payout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTypeList = createAsyncThunk(
  "v1/basic-data",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/basic-data");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const agentPayoutsSlice = createSlice({
  name: "agentPayouts",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.status = action.payload.status;
    },
    resetExtraFilters: (state, action) => {
      state.startDate = "";
      state.endDate = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getEditDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getEditDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getEditDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    
  },
  
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } = agentPayoutsSlice.actions;

export default agentPayoutsSlice.reducer;
