import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../manageProfileForm.module.scss";
import useAadharInfo from "./useAadharInfo";

const AadharInfo = ({ refetch, closeModal }) => {
  const { formik, handleOtpSend, referId } = useAadharInfo({
    refetch,
    closeModal,
  });
  return (
    <div className={Style.form_wrap}>
      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Aadhar Number *
        </label>
        <input
          type="text"
          name="aadhaar_number"
          placeholder="Aadhar Number"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.aadhaar_number &&
            formik.touched.aadhaar_number &&
            "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.aadhaar_number}
        />
        {formik.touched.aadhaar_number && formik.errors.aadhaar_number && (
          <div className="error-text">{formik.errors.aadhaar_number}</div>
        )}
      </div>
      {referId === "" && (
        <Button
          className={"pro-btn-primary lg "}
          onClick={() => handleOtpSend(formik?.values?.aadhaar_number)}
          type="button"
        >
          {"Send OTP"}
        </Button>
      )}
      {referId !== "" && (
        <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Enter OTP *
          </label>
          <input
            type="text"
            name="otp"
            placeholder="Enter OTP"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.otp && formik.touched.otp && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.otp}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className="error-text">{formik.errors.otp}</div>
          )}
        </div>
      )}

      {referId !== "" && (
        <div className={` pro-d-flex  `}>
          <Button
            className={"pro-btn-primary lg pro-me-3"}
            onClick={formik?.handleSubmit}
            type="button"
          >
            {"Update"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AadharInfo;
