import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import useCoupons from "./useCoupons";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import AddCoupon from "./AddCoupon";
import Filter from "./Filter";
import OrderColumn from "../../Global/OrderColumn";

const Coupons = () => {
  const {
    mainData,
    menuState,
    isLoading,
    isFetching,
    currentPage,
    showCreateModal,
    paginationOptions,
    is_edit,
    showDeleteConfirm,
    filterShow,
    formData,
    showEditModal,
    handleSearch,
    handleEditClick,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleCreateClick,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    setFilterShow,
    closeEditModal,
    updateTableFields,
  } = useCoupons();

  return (
    <>
      <HeadingGroup
        title={"Coupons"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
              extraFilters={
                <div className="col-auto">
                  <button
                    className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                    onClick={() => setFilterShow(true)}
                  >
                    <span className="material-symbols-outlined">tune</span>
                    <span>Filter</span>
                  </button>
                </div>
              }
            />
          </div>
        </div>
        {mainData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.rows?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            showCheckBox={true}
            deletable={true}
            editable={true}
            handleEdit={handleEditAction}
            handleDelete={handleDelete}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.field}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
        )}

        {mainData?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={is_edit ? "Edit Coupon" : "Add Coupon"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddCoupon setShowform={closeModal} refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          centered={true}
          show={filterShow}
          handleClose={() => {
            setFilterShow(false);
          }}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter
            setShowform={setFilterShow}
            refetch={refetch}
            formData={formData?.data?.coupon_status}
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Coupons;
