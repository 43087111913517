import DashboardHero from "../DashboardHero";
import useDashboardListing from "./useDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";
import {
  DataContainer,
  NavGroup,
} from "@wac-ui-dashboard/wac_component_library";
import Charts from "../../Global/Charts";
import Select from "react-select";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import SellerAnnouncements from "../../SellerModule/SellerAnnouncement/sellerAnnouncements";
import MyStudents from "../../SellerModule/StudentActivity/myStudents";
import { Link } from "react-router-dom";
import Customers from "../../Customers/customer";
import Agents from "../../Agent/agents";
import ChartPreloader from "../../Global/ChartPreloader";
import BarGraphShimmer from "../../Global/Shimmers/BarGraphShimmer";
import DonutGraphShimmer from "../../Global/Shimmers/DonutGraphShimmer";
import LineGraphShimmer from "../../Global/Shimmers/LineGraphShimmer";
import AgentListingShimmer from "../../Global/Shimmers/AgentListingShimmer";

const DashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    listingType,
    doughnutData,
    doughnutOptions,
    barData,
    barOptions,
    dashboardState,
    handleCurrencyChange,
    handleCountryChange,
    handleDateRangeChange,
    date,
    sellerDashboardItems,
    sellerDashboardData,
    activeUser,
    sellerFetching,
    sellerBarData,
    sellerBarOptions,
    sellerDoughnutData,
    tabNavs,
    barOrderData,
    activeTab,
    barFunnelData,
    barModuleData,
    setActiveTab,
    isSelectedAll,
    isSelectedPending,
    isSelectedRejected,
    isSelectedApproved,
    lineData,
    options
  } = useDashboardListing();


  let currencyList = [
    {
      label: "INR",
      value: "INR",
    },
    {
      label: "AED",
      value: "AED",
    },
  ];

  let countryList = [
    {
      label: "INDIA",
      value: "INR",
    },
    {
      label: "DUBAI",
      value: "AED",
    },
  ];

  return (
    <div className={Style.dashboard_wrap}>
       <div className={`row pro-justify-between pro-items-end pro-bg-white  pro-border-bottom ${Style.dash_heading}`}>
        {activeUser?.id === 1 && (
          <div className="col pro-ps-5">
            <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
          </div>
        )}

        <div className="col-auto pro-py-4 pro-ms-auto pro-pe-5 ">
          <CustomDateRangePicker
            defaultDateRange={date}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
      </div>
      {/* {activeUser?.id === 1 && (
        <div className="row row-cols-3 gx-3 pro-pt-5">
        <div>
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Currency
            </label>
            <Select
              id="currency"
              // isClearable={true}
              placeholder={"Select"}
              className={`pro-input lg`}
              classNamePrefix="pro-input"
              options={currencyList}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              value={currencyList?.find(
                (currency) => currency?.value === dashboardState?.currency
              )}
              onChange={(value) => handleCurrencyChange(value)}
            />
          </div>
        </div>
      </div>
      )} */}

      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={
            activeUser?.id === 1
              ? activeTab === "students"
                ? dashboardData?.data?.student
                : dashboardData?.data?.agent
              : sellerDashboardData?.data
          }
          obj={activeUser?.id === 1 ? dashboardItems : sellerDashboardItems}
          loading={activeUser?.id === 1 ? isFetching : sellerFetching}
          listingType={listingType}
          shimmerCount={5}
        />
      </div>

      {activeUser?.id === 1 ? (
        <div className="row row-cols-4 gx-5 pro-mb-5">
          {activeTab === "students" ? (
            <>
              <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className="col">
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Order Status</h6>
                      </div>
                    </div>  
                    
                    {isFetching ? (
                      <DonutGraphShimmer/>
                      
                    ) : doughnutData?.datasets?.[0].data?.length === 0 ? (
                      
                      <EmptyData />
                    ) : (
                      <>
                        <Charts
                          type="doughnut"
                          labels={doughnutData?.labels}
                          datasets={doughnutData?.datasets}
                          options={doughnutOptions}
                        />
                      </>
                    )}
                  </DataContainer>
              </div>
              <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className={` col ${Style.graph_box}`}>
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales Funnel</h6>
                      </div>
                      <div className="col input-wrap">
                          <Select
                            id="country_code"
                            // isClearable={true}
                            placeholder={"Select"}
                            className={`pro-input `}
                            classNamePrefix="pro-input"
                            options={countryList}
                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => option?.label}
                            value={countryList?.find(
                              (country_code) =>
                                country_code?.value ===
                                dashboardState?.country_code
                            )}
                            onChange={(value) => handleCountryChange(value)}
                          />
                      </div>
                    </div>
                    {isFetching ? (
                      <BarGraphShimmer/>
                        ) : barData?.datasets?.[0].data?.length === 0 ? (
                          <EmptyData />
                        ) : (
                          <>
                            <Charts
                              type="bar"
                              labels={barData?.labels}
                              datasets={barData?.datasets}
                              options={barOptions}
                            />
                        
                            <ul className={`pro-mt-5 row row-cols-2 gx-2  ${Style.bullet_list}`}>
                              {barData?.labels?.map((item, i) => (
                                <li key={i} className={`${Style.bullet_item} col`}>
                                  <span
                                    className={`${Style.bullet}`}
                                    style={{
                                      backgroundColor: barData?.datasets?.[0]?.backgroundColor?.[i],
                                    }}
                                  ></span>
                                  {item}
                                </li>
                              ))}
                            </ul>
                        </>
                        )}
                  </DataContainer>
              </div>
              <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className="col">
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Total Orders</h6>
                      </div>
                      {/* <div className="col">
                        <div className="input-wrap">
                          <Select
                            id="country_code"
                            // isClearable={true}
                            placeholder={"Select"}
                            className={`pro-input `}
                            classNamePrefix="pro-input"
                            options={countryList}
                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => option?.label}
                            value={countryList?.find(
                              (country_code) =>
                                country_code?.value === dashboardState?.country_code
                            )}
                            onChange={(value) => handleCountryChange(value)}
                          />
                        </div>
                      </div> */}
                    </div>  
                    {isFetching ? (
                      <BarGraphShimmer/>
                        ) : barOrderData?.datasets?.[0].data?.length === 0 ? (
                          <EmptyData />
                        ) : (
                          <>
                            <Charts
                              type="bar"
                              labels={barOrderData?.labels}
                              datasets={barOrderData?.datasets}
                              options={barOptions}
                            />
                          
                            <ul className={`pro-mt-5 row row-cols-2 gx-2 ${Style.bullet_list}`}>
                              {barOrderData?.labels?.map((item, i) => (
                                <li key={i} className={`${Style.bullet_item} col`}>
                                  <span
                                    className={`${Style.bullet}`}
                                    style={{
                                        backgroundColor: barOrderData?.datasets?.[0]?.backgroundColor?.[i],
                                    }}
                                  ></span>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                    </DataContainer>
              </div>
              <div className={`col ${Style.no_pad_box} `}>
                <DataContainer>
                <div className={`pro-items-center  pro-p-4 pro-border-bottom  pro-mb-0`}>
                    <h6 className="pro-ttl pro-fw-medium h6 pro-mb-0">Agents</h6>
                  </div>
                  <div className="col dash_scroll_wrap  scrollbar">
                    {isFetching ? (
                      <AgentListingShimmer/>
                    ) : dashboardData?.data?.agent?.top_agent_list?.length === 0 ? (
                          <EmptyData />
                    ) : (
                      <ul className={Style.list_root}>
                        {dashboardData?.data?.agent?.top_agent_list?.map(
                          (item, index) => (
                            <li
                              // key={item?.id}
                              className={`${Style.list_item} pro-border-bottom pro-rounded-0 `}
                            >
                              <a className="pro-pnt pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
                                <figure className={Style.agent_name}>
                                  <img src={item?.image} width={32} />
                                  <figcaption>{item?.name}</figcaption>
                                </figure>
                                <span className={`${Style.count} pro-border-left pro-ps-4  pro-d-flex pro-justify-between pro-items-center`}><span>{item?.total_sales_count}</span></span>
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                      )}
                  </div>
                </DataContainer>
              </div>
              
              <div className={`col pro-w-100 pro-mt-5  ${Style.table_box} `} >
                <DataContainer>
                  <div className={`pro-items-center  pro-p-4 pro-border-bottom  pro-mb-0`}>
                      <h6 className="pro-ttl pro-fw-medium h6 pro-mb-0">Recent Users</h6>
                  </div>
                  <Customers dashboard={true} />
                </DataContainer>
              </div>
            </>
          ) : (
            <>
              <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className="col">
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales Funnel</h6>
                      </div>
                      <div className="col input-wrap">
                          <Select
                            id="country_code"
                            // isClearable={true}
                            placeholder={"Select"}
                            className={`pro-input `}
                            classNamePrefix="pro-input"
                            options={countryList}
                            getOptionValue={(option) => option?.value}
                            getOptionLabel={(option) => option?.label}
                            value={countryList?.find(
                              (country_code) =>
                                country_code?.value ===
                                dashboardState?.country_code
                            )}
                            onChange={(value) => handleCountryChange(value)}
                          />
                      </div>
                    </div>
                        {isFetching ? (
                          <BarGraphShimmer/>
                        ) : barFunnelData?.datasets?.[0].data?.length === 0 ? (
                          <EmptyData />
                        ) : (
                          <>
                            <Charts
                              type="bar"
                              labels={barFunnelData?.labels}
                              datasets={barFunnelData?.datasets}
                              options={barOptions}
                            />
                            <ul className={`pro-mt-5 row row-cols-2 gx-2 ${Style.bullet_list}`}>
                              {barFunnelData?.labels?.map((item, i) => (
                                <li key={i} className={`${Style.bullet_item } col`}>
                                  <span
                                    className={`${Style.bullet}`}
                                    style={{
                                        backgroundColor: barFunnelData?.datasets?.[0]?.backgroundColor?.[i],
                                    }}
                                  ></span>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                  </DataContainer>
              </div>
              <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className="col">
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales Trends</h6>
                      </div>
                    </div>  
                    {/* <div className="row row-cols-3 gx-3 pro-pt-5">
                <div>
                  <div className="input-wrap">
                    <Select
                      id="country_code"
                      // isClearable={true}
                      placeholder={"Select"}
                      className={`pro-input lg`}
                      classNamePrefix="pro-input"
                      options={countryList}
                      getOptionValue={(option) => option?.value}
                      getOptionLabel={(option) => option?.label}
                      value={countryList?.find(
                        (country_code) =>
                          country_code?.value === dashboardState?.country_code
                      )}
                      onChange={(value) => handleCountryChange(value)}
                    />
                  </div>
                </div>
              </div> */}
                      {isFetching ? (
                        <BarGraphShimmer/>
                      ) : barModuleData?.datasets?.[0].data?.length === 0 ? (
                        <EmptyData />
                      ) : (
                        <>
                          <Charts
                            type="bar"
                            labels={barModuleData?.labels}
                            datasets={barModuleData?.datasets}
                            options={barOptions}
                          />
                          <ul className={`pro-mt-5 row row-cols-2 gx-2 ${Style.bullet_list}`}>
                            {barModuleData?.labels?.map((item, i) => (
                              <li key={i} className={`${Style.bullet_item} col `}>
                                <span
                                  className={`${Style.bullet}`}
                                  style={{
                                    backgroundColor: barModuleData?.datasets?.[0]?.backgroundColor?.[i],
                                  }}
                                ></span>
                                {item}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                  </DataContainer>
              </div>
              {/* <div className={` col ${Style.graph_box} flex-fill`}>
                  <DataContainer>
                    <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                      <div className="col">
                        <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Agents</h6>
                      </div>
                    </div>  
                    <ul className={Style.list_root}>
                      {dashboardData?.data?.agent?.top_agent_list?.map(
                        (item, index) => (
                          <li
                            // key={item?.id}
                            className={`${Style.list_item} pro-mb-3 `}
                          >
                            <a className="pro-pnt pro-p-5 pro-d-block ">
                              <span>
                                <img src={item?.image} />
                                {item?.name}
                              </span>
                              <span>{item?.total_sales_count}</span>
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </DataContainer>
              </div> */}
              <div className={`col-4 ${Style.no_pad_box} `}>
                <DataContainer>
                <div className={`pro-items-center  pro-p-4 pro-border-bottom  pro-mb-0`}>
                    <h6 className="pro-ttl pro-fw-medium h6 pro-mb-0">Agents</h6>
                  </div>
                  <div className="col dash_scroll_wrap  scrollbar">
                      {isFetching ? (
                        <AgentListingShimmer/>
                      ) : dashboardData?.data?.agent?.top_agent_list?.length === 0 ? (
                          <EmptyData />
                      ) : (
                        <ul className={Style.list_root}>
                          {dashboardData?.data?.agent?.top_agent_list?.map(
                            (item, index) => (
                              <li
                                // key={item?.id}
                                className={`${Style.list_item} pro-border-bottom pro-rounded-0 `}
                              >
                                <a className="pro-pnt pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
                                  <figure className={Style.agent_name}>
                                    <img src={item?.image} width={32} height={32} />
                                    <figcaption>{item?.name}</figcaption>
                                  </figure>
                                  <span className={`${Style.count} pro-border-left pro-ps-4  pro-d-flex pro-justify-between pro-items-center`}><span>{item?.total_sales_count}</span></span>
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                  </div>
                </DataContainer>
              </div>
              <div className={`col pro-w-100 pro-mt-5  ${Style.table_box}  flex-fill `}>
                <DataContainer>
                <div className={` pro-d-flex pro-items-center pro-justify-between  pro-py-4 pro-border-bottom box-heading`}>
                  <div className="col pro-px-4">
                    <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Requested Agents</h6>
                  </div>
                  <div className="col-auto pro-mx-4">
                    <ul className="pro-d-flex pro-gap-5 ">
                      <li
                        className={`${Style.tab_btn} ${
                          isSelectedAll && Style.active
                        }`}
                        onClick={() => {
                          setActiveTab("All");
                        }}
                      >
                        All
                      </li>
                      <li
                        className={`${Style.tab_btn} ${
                          isSelectedPending && Style.active
                        }`}
                        onClick={() => {
                          setActiveTab("Pending");
                        }}
                      >
                        Pending
                      </li>
                      <li
                        className={`${Style.tab_btn} ${
                          isSelectedRejected && Style.active
                        }`}
                        onClick={() => {
                          setActiveTab("Rejected");
                        }}
                      >
                        Rejected
                      </li>
                      <li
                        className={`${Style.tab_btn} ${
                          isSelectedApproved && Style.active
                        }`}
                        onClick={() => {
                          setActiveTab("Approved");
                        }}
                      >
                        Approved
                      </li>
                    </ul>
                  </div>
                </div>

                  <div className="col">
                    <Agents dashboard={true} />
                  </div>
                </DataContainer>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="row row-cols-3 gx-5 pro-mb-5">
           
            <div className={`col  ${Style.graph_box} flex-fill`}>
            <DataContainer>
              <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                <div className="col">
                  <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales by Module</h6>
                </div>
              </div>
              {sellerFetching ? (
                  <DonutGraphShimmer/>
                ) : sellerDoughnutData?.datasets?.[0].data?.length === 0 ? (
                  <EmptyData />
                ) : (
                  <>
                    <Charts
                      type="doughnut"
                      labels={sellerDoughnutData?.labels}
                      datasets={sellerDoughnutData?.datasets}
                      options={doughnutOptions}
                    />
                  </>
              )}
            </DataContainer>
            </div>
          
            <div className={` col ${Style.graph_box} flex-fill`}>
              <DataContainer> 
                <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                  <div className="col">
                    <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales Funnel</h6>
                  </div>
                  <div className="col input-wrap">
                      <Select
                        id="country_code"
                        // isClearable={true}
                        placeholder={"Select"}
                        className={`pro-input`}
                        classNamePrefix="pro-input"
                        options={countryList}
                        getOptionValue={(option) => option?.value}
                        getOptionLabel={(option) => option?.label}
                        value={countryList?.find(
                          (country_code) =>
                            country_code?.value === dashboardState?.country_code
                        )}
                        onChange={(value) => handleCountryChange(value)}
                      />
                  </div>
                </div>
                {sellerFetching ? (
                  <BarGraphShimmer/>
                ) : sellerBarData?.datasets?.[0].data?.length === 0 ? (
                  <EmptyData />
                ) : (
                  <>
                      <Charts
                        type="bar"
                        labels={sellerBarData?.labels}
                        datasets={sellerBarData?.datasets}
                        options={sellerBarOptions}
                      />
                      <ul
                            className={`pro-mt-5 row row-cols-2 row-cols-lg-3 gx-2 ${Style.bullet_list}`}
                          >
                            {sellerBarData?.labels?.map((item, i) => (
                              <li
                                key={i}
                                className={`${Style.bullet_item} col`}
                              >
                                <span
                                  className={`${Style.bullet}`}
                                  style={{
                                    backgroundColor: sellerBarData?.datasets?.[0]?.backgroundColor?.[i],
                                  }}
                                ></span>
                                {item}
                              </li>
                            ))}
                          </ul>
                  </>
                )}
              </DataContainer>
            </div>

            <div className={`${Style.graph_box} ${Style.box_lg}  ${Style.line_graph} flex-fill`}>
            <DataContainer>
              <div className={`row pro-items-center pro-py-3 pro-border-bottom box-heading`}>
                <div className="col">
                  <h6 className="pro-ttl h6 pro-fw-medium pro-mb-0">Sales Trends</h6>
                </div>
              </div>
              {sellerFetching ? (
                  <LineGraphShimmer/>
                ) : lineData?.datasets?.[0].data?.length === 0 ? (
                  <EmptyData />
                ) : (
                  <>
                    <Charts
                      type="line"
                      labels={lineData?.labels}
                      datasets={lineData?.datasets}
                      options={options}
                    />
                  </>
                )}
            </DataContainer>
          </div>
          <div className="col-12 pro-w-100 flex-fill pro-mt-5 ">
            <div className={`row`}>
              <div className={`col-8   ${Style.table_box} `} >
                <DataContainer>
                  <div className={`pro-items-center  pro-p-4 pro-border-bottom  pro-mb-0`}>
                      <h6 className="pro-ttl pro-fw-medium h6 pro-mb-0">Recent Purchases</h6>
                  </div>
                    <MyStudents
                      dashboard={true}
                    />
                </DataContainer>
              </div>
              <div className={`col-4 ${Style.no_pad_box} `}>
                <DataContainer>
                <div className={`pro-items-center  pro-p-4 pro-border-bottom  pro-mb-0`}>
                    <h6 className="pro-ttl pro-fw-medium h6 pro-mb-0">Announcements</h6>
                  </div>
                  <div className="col dash_scroll_wrap  scrollbar">
                    <SellerAnnouncements
                      dashboard={true}
                    />
                  </div>
                </DataContainer>
              </div>
            </div>

          </div>
            
            
          </div>
          
          
          
        </>
      )}

      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) 
      : (
        <></>
        // <>
        //   <EmptyData />
        // </>
      )}
    </div>
  );
};

export default DashboardListing;
