import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useDeleteProgramPriceMutation,
  useGetPriceDataQuery,
} from "../../../../store/queries/program";
import {
  programPriceEditData,
  updateConfig,
} from "../../../../store/slices/program/programPriceSlice";
import { toast } from "react-toastify";

const useProgramPrices = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { programID } = useParams();
  const navigate = useNavigate();
  const activeProfile = programID ?? sessionStorage.getItem("active");
  const priceState = useSelector((state) => state.programPrice);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [deleteProgramPrice] = useDeleteProgramPriceMutation();
  const {
    data: priceList = {},
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetPriceDataQuery({
    program_id: activeProfile,
    page_size: priceState?.currentPageSize,
    page: priceState?.currentPage,
    sort_by: priceState?.sortBy,
    sort_order: priceState?.sortOrder,
    search: priceState?.search,
  });

  useEffect(() => {
    if (isError) {
      toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (priceState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = priceState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (feild, data) => {
        return <div>{data?.["country"]?.name}</div>;
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.programPriceEdit = false;
        state.programPriceData = {};
      })
    );
  };

  const handleClosePriceModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(programPriceEditData(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.programPriceEdit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handleDeleteAction = (data) => {
    const id = data?.[0];
    const obj = {
      price_id: id,
    };
    deleteProgramPrice(obj).then((res) => {
      if (res?.data?.status_code === 200) {
        refetch();
        toast.success("Price deleted successfully");
      } else {
        toast.error("Failed to delete price");
      }
    });
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    priceList,
    paginationOptions,
    priceState,
    handleDeleteAction,
    handleClosePriceModal,
    handleCreateClick,
    handleEditAction,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    closeEditModal,
    handleEditColumnsClick,
  };
};

export default useProgramPrices;
