import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("branch_id")}${getParams(
          "branch_currency_id"
        )}${getParams("start_date")}${getParams("end_date")}${getParams(
          "filter"
        )}${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.per_page || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const cms = createApi({
  reducerPath: "cmsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["privacyPolicy", "TermsAndCondition", "Faq"],
  endpoints: (builder) => ({
    getPrivacyPolicyData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/cms/privacy-policy`,
      }),
      providesTags: ["privacyPolicy"],
    }),
    getTermsAndConditionData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/cms/terms-and-conditions`,
      }),
      providesTags: ["TermsAndCondition"],
    }),
    getFaqData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/faqs`,
      }),
      providesTags: ["Faq"],
    }),
    createFaqData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/faqs/create`,
      }),
      invalidatesTags: ["Faq"],
    }),
    udpateFaqData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/faqs/update`,
      }),
      invalidatesTags: ["Faq"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/user-fields-save`,
      }),
      invalidatesTags: ["Faq"],
    }),
  }),
});

export const {
  useGetPrivacyPolicyDataQuery,
  useGetTermsAndConditionDataQuery,
  useGetFaqDataQuery,
  useCreateFaqDataMutation,
  useUdpateFaqDataMutation,
  useUpdateTableFieldsDataMutation,
} = cms;
