
import Style from './BarGraphShimmer.module.scss';

const BarGraphShimmer = () => {
  return (
    <div className={`${Style.root} pro-p-3`}>
        <div className="pro-d-flex pro-justify-between pro-mb-5">
            <div className="pro-py-3 pro-pb-2 pro-align-self-center pro-px-6 shimmer"></div>
            <div className="pro-py-4 pro-px-6 pro-ps-4 shimmer"></div>
        </div>
        <div className={`${Style.graph} pro-d-flex pro-d-flex pro-px-3 pro-gap-4 `}>
            <div className={`${Style.graph_item_wrap} pro-d-flex`}>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4 pro-me-1`}></div>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4`}></div>
            </div>

            <div className={`${Style.graph_item_wrap} pro-d-flex`}>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4 pro-me-1`}></div>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4`}></div>
            </div>
            
            <div className={`${Style.graph_item_wrap} pro-d-flex`}>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4 pro-me-1`}></div>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4`}></div>
            </div>

            <div className={`${Style.graph_item_wrap} pro-d-flex`}>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4 pro-me-1`}></div>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4`}></div>
            </div>

            {/* <div className={`${Style.graph_item_wrap} pro-d-flex`}>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4 pro-me-1`}></div>
                <div className={`${Style.graph_item} shimmer pro-px-3 pro-ps-4`}></div>
            </div> */}
        </div>
        <div className={` pro-d-flex row row-cols-3  pro-mt-4 `}>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2 `}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            
            
        </div>
    </div>
  )
}

export default BarGraphShimmer