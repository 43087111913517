import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import QuestionnairsFilterForm from "./QuestionnairsFilterForm";
import { resetExtraFilters, setStoreFormValues } from "../../../../store/slices/questionnaire/questionnaireSlice";


const useFilter = ({setShowform,refetch}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.questionnaire);

  const validationSchema = Yup.object({
    type: Yup.string().nullable(),
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    
  });

  const initialValues = {
    type: state.type,
    start_date: state.start_date,
    end_date: state.end_date,
    
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { 
        type, 
        start_date,
        end_date, 
      } = values;
      
      if (
        type === "" &&
        start_date === "" &&
        end_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      
      if (values) {
        dispatch(setStoreFormValues(values))
      }
    },
  });
  // useEffect(() => {
  //   // Check if type is not empty before refetching
  //   if (formik.values.type !== '') {
  //     refetch();
  //   }
  // }, [formik.values.type, refetch]);
  

  const renderTabContent = () => {
    return (
      <QuestionnairsFilterForm
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
