import React from "react";
import Select from "react-select";
import useProgramPriceForm from "./useProgramPriceForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const ProgramPriceForm = ({ isStickyFooter, closeModal, refetch }) => {
  const {
    formik,
    countryData,
    currencyList,
    programPriceEdit,
    handleEndDateChange,
  } = useProgramPriceForm({ closeModal, refetch });
  return (
    <>
      <div className={`pro-w-100`}>
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="country_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Country
            </label>
            <Select
              id="country_id"
              name="country_id"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.errors?.country_id &&
                formik.touched.country_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={countryData?.data}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              value={countryData?.data?.filter(
                (item) =>
                  item?.id?.toString() ===
                  formik?.values?.country_id?.toString()
              )}
              onBlur={formik.handleBlur("country_id")}
              onChange={(value) =>
                formik?.setFieldValue("country_id", value?.id || null)
              }
              menuPlacement="auto"
            />
            {formik.touched.country_id && formik.errors.country_id && (
              <div className="error-text">{formik.errors.country_id}</div>
            )}
          </div>
        </div>

        <Input
          label={"MRP Amount"}
          type="text"
          id="mrp_amount"
          name="mrp_amount"
          className={`pro-input lg ${
            formik.errors.mrp_amount && formik.touched.mrp_amount && "error"
          }`}
          {...formik.getFieldProps("mrp_amount")}
          onChange={(event) =>
            formik?.setFieldValue("mrp_amount", event?.target?.value)
          }
          error={formik.errors.mrp_amount && formik.touched.mrp_amount}
          errorMessage={formik.errors.mrp_amount}
        />

        <Input
          label={"Offer Percentage"}
          type="text"
          id="offer_percentage"
          name="offer_percentage"
          className={`pro-input lg ${
            formik.errors.offer_percentage &&
            formik.touched.offer_percentage &&
            "error"
          }`}
          {...formik.getFieldProps("offer_percentage")}
          onChange={(event) =>
            formik?.setFieldValue("offer_percentage", event?.target?.value)
          }
          error={
            formik.errors.offer_percentage && formik.touched.offer_percentage
          }
          errorMessage={formik.errors.offer_percentage}
        />

        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="currency"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Currency
            </label>
            <Select
              id="currency"
              name="currency"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.errors?.currency && formik.touched.currency && " error"
              }`}
              classNamePrefix="pro-input"
              options={currencyList}
              getOptionLabel={(option) => option?.label}
              getOptionValue={(option) => option?.value}
              value={currencyList?.filter(
                (item) =>
                  item?.value?.toString() ===
                  formik?.values?.currency?.toString()
              )}
              onBlur={formik.handleBlur("currency")}
              onChange={(value) =>
                formik?.setFieldValue("currency", value?.value || null)
              }
              menuPlacement="auto"
            />
            {formik.touched.currency && formik.errors.currency && (
              <div className="error-text">{formik.errors.currency}</div>
            )}
          </div>
        </div>

        <Input
          label={"Price"}
          type="text"
          id="price"
          name="price"
          className={`pro-input lg ${
            formik.errors.price && formik.touched.price && "error"
          }`}
          {...formik.getFieldProps("price")}
          onChange={(event) =>
            formik?.setFieldValue("price", event?.target?.value)
          }
          error={formik.errors.price && formik.touched.price}
          errorMessage={formik.errors.price}
        />

        <Input
          label={"Tax"}
          type="text"
          id="tax"
          name="tax"
          className={`pro-input lg ${
            formik.errors.tax && formik.touched.tax && "error"
          }`}
          {...formik.getFieldProps("tax")}
          onChange={(event) =>
            formik?.setFieldValue("tax", event?.target?.value)
          }
          error={formik.errors.tax && formik.touched.tax}
          errorMessage={formik.errors.tax}
        />

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Offer Validity
          </label>
          <Input
            className={`pro-input lg`}
            type="date"
            name="offer_validity"
            max={new Date().toISOString().split("T")[0]}
            onChange={handleEndDateChange}
            value={formik.values.offer_validity}
            error={
              formik.errors.offer_validity && formik.touched.offer_validity
            }
            errorMessage={formik.errors.offer_validity}
          />
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end pro-mt-2 ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            //   disabled={submitLoading}
            onClick={formik.handleSubmit}
          >
            {programPriceEdit ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProgramPriceForm;
