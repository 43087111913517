import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     startDate: null,
     endDate: null,
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
}



export const createNewCurrency = createAsyncThunk(
     "/admin/currencies/create",
     async (data, { rejectWithValue }) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.post("/admin/currencies/create", data);
         return response.data;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );


export const updateCurrency = createAsyncThunk(
     "/admin/currencies/update",
     async ({ data }, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post(`admin/currencies/update`, data)
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)




const currencySlice = createSlice({
     name: "currency",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     }
})

export const { updateConfig } = currencySlice.actions;

export default currencySlice.reducer;