import { useSelector } from "react-redux";
import { useGetListDataQuery } from "../../../../../store/queries/SellerModule/sellerTraining";
import { useParams } from "react-router-dom";
import { updateConfig } from "../../../../../store/slices/SellerModule/SellerTraining/sellerTrainingSlice.js";
import { useDispatch } from "react-redux";

const useAllTrainings = () => {
  const { tab } = useParams();
  const menuState = useSelector((state) => state.sellerTraining);
  const dispatch = useDispatch();

  const { data: mainData = {}, refetch } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  const registerForm = (item) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedItemsDetails = item;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
        state.showCreateModal = false;
      })
    );
  };

  return { mainData, tab, refetch, registerForm, closeModal, menuState };
};

export default useAllTrainings;
