import { useDispatch, useSelector } from "react-redux";
// import { useGetRateEditDataQuery } from "../../../../../store/queries/branches";
import { updateConfig } from "../../../store/slices/manageProfile/manageProfileSlice";
import { useEffect, useMemo, useState } from "react";

const useManageProfileForm = () => {
  const [isSelectedProfile, setIsSelectedProfile] = useState(true);
  const [isSelectedSecurity, setIsSelectedSecurity] = useState(false);
  const [isSelectedBank, setIsSelectedBank] = useState(false);
  const [isSelectedAadhar, setIsSelectedAadhar] = useState(false);
  const activeUser = useMemo(
    () => JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found",
    []
  );
  //   const [params] = useSearchParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { formActiveTab, selectedItemsDetails } = useSelector(
    (state) => state.manageProfile
  );

  useEffect(() => {
    if (activeUser?.id === 1) {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = "Profile";
        })
      );

      setIsSelectedProfile(true);
      setIsSelectedSecurity(false);
      setIsSelectedBank(false);
      setIsSelectedAadhar(false);
    } else {
      dispatch(
        updateConfig((state) => {
          state.formActiveTab = "Bank Info";
        })
      );

      setIsSelectedProfile(false);
      setIsSelectedSecurity(false);
      setIsSelectedBank(true);
      setIsSelectedAadhar(false);
    }

    //eslint-disable-next-line
  }, []);

  const setActiveTab = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.formActiveTab = tab;
      })
    );
    if (tab === "Profile") {
      setIsSelectedProfile(true);
      setIsSelectedSecurity(false);
      setIsSelectedBank(false);
      setIsSelectedAadhar(false);
    } else if (tab === "Security") {
      setIsSelectedProfile(false);
      setIsSelectedSecurity(true);
      setIsSelectedBank(false);
      setIsSelectedAadhar(false);
    } else if (tab === "Bank Info") {
      setIsSelectedProfile(false);
      setIsSelectedSecurity(false);
      setIsSelectedBank(true);
      setIsSelectedAadhar(false);
    } else {
      setIsSelectedProfile(false);
      setIsSelectedSecurity(false);
      setIsSelectedBank(false);
      setIsSelectedAadhar(true);
    }
  };

  return {
    setActiveTab,
    activeUser,
    formActiveTab,
    isSelectedProfile,
    isSelectedSecurity,
    isSelectedBank,
    isSelectedAadhar,
    selectedItemsDetails,
  };
};

export default useManageProfileForm;
