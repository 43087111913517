import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddAddons from "./useAddAddons";
// import ItemField from "./ItemFieldClick";
import Select from "react-select";
import ItemField from "./ItemFieldClick";
import Style from "../user.module.scss"

const AddAddons = ({ refetch, setShowform, programId }) => {
  const {
    getFieldError,
    formik,
    handleAddField,
    orderList,
    is_edit,
    handleContentChange,
    handleContentTextChange
  } = useAddAddons({setShowform, refetch, programId});


  return (
    <div className={`row`}>
      <Input
        label={"Bonus Program Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Description*"}
        type="text"
        id="description"
        name="description"
        className={`pro-input lg ${getFieldError("description") && " error"}`}
        {...formik.getFieldProps("description")}
        error={getFieldError("description")}
        errorMessage={getFieldError("description")}
      />
      <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Display Order *
              </label>
              <Select
                name="display_order"
                options={orderList} // replace with your display_order options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                value={orderList?.filter((m) => formik?.values?.display_order === m?.value)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(`display_order`, selectedOption?.value)
                }
                className={`pro-input lg ${
                  formik.touched?.display_order &&
                  formik.errors?.display_order &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`display_order`)}
                
              />
              {formik.touched?.display_order &&
                formik.errors?.display_order && (
                <div className="error-text">{formik.errors?.display_order}</div>
              )}
            </div>
          </div>
      <div className="col-12 pro-mb-4">
        <div className={`${Style.add_more_wrap}`}>
            {formik?.values?.bonus_program_content?.map((fields, index) => (
              <ItemField
                key={index}
                itmIndex={index}
                fields={fields}
                formik={formik}
                getFieldError={getFieldError}
                isEdit={is_edit}
                handleContentChange={handleContentChange}
                handleContentTextChange={handleContentTextChange}
              />
            ))}
            <Button
              className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
              onClick={handleAddField}
              // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
            >
              {` Add Content`}
            </Button>
        </div>
      </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={setShowform}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddAddons;
