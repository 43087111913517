import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils.js";
import {
  // getEditStudentData,
  // getEducationLevelData,
  updateConfig,
} from "../../../../store/slices/program/programSlice.js";
import { useGetBasicDetailsQuery } from "../../../../store/queries/customer/index.js";

const useUserDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const ProfileState = useSelector((state) => state.user);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});
  // const [updateStudentStatus] = useUpdateStudentStatusMutation();

  const navigation = [
    {
      label: "My Orders",
      title: "My Orders",
      link: `/user-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/user-details/${activeProfile}`, true),
    },
    // {
    //   label: "Purchase History",
    //   title: "Purchase History",
    //   link: `/user-details/purchase-history/${activeProfile}${window.location.search}`,
    //   //   isButton: false,
    //   active: checkIfActiveRoute(`/purchase-history/${activeProfile}`),
    // },
    {
      label: "Goals",
      title: "Goals",
      link: `/user-details/goals/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/goals/${activeProfile}`),
    },
  ];
  const navigate = useNavigate();

  const {
    data: basicData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetBasicDetailsQuery(activeProfile);

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = basicData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [basicData]);

  useEffect(() => {
    if (basicData?.statusCode === 400 || basicData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [basicData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    name: "Full Name",
    email: "Email",
    dob: "Date of Birth",
    mobile: "Mobile",
  };

  const value = {
    name: `${basicData?.data?.name ?? "Not specified"}`,
    dob: `${
      basicData?.data?.date_of_birth ?? "Not specified"
    }`,
    mobile: `${
      basicData?.data?.mobile ?? "Not specified"
    }`,
    email: `${basicData?.data?.email ?? "Not specified"}`,
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.isEdit = true;
        state.selectedID = basicData?.data?.student_detail?._id;
      })
    );
    // dispatch(getEducationLevelData());
    // dispatch(getEditStudentData(basicData?.data?.student_detail?._id));
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.isEdit = false;
        state.selectedID = {};
        state.imagePreview = "";
        state.imageName = "";
        state.studentData = [];
        state.educationLevel = [];
      })
    );
  };

  // useEffect(() => {
  //   if (statusUpdate) {
  //     updateStudentStatus(obj?.id).then((res) => {
  //       if (res?.data?.statusCode === 200) {
  //         toast.success(res?.data?.message);
  //         refetch();
  //       } else {
  //         toast.error(res?.data?.message);
  //       }
  //     });
  //     handleDeleteModal();
  //   }
  // }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };
  return {
    activeProfile,
    isFetching,
    printRef,
    closeModal,
    basicData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    popUpMessage,
    handleDeleteModal,
  };
};

export default useUserDetailsLayout;
