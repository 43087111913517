import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAgentDataQuery } from "../../../store/queries/agent/index.js";
import { useUpdateTableFieldsDataMutation } from "../../../store/queries/global/index.js";
import {
  deleteData,
  getEditDataByID,
  updateConfig,
} from "../../../store/slices/Agent/agentSlice.js";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import { getFormatedDate } from "../../../utils/functions/table.js";
import Style from "./agents.module.scss";

const useAgents = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.agent);

  const { showCreateModal, selctedId } = useSelector((state) => state.agent);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [filterShow, setFilterShow] = useState(false);

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAgentDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
    status: menuState.status,
    startDate: getFormatedDate(menuState?.startDate),
    endDate: getFormatedDate(menuState?.endDate),
    verification_status: menuState.verification_status,
  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const hasCreatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permissions?.[0]?.can_create ? 1 : 0;
  }, [mainData]);

  const hasUpdatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permissions?.[0]?.can_update ? 1 : 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permissions?.[0]?.can_delete ? 1 : 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(getEditDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              if (data?.verification_status === true) {
                navigate({
                  pathname: `/agent-details/students/${data?.id}`,
                });
              } else {
                navigate({
                  pathname: `/agent-details/${data?.id}`,
                });
              }

              sessionStorage.setItem("active", `${data?.id}`);
            }}
          >
            <span
              className={`pro-ms-2 pro-pnt pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        // Determine the class based on the status
        const statusValue = data?.status?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "requested":
            statusClass = "badge-warning-outline";
            break;
          case "active":
            statusClass = "badge-success-outline";
            break;
          case "deleted":
            statusClass = "badge-info-outline";
            break;
          case "rejected":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[field]}</span>
          </p>
        );
      },
      is_verified: (field, data) => {
        // Determine the class based on the status
        const statusValue = data?.is_verified?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "pending":
            statusClass = "badge-warning-outline";
            break;
          case "approved":
            statusClass = "badge-success-outline";
            break;
          case "deleted":
            statusClass = "badge-info-outline";
            break;
          case "rejected":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[field]}</span>
          </p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.status = null;
      })
    );
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.status = selectedOption.value;
      })
    );
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleDashboardRedirect = () => {
    navigate("/agent/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const handleClose = () => {
    setFilterShow(false);
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    showImageModal,
    imageData,
    selectedOption,
    tableRef,
    showDeleteConfirm,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    filterShow,
    handleEditAction,
    closeImageModal,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    handleDelete,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleDashboardRedirect,
    setFilterShow,
    handleClose,
  };
};

export default useAgents;
