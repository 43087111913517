import React, {useState} from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const PackageFilterForm = ({ formik, handleReset }) => {
  
  const today = new Date();
  today.setDate(today.getDate() - 1); 
  const minDate = today.toISOString().split('T')[0];

  let packageList = [
    {
      label: "Full Modules",
      value: 1,
    },
    {
      label: "Individual Modules",
      value: 2,
    },
    {
      label: "JEE",
      value: 3,
    },
    {
      label: "NEET",
      value: 4,
    },
  ];

  const [startDateClass, setStartDateClass] = useState("");
  const [endDateClass, setEndDateClass] = useState("");

  // Handle change for the start date picker
  const handleStartDateChange = (e) => {
    formik.handleChange(e);
    setStartDateClass(e.target.value ? "date-selected" : "");
  };

  // Handle change for the end date picker
  const handleEndDateChange = (e) => {
    formik.handleChange(e);
    setEndDateClass(e.target.value ? "date-selected" : "");
  };
  
  return (
    <div>
      {/* <div clclassName="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-2 pro-fw-medium">
            Package Type *
          </label>
          <Select
            id="package_type"
            isClearable={true}
            placeholder={"Select"}
            options={packageList}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={packageList?.filter(
              (p) => p.value === formik.values.package_type
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur}
            onChange={(value) =>
              formik.setFieldValue(`package_type`, value?.value || null)
            }
          />
      </div> */}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Package Type
        </label>
        <Select
          id="package_type"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="package_type"
          options={packageList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={packageList?.filter((m) => formik.values.package_type === m.value)}
          onChange={(value) => formik.setFieldValue("package_type", value?.value)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          From date
        </label>
        <input
          className={`pro-input lg ${startDateClass}`}
          type="date"
          name="start_date"
          max={minDate}
          onChange={handleStartDateChange}
          value={formik.values.start_date}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          To date
        </label>
        <input
          className={`pro-input lg ${endDateClass}`}
          type="date"
          name="end_date"
          max={new Date().toISOString().split("T")[0]}
          onChange={handleEndDateChange}
          value={formik.values.end_date}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={() => handleReset()}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default PackageFilterForm;
