import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  description: "",
};

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = privacyPolicySlice.actions;
export default privacyPolicySlice.reducer;
