import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useProgramSessions from "./useProgramSessions";
import EmptyData from "../../../Global/EmptyData";
import AddSession from "../AddSession";

const ProgramSessions = () => {
  const {
    isLoading,
    isFetching,
    sessionList,
    paginationOptions,
    sessionState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditColumnsClick,
    handleCreateClick,
    closeModal,
    activeProfile,
    handleEditAction,
    handleDelete,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
  } = useProgramSessions();
  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <div className="pro-mb-4">
          <HeadingGroup
            title={"Sessions"}
            className={`pro-mb-4`}
            buttonTitle={"Add new"}
            handleClick={handleCreateClick}
          />
        </div>
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: sessionState?.search }}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
        />
        <div className={`pro-pb-5`}>
          {sessionList?.data?.rows?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={sessionList?.data?.rows?.data || []}
              uniqueID={"id"}
              fields={sessionList?.data?.field || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              deletable={true}
              handleDelete={handleDelete}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={sessionState?.clearSelection}
              perpage={sessionState?.currentPageSize}
              assignable={false}
              editable={true}
              showCheckBox={true}
              handleEdit={handleEditAction}
            />
          )}
        </div>

        {sessionList?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={sessionState?.currentPage}
            totalPageCount={Math.ceil(
              sessionList.data.rows?.total / sessionState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(sessionList?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = sessionList?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={sessionList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={sessionState?.showCreateModal}
          handleClose={closeModal}
          title={sessionState?.is_edit ? "Update Session" : "Add Session"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddSession
            setShowform={closeModal}
            refetch={refetch}
            programId={activeProfile}
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default ProgramSessions;
