import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Branches/branchSlice.js";
import {
  createNewBranch,
  updateBranch,
} from "../../../../store/slices/Branches/branchSlice.js";
import { phoneRegExp } from "../../../../utils/functions/table.js";

const useBranchForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.branch
  );

  const validation = Yup.object({
    name: Yup.string().required("*Branch Name is Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*phone number is required"),
    address: Yup.string().required("*Address is Required"),
    country_id: Yup.mixed().required("*Select Country"),
    state_id: Yup.mixed().required("*Select State"),
    district_id: Yup.mixed().required("*Select District"),
  });

  const formik = useFormik({
    initialValues: {
      branch_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.branch_id : "",
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",
      phone_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.phone_number : "",
      address: selectedItemsDetails !== "" ? selectedItemsDetails?.address : "",
      country_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.country : "",
      state_id: selectedItemsDetails !== "" ? selectedItemsDetails?.state : "",
      district_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.district : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 1
          : 1,
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        branch_id: is_edit ? selectedId : "",
        name: values?.name,
        address: values?.address,
        phone_number: values?.phone_number,
        country_id: values?.country_id?._id,
        state_id: values?.state_id?._id,
        district_id: values?.district_id?._id,
        status: +values?.status,
      };
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      if (selectedItemsDetails !== "") {
        formData.append("_method", "PUT");
        dispatch(updateBranch({ data: formData })).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
                state.clearSelection = true;
              })
            );
            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewBranch(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    handleCloseModal,
    profilefileInputRef,
    selectedItemsDetails,
  };
};

export default useBranchForm;
