import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useCoupon from "./useCoupon";

const Coupon = () => {
  const { drawerMenu } = useCoupon();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Coupon;
