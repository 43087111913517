import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import useValidations from "../../../../utils/hooks/useValidations.js";
import { ProfileChangePassword } from "../../api.js";
import { useNavigate } from "react-router-dom";

const useSecurity = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { validPasswords } = useValidations();

  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(true);

  const handleShowCurrentPassword = (e) => {
    e.preventDefault();
    setShowCurrentPassword(!showCurrentPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      current_password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.password) {
        errors.password = "*Password required";
      }
      const {
        uppercasePassword,
        lowercasePassword,
        digitsPassword,
        minLengthPassword,
        charsPassword,
      } = validPasswords(values.password);
      if (!values.current_password) {
        errors.current_password = "*Current password required";
      }
      if (!values.password) {
        errors.confirm_password = "*Password required";
      }

      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      if (values.password && !minLengthPassword) {
        errors.password = "*Password should be atleast 8 digits";
      }
      if (values.password && !charsPassword) {
        errors.password = "*Enter atleast 1 special character";
      }
      if (values.password && !digitsPassword) {
        errors.password = "*Enter atleast 1 digit";
      }
      if (values.password && !lowercasePassword) {
        errors.password = "*Enter atleast 1 lowercase letter";
      }

      if (values.password && !uppercasePassword) {
        errors.password = "*Enter atleast 1 uppercase letter";
      }

      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values,{resetForm}) => {
      ProfileChangePassword({
        current_password: values?.current_password,
        password: values.password,
        confirm_password: values.confirm_password,
      }).then((response) => {
        resetForm();
        if (response?.data?.success) {
          toast.success("Password has been updated successfully");
        } else {
          toast.error(response.message);
        }
      });
    },
  });

  const handleCloseModal = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.showCreateModal = false;
    //   })
    // );
  };

  return {
    handleCloseModal,
    formik,
    handleShowConfirmPassword,
    handleShowCurrentPassword,
    handleShowNewPassword,
    showConfirmPassword,
    showCurrentPassword,
    showNewPassword,
  };
};

export default useSecurity;
