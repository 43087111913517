import React, { useEffect, useState } from "react";
import { Image, Input } from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "./sessionForm.module.scss";
import Select from "react-select";
import { getProgramList } from "../../../../../store/slices/packages/packageModuleSlice";
import { useDispatch } from "react-redux";

export const ItemField = ({ formik, itmIndex, fields, is_edit }) => {
  const { handleRemoveFieldCS, handleVideosChange, handleAudioChange } =
    useItemField({
      fields,
      itmIndex,
      formik,
      is_edit,
    });

  const dispatch = useDispatch();
  const [programList, setProgramList] = useState([]);

  useEffect(() => {
    dispatch(getProgramList())
      .unwrap()
      .then((result) => {
        setProgramList(
          result.data.data?.map((item) => ({
            label: item.module_name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className={`pro-mb-4 ${Style.box_root} `}>
        {formik?.values?.questionnaire?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className={`col-md-12 ${Style.add_more_field} `}>
          {formik?.values?.question_type_id === 1 && (
            <div className="col ">
              <div className="input-wrap">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Program *
                </label>
                <Select
                  name="module_id"
                  options={programList} // replace with your display_order options
                  getOptionValue={(option) => option?.value}
                  getOptionLabel={(option) => option?.label}
                  value={programList?.filter(
                    (m) =>
                      formik?.values?.questionnaire?.[itmIndex]?.module_id ===
                      m?.value
                  )}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      `questionnaire.${itmIndex}.module_id`,
                      selectedOption?.value
                    )
                  }
                  className={`pro-input lg ${
                    formik.touched?.questionnaire?.[itmIndex]?.module_id &&
                    formik.errors?.questionnaire?.[itmIndex]?.module_id &&
                    " error"
                  }`}
                  classNamePrefix={`pro-input`}
                  onBlur={formik.handleBlur(
                    `questionnaire.${itmIndex}.module_id`
                  )}
                />
                {formik.touched?.questionnaire?.[itmIndex]?.module_id &&
                formik.errors?.questionnaire?.[itmIndex]?.module_id && (
                  <span className="error-text">
                    {formik.errors?.questionnaire?.[itmIndex]?.module_id}
                  </span>
                )}
              </div>
            </div>
          )}

          <Input
            type="text"
            id={`question`}
            name={`question`}
            label={`Question`}
            onBlur={formik.handleBlur(`questionnaire.${itmIndex}.question`)}
            className={`pro-input lg ${
              formik.touched?.questionnaire?.[itmIndex]?.question &&
              formik.errors?.questionnaire?.[itmIndex]?.question &&
              " error"
            }`}
            {...formik.getFieldProps(`questionnaire.${itmIndex}.question`)}
            error={
              formik.touched?.questionnaire?.[itmIndex]?.question &&
              formik.errors?.questionnaire?.[itmIndex]?.question && (
                <span className="error-text">
                  {formik.errors?.questionnaire?.[itmIndex]?.question}
                </span>
              )
            }
            errorMessage={
              formik.touched?.questionnaire?.[itmIndex]?.question &&
              formik.errors?.questionnaire?.[itmIndex]?.question && (
                <span className="error-text">
                  {formik.errors?.questionnaire?.[itmIndex]?.question}
                </span>
              )
            }
          />
          <Input
            type="text"
            id={`order`}
            name={`order`}
            label={`Order`}
            onBlur={formik.handleBlur(`questionnaire.${itmIndex}.order`)}
            className={`pro-input lg ${
              formik.touched?.questionnaire?.[itmIndex]?.order &&
              formik.errors?.questionnaire?.[itmIndex]?.order &&
              " error"
            }`}
            {...formik.getFieldProps(`questionnaire.${itmIndex}.order`)}
            error={
              formik.touched?.questionnaire?.[itmIndex]?.order &&
              formik.errors?.questionnaire?.[itmIndex]?.order && (
                <span className="error-text">
                  {formik.errors?.questionnaire?.[itmIndex]?.order}
                </span>
              )
            }
            errorMessage={
              formik.touched?.questionnaire?.[itmIndex]?.order &&
              formik.errors?.questionnaire?.[itmIndex]?.order && (
                <span className="error-text">
                  {formik.errors?.questionnaire?.[itmIndex]?.order}
                </span>
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default ItemField;
