import { Button } from "@wac-ui-dashboard/wac_component_library";
import useCaptureEmail from "./useCaptureEmail";

const CaptureEmail = ({ refetch, closeEmailModal }) => {
  const { email, errors, handleSubmit, handleChange, handleBlur } =
    useCaptureEmail({ refetch, closeEmailModal });

  return (
    <div className="pro-w-100 pro-py-5 pro-px-2">
      <div
        className=" pro-px-5 pro-pb-3"
        buttonText={"Submit"}
        handleClick={handleSubmit}
      >
        <div className="input-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Enter Student Email
          </label>
          <input
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            className={`pro-input lg ${errors && "error"}`}
          />
          {errors && <span className="error-text">{errors}</span>}
        </div>

        <Button
          className={"pro-btn-primary lg pro-w-100 pro-mt-4"}
          type="submit"
          onClick={handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default CaptureEmail;
