import Style from "./AllTrainingShimmer.module.scss";
const AllTrainingShimmer = () => {
  return (
    <div className={` ${Style.shimmer_container}`}>
      <table>
          <tbody>
                <tr>
                  <td>
                    <div className={`pro-d-flex  pro-items-center   ${Style.date_time}`}>
                      <div className="col-auto  pro-pe-4 pro-border-right">
                        <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                        <div className="shimmer pro-p-3 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      <div className="col pro-ps-4">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      
                    </div>
                  </td>
                  
                  <td>
                    <div >
                      <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                    </div>
                  </td>
                  <td>
                      <div className={Style.mentor}>
                        <div className="shimmer pro-p-2 pro-w-100 letter"></div>
                      </div>
                  </td>
                  <td>
                    <div className="pro-ps-5">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
                    </div>
                    
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={`pro-d-flex  pro-items-center   ${Style.date_time}`}>
                      <div className="col-auto  pro-pe-4 pro-border-right">
                        <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                        <div className="shimmer pro-p-3 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      <div className="col pro-ps-4">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      
                    </div>
                  </td>
                  
                  <td>
                    <div >
                      <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                    </div>
                  </td>
                  <td>
                      <div className={Style.mentor}>
                        <div className="shimmer pro-p-2 pro-w-100 letter"></div>
                      </div>
                  </td>
                  <td>
                    <div className="pro-ps-5">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
                    </div>
                    
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={`pro-d-flex  pro-items-center   ${Style.date_time}`}>
                      <div className="col-auto  pro-pe-4 pro-border-right">
                        <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                        <div className="shimmer pro-p-3 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      <div className="col pro-ps-4">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      </div>
                      
                    </div>
                  </td>
                  
                  <td>
                    <div >
                      <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1"></div>
                      <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-1"></div>
                    </div>
                  </td>
                  <td>
                      <div className={Style.mentor}>
                        <div className="shimmer pro-p-2 pro-w-100 letter"></div>
                      </div>
                  </td>
                  <td>
                    <div className="pro-ps-5">
                        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
                    </div>
                    
                  </td>
                </tr>
          </tbody>
        </table>
    </div>
  );
};

export default AllTrainingShimmer;
