import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  editPackage: false,
  selectedData: "",
  clearSelection: false,
  start_date: "",
  end_date: "",
  package_type: "",
  selectedId: "",
  selectedItemsDetails: "",
  editDataLoading: false,
};

export const faqEditData = createAsyncThunk(
  "v1/faqs/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/faqs/edit?id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteData = createAsyncThunk(
  "/v1/faqs/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/faqs/delete`,params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(faqEditData.pending, (state, action) => {
        state.editDataLoading = true;
      })
      .addCase(faqEditData.fulfilled, (state, action) => {
        state.editDataLoading = false;
        state.selectedItemsDetails = action?.payload?.data;
      })
      .addCase(faqEditData.rejected, (state, action) => {
        state.editDataLoading = false;
      });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  faqSlice.actions;

export default faqSlice.reducer;
