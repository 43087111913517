import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/logo.svg";
import HEADERLOGODARK from "./images/header/logo_dark.svg";
import IMAGE_APPEARANCE from "./images/image-appearance.png";
import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from './images/no_data.svg';
import EMPTY_DATA from './images/empty_data.png'
import ACCOUNT_CIRCLE from './images/acount_circle.png'
import FEMALEPROFILE from "./images/female.png";
import MALEPROFILE from "./images/male.png";
import GENDER_IMAGE from "./images/gender_image.png";
import GREENCHECK from "./images/roles/green.svg";
import YELLOWCHECK from "./images/roles/yellow-check.svg";
import REDCHECK from "./images/roles/red-check.svg";
import EDIT from "./images/edit_square.svg";
import ICON_MODULE1 from "./images/module1.svg";
import ICON_MODULE2 from "./images/module2.svg";
import ICON_PLAY from "./images/play_circle.svg";
import ICON_DOC from "./images/doc_file.svg";
import CHECK_CIRCLE from "./images/checkCircle.svg";
import CLOSE_CIRCLE from "./images/checkClose.svg";
import REGISTER_BANNER from "./images/imgRegister.jpg";
import CHART_PRELOADER from "./images/preloader_bar.gif"

const Assets = {
  USER,
  HEADERLOGO,
  HEADERLOGODARK,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  EMPTY_DATA,
  FEMALEPROFILE,
  MALEPROFILE,
  ACCOUNT_CIRCLE,
  GENDER_IMAGE,
  GREENCHECK,
  YELLOWCHECK,
  REDCHECK,
  EDIT,
  ICON_MODULE1,
  ICON_MODULE2,
  ICON_PLAY,
  ICON_DOC,
  CHECK_CIRCLE,
  CLOSE_CIRCLE,
  REGISTER_BANNER,
  CHART_PRELOADER
};

export default Assets;
