import React from "react";
import CommonLayout from "../../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useSellerCoupon from "./useSellerCoupon";

const SellerCoupon = () => {
  const { drawerMenu } = useSellerCoupon();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default SellerCoupon;
