import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../../Global/EmptyData";
import OrderColumn from "../../../Global/OrderColumn";
import useProgramPrices from "./useProgramPrices";
import ProgramPriceForm from "../ProgramPriceForm";

const ProgramPrices = () => {
  const {
    isLoading,
    isFetching,
    priceList,
    paginationOptions,
    priceState,
    showEditModal,
    updateTableFields,
    handleDeleteAction,
    handleClosePriceModal,
    handleCreateClick,
    handleEditAction,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    closeEditModal,
    refetch,
    handleEditColumnsClick,
  } = useProgramPrices();

  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <HeadingGroup
          title={"Price"}
          className={`pro-mb-4`}
          buttonTitle={"Create"}
          handleClick={handleCreateClick}
        />
        <div className="pro-mt-3">
          <SearchFilters
            onSearchInput={handleSearch}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: priceState?.search }}
            showActions={true}
            handleActionClick={handleEditColumnsClick}
          />
          <div className={`pro-pb-5`}>
            {priceList?.data?.rows?.data?.length === 0 ? (
              <EmptyData />
            ) : (
              <Table
                multiSelect={false}
                data={priceList?.data?.rows?.data || []}
                uniqueID={"id"}
                fields={priceList?.data?.field || []}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                clear={priceState?.clearSelection}
                perpage={priceState?.currentPageSize}
                assignable={false}
                deletable={true}
                editable={true}
                handleEdit={handleEditAction}
                handleDelete={handleDeleteAction}
                showCheckBox={true}
              />
            )}
          </div>
        </div>

        {priceList?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={priceState?.currentPage}
            totalPageCount={Math.ceil(
              priceList?.data?.rows?.total / priceState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(priceList?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = priceList?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={priceList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>

      <OffCanvasLayout
        show={priceState?.showCreateModal}
        handleClose={handleClosePriceModal}
        title={
          priceState?.programPriceEdit
            ? "Edit Program Price"
            : "Add Program Price"
        }
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <ProgramPriceForm
          closeModal={handleClosePriceModal}
          refetch={refetch}
        />
      </OffCanvasLayout>
    </>
  );
};

export default ProgramPrices;
