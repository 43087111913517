
import Style from './LineGraphShimmer.module.scss';

const LineGraphShimmer = () => {
  return (
    <div className={`${Style.root} pro-p-3`}>
        <div className="pro-d-flex pro-justify-between pro-mb-5">
            <div className="pro-py-3 pro-pb-3 pro-align-self-center pro-px-6 shimmer"></div>
            <div className="pro-py-4 pro-px-6 pro-ps-4 shimmer"></div>
        </div>
        <div className={`pro-border-left pro-border-bottom ${Style.line_chart_wrap}`}>
          <svg width="100%" height="100%" viewBox="0 0 1138 370" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 300L0 370H570H1130L1050 250L950 350L850 300L750 250L650 150L550 300L450 200L350 250H270L200 170L100 300Z" fill="url(#chart-shine)" stroke="#ECEEEF" stroke-width="2" />
            
            <defs>
              <linearGradient id="chart-shine" x1="0" y1="100%" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="#f6f7f8" />
                <stop offset="20%" stop-color="#edeef1" stop-opacity="1" />
                <stop offset="40%" stop-color="#f6f7f8" />
                <stop offset="100%" stop-color="#f6f7f8" />
                <animate id="gradient" attributeName="x1" dur="1s" from="0" to="100%" repeatCount="indefinite" begin="0s" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className={` pro-d-flex row row-cols-3  pro-mt-4 `}>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2 `}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
            <div className={` pro-d-flex pro-gap-1 pro-mb-2`}>
                <div className='pro-p-1 shimmer col-auto'></div>
                <div className='pro-p-1 shimmer col'></div>
            </div>
        </div>
    </div>
  )
}

export default LineGraphShimmer