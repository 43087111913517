import style from "./dashboardCardShimmer.module.scss";
const DashboardCardShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className={` ${style.shimmer_container}`}>
        <div className="pro-d-flex pro-justify-between pro-mb-1 pro-gap-2">
          <div className="pro-mt-2 pro-w-50" >
              <div className="shimmer pro-p-2 pro-w-50 letter para pro-mt-1"></div>
              <div className="shimmer pro-p-1 pro-w-100 letter para "></div>
          </div>
          <div>
            <div className="shimmer pro-px-4 pro-py-4 pro-rounded-3"></div>
          </div>
          
        </div>
        {/* <div className="shimmer pro-px-4 pro-py-4 pro-rounded-3"></div> */}
        <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-3"></div>
      </div>
    </div>
  );
};

export default DashboardCardShimmer;
