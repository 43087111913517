import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  aadharVerification,
  sendOtp,
} from "../../../../store/slices/manageProfile/manageProfileSlice.js";
import { useState } from "react";

const useAadharInfo = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.manageProfile);
  const [referId, setReferId] = useState("");
  const aadhaarRegex = /^\d{12}$/;

  const validation = Yup.object({
    aadhaar_number: Yup.string()
      .required("*Required")
      .matches(aadhaarRegex, "*Invalid Aadhaar number"),
    otp: Yup.number().required("Please enter otp"),
  });

  const formik = useFormik({
    initialValues: {
      aadhaar_number:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.data?.aadhaar_data?.aadhaar_number
          : "",
      otp: "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        aadhaar_number: values?.aadhaar_number,
        otp: values?.otp,
        ref_id: referId,
      };

      dispatch(aadharVerification(obj)).then((response) => {
        if (response?.payload?.success) {
          resetForm();
          closeModal?.();
          refetch();
          // dispatch(
          //   updateConfig((state) => {
          //     state.showCreateModal = false;
          //   })
          // );
          // dispatch(
          //   updateConfig((state) => {
          //     state.clearSelection = true;
          //   })
          // );

          toast.success(response?.payload?.message);
        } else toast.error(response?.payload?.message);
      });
    },
  });

  // updating the completed percentage of butfields

  const handleOtpSend = (id) => {
    dispatch(sendOtp({ aadhaar_number: id })).then((response) => {
      if (response?.payload?.success) {
        setReferId(response?.payload?.data?.ref_id);
        toast.success(response?.payload?.message);
      } else toast.error(response?.payload?.message);
    });
  };

  return {
    formik,
    handleOtpSend,
    referId,
  };
};

export default useAadharInfo;
