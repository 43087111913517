import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
    
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("payment_method")}${getParams("purchase_from")}${getParams("purchase_to")}${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&page_size=${params?.page_size || "10"}&${getParams("search")}&${getParams("customer_id")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const myStudents = createApi({
  reducerPath: "myStudentsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["myStudents"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/agent/students`,
      }),
      providesTags: ["myStudents"],
    }),
    getStudentBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/agent/students/view`,
      }),
      providesTags: ["myStudents"],
    }),
  }),
});

export const { useGetListDataQuery, useGetStudentBasicDataQuery } = myStudents;
