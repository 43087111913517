import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../user.module.scss";
import { updateConfig } from "../../../../store/slices/packages/packageFormSlice.js";
import { limitStrLength } from "../../../../utils/functions/table.js";
const BasicForm = ({
  formik,
  setActiveTab,
  tabs,
  setShowform,
  selectedItemsDetails,
  selectedData
}) => {
  const dispatch = useDispatch();


  const [thumbnail, setThumbnail] = useState(
    selectedItemsDetails ? formik?.values?.package_intro_video_url : null
  );

  const [imageThumbnailPreview, setImageCoverPreview] = useState(
    selectedItemsDetails ? formik?.values?.package_logo_url : ""
  );

  const [imageBannerPreview, setImageBannerPreview] = useState(
    selectedItemsDetails ? formik?.values?.package_image_url : ""
  );

  const [titleIconPreview, setTitleIconPreview] = useState([]);
  const [offerImagePreview, setOfferImagePreview] = useState([]);

  useEffect(() => {
    if (selectedData?.banner_title?.length > 0) {
      const iconPreviews = selectedData.banner_title.map((item) => item.icon_url || "");
      const imagePreviews = selectedData.banner_title.map((item) => item.logo_url || "");
      
      setTitleIconPreview(iconPreviews);
      setOfferImagePreview(imagePreviews);
    }
  }, [selectedData]);
  

  useEffect(() => {
		if (selectedItemsDetails !== "") {
			setImageCoverPreview(selectedData?.package_image_thumbnail_url ?? "");
			setThumbnail(selectedData?.package_intro_video_url ?? "");
      setImageBannerPreview(selectedData?.package_image_url ?? "");
		}
	}, [selectedItemsDetails]);

  const handleAddField = () => {
    let { package_benefits } = formik?.values;
    package_benefits = [
      ...package_benefits,
      {
        title: "",
      },
    ];
    formik.setFieldValue("package_benefits", package_benefits);
  };

  const handleAddIntoField = () => {
    let { banner_titles } = formik?.values;
    banner_titles = [
      ...banner_titles,
      {
        title_icon: "",
        offer_image: "",
        title_colour_code: "",
        banner_title_contents: [
          {
            content_title: "",
            is_colour: "",
          },
        ],
      },
    ];
    formik.setFieldValue("banner_titles", banner_titles);
  };

  const handleAddBannerTitleContentsField = (index) => {
    // Get the current banner_title_contents array for the specific banner_title
    let banner_title_contents = formik?.values?.banner_titles?.[index]?.banner_title_contents || [];
  
    // Add a new object to the array
    banner_title_contents = [
      ...banner_title_contents,
      {
        content_title: "",
        is_colour: "",
      },
    ];
  
    // Update the specific banner_title's banner_title_contents field
    formik.setFieldValue(`banner_titles.${index}.banner_title_contents`, banner_title_contents);
    
  };
  

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.package_benefits?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("package_benefits", data);
  };

  const handleRemoveIntroFieldCS = (index) => {
    const data = formik?.values?.banner_titles?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("banner_titles", data);
  };

  const handleRemoveFieldTitleContent = (index, contentIndex) => {
    const data = formik?.values?.banner_titles?.[index]?.banner_title_contents?.filter(
      (item, itemIndex) => itemIndex !== contentIndex
    );
    formik.setFieldValue(`banner_titles.${index}.banner_title_contents`, data);
  };


  let packageList = [
    {
      label: "Full Modules",
      value: 1,
    },
    {
      label: "Individual Modules",
      value: 2,
    },
    {
      label: "JEE",
      value: 3,
    },
    {
      label: "NEET",
      value: 4,
    },
  ];

  let statusList = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const handleThumbnailImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("package_logo", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "package_logo",
          "The Package image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "package_logo",
        "The Package image must be less than 5MB in size."
      );
    }
  };

  const handleTitleIconImage = (e, index) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue(
          `banner_titles[${index}].title_icon`,
          e.target.files[0]
        );

        const reader = new FileReader();

        reader.onloadend = () => {
          setTitleIconPreview((prevState) => {
            const newState = [...prevState];
            newState[index] = reader.result; // or newState.push({index, result: reader.result});
            return newState;
          });
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          `banner_titles[${index}].title_icon`,
          "The Title Icon image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        `banner_titles[${index}].title_icon`,
        "The Title Icon image must be less than 5MB in size."
      );
    }
  };

  const handleOfferImage = (e, index) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue(
          `banner_titles[${index}].offer_image`,
          e.target.files[0]
        );

        const reader = new FileReader();

        reader.onloadend = () => {
          setOfferImagePreview((prevState) => {
            const newState = [...prevState];
            newState[index] = reader.result;
            return newState;
          });
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          `banner_titles[${index}].offer_image`,
          "The Offer Image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        `banner_titles[${index}].offer_image`,
        "The Offer Image must be less than 5MB in size."
      );
    }
  };

  const handleBannerImageImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("intro_banner", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageBannerPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "intro_banner",
          "The Banner image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "intro_banner",
        "The Banner image must be less than 5MB in size."
      );
    }
  };

  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleVideosChange = async (e) => {
    const files = e?.target?.files[0];
    const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError("package_video", "invalid file type");
      return;
      // errors.push(`File ${file.name} is not a valid video format`);
    } else if (files.size > maxSizeInBytes) {
      // errors.push(`File ${file.name} exceeds the 10 MB limit`);
      formik.setFieldError("package_video", "invalid file size");
      return;
    }

    if (allowedFormats.includes(files.type)) {
      formik.setFieldValue("package_video", e?.target?.files?.[0]);
    }
    const thumbnailFile = await generateThumbnail(files);

    const reader = new FileReader();

    reader.onloadend = () => {
      setThumbnail(reader.result);
    };

    if (thumbnailFile) {
      reader.readAsDataURL(thumbnailFile);
    }

    // Function to generate thumbnail using async/await
    async function generateThumbnail(file) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadeddata = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.currentTime = 2; // Set the time to 1 second (adjust if needed)
          video.play().then(async () => {
            video.pause();
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // Here, you can use imlet gData or perform further actions with the second frame
            let blob = await dataURLtoBlob(canvas.toDataURL());
            const thumbnailFile = new File([blob], `thumbnail.jpg`, {
              type: "image/jpeg",
            });
            resolve(thumbnailFile);
          });
        };
        video.src = URL.createObjectURL(file);
        video.load();
      });
    }
  };

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Package Type *
          </label>
          <Select

            id="package_type"
            isClearable={true}
            placeholder={"Select"}
            options={packageList}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            className={`pro-input lg ${
              formik.errors.package_type &&
              formik.touched.package_type &&
              "error"
            }`}
            value={packageList?.filter(
              (p) => p.value === formik.values.package_type
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur}
            onChange={(value) =>
              formik.setFieldValue(`package_type`, value?.value || null)
            }
          />
          {formik.touched.package_type && formik.errors.package_type && (
            <div className="error-text">{formik.errors.package_type}</div>
          )}
        </div>
      </div>
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Package Name *
          </label>
          <Input
            type="text"
            name="name"
            placeholder="Package Name"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.name && formik.touched.name && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.name}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="error-text">{formik.errors.name}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description *
          </label>
          <Input
            type="text"
            name="description"
            placeholder="Description"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.description && formik.touched.description && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.description}
          />
          {formik.touched.description && formik.errors.description && (
            <div className="error-text">{formik.errors.description}</div>
          )}
        </div>
      </div>
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Sub Title *
          </label>
          <Input
            type="text"
            name="sub_title"
            placeholder="Sub Title"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.sub_title && formik.touched.sub_title && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.sub_title}
          />
          {formik.touched.sub_title && formik.errors.sub_title && (
            <div className="error-text">{formik.errors.sub_title}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Benefits *
        </label>
        <div className={`pro-p-4 pro-h-100 ${Style.box_root}`}>
          <div className={Style.add_form_wrap}>
            {formik?.values?.package_benefits?.map((fields, index) => (
              <div className={Style.box_root_inner}>
                {formik?.values?.package_benefits?.length !== 1 && (
                  <button
                    className={`${Style.btn_close} btn-close`}
                    onClick={() => handleRemoveFieldCS(index)}
                  ></button>
                )}
                <div className="row gx-2">
                  <div className="col-md-12">
                    <Input
                      type="text"
                      id={`title`}
                      name={`title`}
                      label={`Title`}
                      onBlur={formik.handleBlur(
                        `package_benefits.${index}.title`
                      )}
                      className={`pro-input lg ${
                        formik.touched?.package_benefits?.[index]?.title &&
                        formik.errors?.package_benefits?.[index]?.title &&
                        " error"
                      }`}
                      {...formik.getFieldProps(
                        `package_benefits.${index}.title`
                      )}
                      error={
                        formik.touched?.package_benefits?.[index]?.title &&
                        formik.errors?.package_benefits?.[index]?.title && (
                          <span className="error-text">
                            {formik.errors?.package_benefits?.[index]?.title}
                          </span>
                        )
                      }
                      errorMessage={
                        formik.touched?.package_benefits?.[index]?.title &&
                        formik.errors?.package_benefits?.[index]?.title && (
                          <span className="error-text">
                            {formik.errors?.package_benefits?.[index]?.title}
                          </span>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            type="button"
            onClick={handleAddField}
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      <div className="col pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Banner Info *
        </label>
        <div className={`pro-p-4 pro-h-100 ${Style.box_root}`}>
          <div className={Style.add_form_wrap}>
            {formik?.values?.banner_titles?.map((fields, index) => (
              <div className={Style.box_root_inner}>
                {formik?.values?.banner_titles?.length !== 1 && (
                  <button
                    className={`${Style.btn_close} btn-close`}
                    onClick={() => handleRemoveIntroFieldCS(index)}
                  ></button>
                )}
                <div className="row gx-2">
                  <div className="input-wrap pro-mb-4">
                    <label
                      htmlFor=""
                      className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                    >
                      Title Icon*
                    </label>
                    <div
                      className={`input-drag ${
                        titleIconPreview[index] ? "active" : ""
                      }`}
                    >
                      <input
                        type="file"
                        placeholder="placeholder"
                        accept=".jpeg, .jpg , .png"
                        className={`pro-input ${
                          formik.touched?.banner_titles?.[index]?.title_icon &&
                          formik.errors?.banner_titles?.[index]?.title_icon &&
                          " error"
                        }`}
                        id="title_icon"
                        name={`banner_titles.${index}.title_icon`}
                        onBlur={formik.handleBlur}
                        onChange={(e) => handleTitleIconImage(e, index)}
                      />
                      <span className="input-drag-box">
                        {!titleIconPreview?.[index] ? (
                          <IconText
                            title={
                              formik.values?.banner_titles?.[index]?.title_icon
                                ?.name !== undefined
                                ? limitStrLength(
                                    formik?.values?.banner_titles?.[index]
                                      ?.title_icon?.name,
                                    30
                                  )
                                : selectedItemsDetails?.title_icon
                                ? selectedItemsDetails?.title_icon
                                : `Drop files to attach or browse`
                            }
                          />
                        ) : (
                          <div className="img-wrap">
                            <span
                              className="img-close"
                              onClick={() => {
                                formik.setFieldValue(
                                  `banner_titles[${index}].title_icon`,
                                  null
                                );
                                setTitleIconPreview((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = null;
                                  return newState;
                                });
                              }}
                            >
                              &#10006;
                            </span>
                            <Image
                              width={100}
                              height={100}
                              src={titleIconPreview[index]}
                              alt={`Title Icon Preview ${index}`}
                            />
                          </div>
                        )}
                      </span>
                      {formik.touched?.banner_titles?.[index]?.title_icon &&
                        formik.errors?.banner_titles?.[index]?.title_icon && (
                          <span className="error-text">
                            {formik.errors?.banner_titles?.[index]?.title_icon}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="input-wrap pro-mb-4">
                    <label
                      htmlFor=""
                      className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                    >
                      Offer Image*
                    </label>
                    <div
                      className={`input-drag ${
                        offerImagePreview[index] ? "active" : ""
                      }`}
                    >
                      <input
                        type="file"
                        placeholder="placeholder"
                        accept=".jpeg, .jpg , .png"
                        className={`pro-input ${
                          formik.touched?.banner_titles?.[index]?.offer_image &&
                          formik.errors?.banner_titles?.[index]?.offer_image &&
                          " error"
                        }`}
                        id="offer_image"
                        name={`banner_titles.${index}.offer_image`}
                        onBlur={formik.handleBlur}
                        onChange={(e) => handleOfferImage(e, index)}
                      />
                      <span className="input-drag-box">
                        {!offerImagePreview[index] ? (
                          <IconText
                            title={
                              formik.values?.banner_titles?.[index]?.offer_image
                                ?.name !== undefined
                                ? limitStrLength(
                                    formik?.values?.banner_titles?.[index]
                                      ?.offer_image?.name,
                                    30
                                  )
                                : selectedItemsDetails?.offer_image
                                ? selectedItemsDetails?.offer_image
                                : `Drop files to attach or browse`
                            }
                          />
                        ) : (
                          <div className="img-wrap">
                            <span
                              className="img-close"
                              onClick={() => {
                                formik.setFieldValue(
                                  `banner_titles[${index}].offer_image`,
                                  null
                                );
                                setOfferImagePreview((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = null;
                                  return newState;
                                });
                              }}
                            >
                              &#10006;
                            </span>
                            <Image
                              width={100}
                              height={100}
                              src={offerImagePreview[index]}
                              alt={`Offer Image Preview ${index}`}
                            />
                          </div>
                        )}
                      </span>
                      {formik.touched?.banner_titles?.[index]?.offer_image &&
                        formik.errors?.banner_titles?.[index]?.offer_image && (
                          <span className="error-text">
                            {formik.errors?.banner_titles?.[index]?.offer_image}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <Input
                      type="text"
                      id={`title_colour_code`}
                      name={`title_colour_code`}
                      label={`Title Colour Code`}
                      onBlur={formik.handleBlur(
                        `banner_titles.${index}.title_colour_code`
                      )}
                      className={`pro-input lg ${
                        formik.touched?.banner_titles?.[index]
                          ?.title_colour_code &&
                        formik.errors?.banner_titles?.[index]
                          ?.title_colour_code &&
                        " error"
                      }`}
                      {...formik.getFieldProps(
                        `banner_titles.${index}.title_colour_code`
                      )}
                      error={
                        formik.touched?.banner_titles?.[index]
                          ?.title_colour_code &&
                        formik.errors?.banner_titles?.[index]
                          ?.title_colour_code && (
                          <span className="error-text">
                            {
                              formik.errors?.banner_titles?.[index]
                                ?.title_colour_code
                            }
                          </span>
                        )
                      }
                      errorMessage={
                        formik.touched?.banner_titles?.[index]
                          ?.title_colour_code &&
                        formik.errors?.banner_titles?.[index]
                          ?.title_colour_code && (
                          <span className="error-text">
                            {
                              formik.errors?.banner_titles?.[index]
                                ?.title_colour_code
                            }
                          </span>
                        )
                      }
                    />
                  </div>
                  <div className={`pro-p-4 pro-h-100 ${Style.box_root}`}>
                    <div className={Style.add_form_wrap}>
                      {formik?.values?.banner_titles?.[
                        index
                      ]?.banner_title_contents?.map((fields, contentIndex) => (
                        <div className={Style.box_root_inner}>
                          {formik?.values?.banner_titles?.[index]
                            ?.banner_title_contents?.length !== 1 && (
                            <button
                            type="button"
                              className={`${Style.btn_close} btn-close`}
                              onClick={() =>
                                handleRemoveFieldTitleContent(
                                  index,
                                  contentIndex
                                )
                              }
                            ></button>
                          )}
                          <div className="row gx-2">
                            <div className="col-md-12">
                              <Input
                                type="text"
                                id={`content_title`}
                                name={`content_title`}
                                label={`Content`}
                                onBlur={formik.handleBlur(
                                  `banner_titles.${index}.banner_title_contents.${contentIndex}.content_title`
                                )}
                                className={`pro-input lg ${
                                  formik.touched?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title &&
                                  formik.errors?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title &&
                                  " error"
                                }`}
                                {...formik.getFieldProps(
                                  `banner_titles.${index}.banner_title_contents.${contentIndex}.content_title`
                                )}
                                error={
                                  formik.touched?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title &&
                                  formik.errors?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title && (
                                    <span className="error-text">
                                      {
                                        formik.errors?.banner_titles?.[index]
                                          ?.banner_title_contents?.[
                                          contentIndex
                                        ]?.content_title
                                      }
                                    </span>
                                  )
                                }
                                errorMessage={
                                  formik.touched?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title &&
                                  formik.errors?.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.content_title && (
                                    <span className="error-text">
                                      {
                                        formik.errors?.banner_titles?.[index]
                                          ?.banner_title_contents?.[
                                          contentIndex
                                        ]?.content_title
                                      }
                                    </span>
                                  )
                                }
                              />
                            </div>

                            <div className="col pro-mb-4">
                              <div className="input-wrap">
                                <label
                                  htmlFor=""
                                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                                >
                                  Color Status*
                                </label>

                                <Select
                                  id="is_colour"
                                  isClearable={true}
                                  placeholder={"Select"}
                                  options={statusList}
                                  getOptionLabel={(option) => option?.label}
                                  getOptionValue={(option) => option?.value}
                                  className={`pro-input lg ${
                                    formik.errors.banner_titles?.[index]
                                      ?.banner_title_contents?.[contentIndex]
                                      ?.is_colour &&
                                    formik.touched.banner_titles?.[index]
                                      ?.banner_title_contents?.[contentIndex]
                                      ?.is_colour &&
                                    "error"
                                  }`}
                                  value={statusList?.filter(
                                    (p) =>
                                      p.value ===
                                      formik.values.banner_titles?.[index]
                                        ?.banner_title_contents?.[contentIndex]
                                        ?.is_colour
                                  )}
                                  classNamePrefix="pro-input"
                                  onBlur={formik.handleBlur}
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `banner_titles.${index}.banner_title_contents.${contentIndex}.is_colour`,
                                      value?.value || null
                                    )
                                  }
                                ></Select>
                                {formik.touched.banner_titles?.[index]
                                  ?.banner_title_contents?.[contentIndex]
                                  ?.is_colour &&
                                  formik.errors.banner_titles?.[index]
                                    ?.banner_title_contents?.[contentIndex]
                                    ?.is_colour && (
                                    <div className="error-text">
                                      {
                                        formik.errors?.banner_titles?.[index]
                                          ?.banner_title_contents?.[
                                          contentIndex
                                        ]?.is_colour
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button
                      className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
                      type="button"
                      onClick={() => handleAddBannerTitleContentsField(index)}
                      // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
                    >
                      {` Add More`}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            type="button"
            onClick={handleAddIntoField}
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Package Logo*
        </label>
        <div className={`input-drag ${imageThumbnailPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            // ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.package_logo &&
              formik.errors.package_logo &&
              " error"
            }`}
            id="package_logo"
            name="package_logo"
            onBlur={formik.handleBlur("package_logo")}
            onChange={(e) => handleThumbnailImage(e)}
          />
          <span className="input-drag-box">
            {!imageThumbnailPreview ? (
              <IconText
                title={
                  formik?.values?.package_logo?.name !== undefined
                    ? limitStrLength(formik?.values?.package_logo?.name, 30)
                    : selectedItemsDetails !== "" &&
                      selectedItemsDetails?.package_logo
                    ? selectedItemsDetails?.package_logo
                    : `Drop files to attach or browse`
                  // `Drop files to attach or browse`
                }
              />
            ) : (
              <div className="img-wrap">
                <span className="img-close">&#10006;</span>
                <Image
                  width={100}
                  height={100}
                  src={imageThumbnailPreview}
                  alt={`branch - 01`}
                />
              </div>
            )}
          </span>
          {formik.touched.package_logo && formik.errors.package_logo && (
            <span className="error-text">{formik.errors.package_logo}</span>
          )}
        </div>
      </div>
      {/* <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageThumbnailPreview}
            alt={`branch - 01`}
          />
        </div>
      </div> */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Banner Image *
        </label>
        <div className={`input-drag ${imageBannerPreview ? "active" : ""}`}>
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            // ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.intro_banner &&
              formik.errors.intro_banner &&
              " error"
            }`}
            id="intro_banner"
            name="intro_banner"
            onBlur={formik.handleBlur("intro_banner")}
            onChange={(e) => handleBannerImageImage(e)}
          />
          <span className="input-drag-box">
            {!imageBannerPreview ? (
              <IconText
                title={
                  formik?.values?.intro_banner?.name !== undefined
                    ? limitStrLength(formik?.values?.intro_banner?.name, 30)
                    : selectedItemsDetails !== "" &&
                      selectedItemsDetails?.intro_banner
                    ? selectedItemsDetails?.intro_banner
                    : `Drop files to attach or browse`
                  // `Drop files to attach or browse`
                }
              />
            ) : (
              <div className="img-wrap">
                <span className="img-close">&#10006;</span>
                <Image
                  width={100}
                  height={100}
                  src={imageBannerPreview}
                  alt={`branch - 01`}
                />
              </div>
            )}
          </span>
          {formik.touched.intro_banner && formik.errors.intro_banner && (
            <span className="error-text">{formik.errors.intro_banner}</span>
          )}
        </div>
      </div>

      {/* <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Banner Content *
          </label>
          <Input
            type="text"
            name="banner_content"
            placeholder="Banner Content"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.banner_content &&
              formik.touched.banner_content &&
              "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik?.values?.banner_content}
          />
          {formik.touched.banner_content && formik.errors.banner_content && (
            <div className="error-text">{formik.errors.banner_content}</div>
          )}
        </div>
      </div> */}

      <div className={Style.wrapper}>
        <label className="pro-mb-1 pro-font-sm pro-fw-medium">
          Package Video*
        </label>
        <div
          className={`pro-d-flex pro-input  pro-justify-center pro-flex-wrap pro-gap-3 pro-p-2 upload-wrap ${
            Style.upload_wrap
          } video-upload-wrap ${thumbnail ? "active" : ""}`}
        >
          <></>
          <label
            for="video-upload"
            className={`pro-w-100 pro-h-100 stretched-label`}
          ></label>
          {/* {showAddButton && ( */}
          <div
            className={`input-wrap  ${
              formik.touched?.package_video &&
              formik.errors?.package_video &&
              " error"
            }`}
          >
            {!thumbnail ? (
              <div className="pro-d-flex  pro-justify-center pro-items-center pro-flex-column pro-text-center">
                <span className="material-symbols-outlined x3">backup</span>
                <span className={`${Style.add_text} pro-fw-medium `}>
                  "Add Video"
                </span>
              </div>
            ) : (
              <div className="pro-input pro-h-100 pro-d-flex pro-justify-between pro-items-center">
                <div className="file-name ">{thumbnail?.substring(0,30)}</div>
                {/* <button  type="button" className="btn-close"></button> */}
              </div>
            )}
            <input
              type="file"
              className={`${Style.file} pro-d-none`}
              onChange={handleVideosChange}
              id="video-upload"
            />
          </div>
          {/* )} */}
          {formik.touched?.package_video && formik.errors?.package_video && (
            <span className="error-text">{formik.errors?.package_video}</span>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-mt-5 "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={
          //   formik?.errors?.firstName != undefined ||
          //   formik?.errors?.lastName != undefined ||
          //   formik?.errors?.mobileNumber != undefined ||
          //   formik?.errors?.email != undefined ||
          //   formik?.errors?.dateOfBirth != undefined ||
          //   formik?.errors?.nationality != undefined
          // }
          onClick={() => {
            // setActiveTab(tabs[1]);
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = tabs[1].label;
              })
            );
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;
