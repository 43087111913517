import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../../Global/EmptyData";
import usePayoutDetailsLayout from "./usePayoutDetailsLayout";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";

const PayoutDetailsLayout = () => {
  const {
    handlePageSize,
    handlePagination,
    handleSearch,
    handleSort,
    handleNavigation,
    getRow,
    paginationOptions,
    payoutHistoryData,
    menuState,
    isFetching,
    isLoading,
  } = usePayoutDetailsLayout();

  return (
    <ProtectRoute>
      <div className="pro-m-5">
        <HeadingGroup
          title={"Payout History"}
          className={`pro-mb-4`}
          buttonTitle={"Back"}
          handleClick={handleNavigation}
        />
        <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
          <SearchFilters
            onSearchInput={handleSearch}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: menuState?.detailSearch }}
            showActions={false}
          />
          <div className={`pro-pb-5`}>
            {payoutHistoryData?.data?.list?.length === 0 ? (
              <EmptyData />
            ) : (
              <Table
                multiSelect={false}
                data={payoutHistoryData?.data?.list || []}
                uniqueID={"id"}
                fields={payoutHistoryData?.data?.fields || []}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                clear={menuState?.clearSelection}
                perpage={menuState?.currentPageSize}
                showCheckBox={false}
              />
            )}
          </div>

          {payoutHistoryData?.data?.list?.length > 0 && (
            <Pagination
              currentPage={menuState?.detailCurrentPage}
              totalPageCount={Math.ceil(
                payoutHistoryData?.data?.total_page /
                  menuState.detailCurrentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      </div>
    </ProtectRoute>
  );
};

export default PayoutDetailsLayout;
