import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { useDispatch } from "react-redux";
import ItemField from "../MultiForm/ItemFieldClick";
import { updateConfig } from "../../../../store/slices/packages/packageFormSlice.js";
import Style from "../user.module.scss";
import { getPrice } from "../../../../store/slices/program/programFormSlice.js";
import { toast } from "react-toastify";

const PriceForm = ({ formik, setActiveTab, tabs, setShowform }) => {
  const dispatch = useDispatch();

  const handleAddField = () => {
    let { package_price } = formik?.values;
    package_price = [
      ...package_price,
      {
        country_id: "",
        price: "",
        currency: "",
        tax: "",
        mrp_amount: "",
        offer_percentage: "",
        // price_without_offer: "",
        offer_tag: "",
        offer_validity: "",
      },
    ];
    formik.setFieldValue("package_price", package_price);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  // const closeModal = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = false;
  //       state.isEdit = false;
  //     })
  //   );
  // };

  const setPriceAmount = (index, mrp, offer_percentage) => {
    let params = {
      mrp: mrp,
      offer_percentage: offer_percentage,
    };
    if (params?.mrp != "" && params?.offer_percentage != "") {
      dispatch(getPrice(params))
        .then((result) => {
          if (
            result?.payload?.data?.status_code === 200 &&
            result?.payload?.data?.data != ""
          ) {
            formik.setFieldValue(
              `package_price.${index}.price`,
              result?.payload?.data?.data
            );
          } else {
            toast.error(result?.payload?.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong!");
        });
    }
  };

  return (
    <div>
      {/* Render form fields for the "Address" tab */}
      <div className="col-12 pro-mb-4">
        <div className={`${Style.add_more_wrap}`}>
          {formik?.values?.package_price?.map((fields, index) => (
            <ItemField
              key={index}
              itmIndex={index}
              fields={fields}
              formik={formik}
              getFieldError={getFieldError}
              setPriceAmount={setPriceAmount}
            />
          ))}
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            type="button"
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={
          //   formik?.errors?.country != undefined ||
          //   formik?.errors?.state != undefined ||
          //   formik?.errors?.city != undefined ||
          //   formik?.errors?.address != undefined ||
          //   formik?.errors?.permanentAddress != undefined
          // }
          onClick={() => {
            // setActiveTab(tabs[1]);
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = tabs[2].label;
              })
            );
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PriceForm;
