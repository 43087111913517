import moment from "moment";

export const getFormatedDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};
export const getFormatedDateRange = (date) => {
  return moment(new Date(date)).format("DD MMM YYYY");
};
export const getMaxDate = (years) => {
  return moment().subtract(years, "years")._d;
};

export const limitStrLength = (text, max_length) => {
  if (text.length > max_length - 3) {
    return text.substring(0, max_length).trimEnd() + "...";
  } else {
    return text;
  }
};  

export const capitalizeOnSpace = (event, setFieldValue) => {
  const inputName = event.target.name;
  const inputValue = event.target.value;

  const nameParts = inputValue.split(" ");
  const capitalizedParts = nameParts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1);
  });

  const capitalizedFullName = capitalizedParts.join(" ");

  setFieldValue(inputName, capitalizedFullName);
};

export const capitalizeInitial = (event, setFieldValue) => {
  const inputName = event.target.name;
  const inputValue = event.target.value;

  const capitalizedLastName =
    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);

  setFieldValue(inputName, capitalizedLastName);
};

export const numRegExp = /^\d+$/;
export const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
