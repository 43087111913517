import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { getMenuPermissions, updateConfig } from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedDashboardComponent,
    allowedBranchComponents,
    allowedConfigureComponents,
    allowedPackageComponent,
    allowedUserComponents,
    allowedBonusComponents,
    allowedProgramComponents,
    allowedRoleComponent,
    allowedCustomerComponent,
    allowedDailyMeditationComponents,
    allowedQuestionnaireComponents,
    allowedReviewComponents,
    allowedAgentComponents,
    allowedAnnouncementComponents,
    allowedPayoutsComponents,
    allowedTrainingComponents,
    allowedStudentsActivityComponents,
    allowedSellerCouponComponents,
    allowedCouponComponents,
    allowedOrderComponents,
    allowedCmsComponents,
  } = useRoutes();

  useEffect(() => {
    if (!globalState.isLogged && location.pathname === "/settings/appearance") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedBonusComponents?.length > 0 ||
        allowedConfigureComponents?.length > 0 ||
        allowedPackageComponent?.length > 0 ||
        allowedUserComponents?.length > 0 ||
        allowedProgramComponents?.length > 0 ||
        allowedRoleComponent?.length > 0 ||
        allowedCustomerComponent?.length > 0 ||
        allowedDailyMeditationComponents?.length > 0 ||
        allowedQuestionnaireComponents?.length > 0 ||
        allowedSellerCouponComponents?.length > 0 ||
        allowedReviewComponents?.length > 0 ||
        allowedAgentComponents?.length > 0 ||
        allowedAnnouncementComponents?.length > 0 ||
        allowedPayoutsComponents?.length > 0 ||
        allowedTrainingComponents?.length > 0 ||
        allowedStudentsActivityComponents?.length > 0 ||
        allowedCouponComponents?.length > 0 ||
        allowedOrderComponents?.length > 0 ||
        allowedCmsComponents?.length > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPackageComponent?.length > 0) {
        navigate(`/${allowedPackageComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedBonusComponents?.length > 0) {
        navigate(`/${allowedBonusComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedReviewComponents?.length > 0) {
        navigate(`/${allowedReviewComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedOrderComponents?.length > 0) {
        navigate(`/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedSellerCouponComponents?.length > 0) {
        navigate(
          `/${allowedSellerCouponComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCouponComponents?.length > 0) {
        navigate(`/${allowedCouponComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedStudentsActivityComponents?.length > 0) {
        navigate(
          `/student-activity/${
            allowedStudentsActivityComponents?.map((item) => item?.path)?.[0]
          }`
        );
      } else if (allowedTrainingComponents?.length > 0) {
        navigate(
          `/training/${
            allowedTrainingComponents?.map((item) => item?.path)?.[0]
          }`
        );
      } else if (allowedPayoutsComponents?.length > 0) {
        navigate(
          `/${allowedPayoutsComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedAnnouncementComponents?.length > 0) {
        navigate(
          `/${allowedAnnouncementComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPackageComponent?.length > 0) {
        navigate(`/${allowedPackageComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedCustomerComponent?.length > 0) {
        navigate(
          `/${allowedCustomerComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedProgramComponents?.length > 0) {
        navigate(
          `/${allowedProgramComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedConfigureComponents?.length > 0) {
        navigate(
          `/configure/${
            allowedConfigureComponents?.map((item) => item?.path)?.[0]
          }`
        );
      } else if (allowedAgentComponents?.length > 0) {
        navigate(
          `/agent/${allowedAgentComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCmsComponents?.length > 0) {
        navigate(
          `/cms/${allowedCmsComponents?.map((item) => item?.path)?.[0]}`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedDashboardComponent,
    allowedBranchComponents,
    allowedConfigureComponents,
    allowedPackageComponent,
    allowedUserComponents,
    allowedBonusComponents,
    allowedProgramComponents,
    allowedRoleComponent,
    allowedCustomerComponent,
    allowedDailyMeditationComponents,
    allowedQuestionnaireComponents,
    allowedReviewComponents,
    allowedOrderComponents,
    allowedAgentComponents,
    allowedAnnouncementComponents,
    allowedPayoutsComponents,
    allowedTrainingComponents,
    allowedStudentsActivityComponents,
    allowedSellerCouponComponents,
    allowedCouponComponents,
    allowedCmsComponents,
  ]);

  const activeUser =
    JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found";

  let newPermissionArray =
    activeUser?.id === 1
      ? allowedCouponComponents
      : allowedSellerCouponComponents;
  let tempRoute = activeUser?.id === 1 ? "/coupon" : "/seller-coupon";
  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Packages",
      link: `/${allowedPackageComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/packages`),
    },
    {
      label: "Bonus Programs",
      link: `/${allowedBonusComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/bonus-programs`),
    },

    {
      label: "Customers",
      link: `/${allowedCustomerComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/customer`),
    },
    {
      label: "Programs",
      link: `/${allowedProgramComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/program`),
    },
    {
      label: "Review",
      link: `/${allowedReviewComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/review`),
    },
    {
      label: "Orders",
      link: `/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/order`),
    },

    {
      label: "Configure",
      link: `/configure/${
        allowedConfigureComponents?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute(`/configure`),
    },

    {
      label: "Agent",
      link: `/${allowedAgentComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/agent`),
    },
    {
      label: "Training",
      link: `/${allowedTrainingComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/training`),
    },
    {
      label: "Students Activity",
      link: `/student-activity/${
        allowedStudentsActivityComponents?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute(`/student-activity`),
    },
    {
      label: "Coupon",
      link: `/${newPermissionArray?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(tempRoute),
    },
    {
      label: "Coupons",
      link: `/${allowedCouponComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/coupons`),
    },
    {
      label: "Payouts",
      link: `/${allowedPayoutsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/seller-payouts`),
    },
    {
      label: "Announcement",
      link: `/${allowedAnnouncementComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/seller-announcement`),
    },
    {
      label: "CMS",
      link: `/${allowedCmsComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/cms`),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };
  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu?.menu_name,
      // "Dashboard",
    ]);
    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
