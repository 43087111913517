import React from "react";
import CommonLayout from "../../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useSellerPayouts from "./useSellerPayouts";

const SellerPayouts = () => {
  const { drawerMenu } = useSellerPayouts();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default SellerPayouts;
