import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useEffect, useState } from "react";
import useTrainingSessionForm from "./useTrainingSessionForm";
import {
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import Select from "react-select";
import Style from "../trainingSession.module.scss";
import { getAgentList } from "../../../../store/slices/TrainingSession/trainingSessionSlice";
import { useDispatch } from "react-redux";

const TrainingSessionForm = ({ refetch, closeModal, isStickyFooter }) => {
  const { formik, selectedItemsDetails } = useTrainingSessionForm({
    refetch,
    closeModal,
  });

  const today = new Date();
  const minDate = today.toISOString().split("T")[0];

  const [agentList, setAgentList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgentList())
      .unwrap()
      .then((result) => {
        setAgentList(
          result.data.data?.agent?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  const handleChange = (e, type) => {
    const newTime = e?.target?.value;
    // Validate the time format if needed
    if (/^([01]\d|2[0-3]):([0-5]\d)$/.test(newTime)) {
      if (type == "start_time") {
        formik.setFieldValue("start_time", newTime);
      } else if (type == "end_time") {
        formik.setFieldValue("end_time", newTime);
      }
    }
  };

  const [inputClass, setInputClass] = useState("");

  const handleDateChange = (e) => {
    formik.setFieldValue(`date`, e.target.value);
    setInputClass(e.target.value ? "date-selected" : "");
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

      <Input
        label={"Mentor*"}
        type="text"
        id="mentor"
        name="mentor"
        className={`pro-input lg ${
          formik.errors.mentor && formik.touched.mentor && "error"
        }`}
        {...formik.getFieldProps("mentor")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.mentor && formik.touched.mentor}
        errorMessage={formik.errors.mentor}
      />

      <div>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description
        </label>
        <textarea
          type="text"
          id="description"
          rows={4}
          cols={50}
          name="description"
          className={`pro-input lg ${
            formik.errors.description && formik.touched.description && "error"
          }`}
          value={formik?.values?.description}
          onChange={(event) =>
            formik.setFieldValue("description", event.target.value)
          }
        />
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <Input
        label={"Meeting Link*"}
        type="text"
        id="meeting_link
          "
        name="meeting_link
          "
        className={`pro-input lg ${
          formik.errors.meeting_link && formik.touched.meeting_link && "error"
        }`}
        {...formik.getFieldProps("meeting_link")}
        onChange={(event) =>
          formik.setFieldValue("meeting_link", event?.target?.value)
        }
        error={formik.errors.meeting_link && formik.touched.meeting_link}
        errorMessage={formik.errors.meeting_link}
      />

      <div className="col-md-12">
        <Input
          type="date"
          id={`date`}
          name={`date`}
          label={`Date`}
          min={minDate}
          value={formik?.values?.date}
          onBlur={formik.handleBlur(`date`)}
          className={`pro-input lg ${inputClass} ${
            formik.touched?.date && formik.errors?.date && " error"
          }`}
          {...formik.getFieldProps(`date`)}
          error={
            formik.touched?.date &&
            formik.errors?.date && (
              <span className="error-text">{formik.errors?.date}</span>
            )
          }
          errorMessage={
            formik.touched?.date &&
            formik.errors?.date && (
              <span className="error-text">{formik.errors?.date}</span>
            )
          }
          onChange={handleDateChange}
        />
      </div>

      <div className="col-md-12">
        <Input
          type="time"
          id={`start_time`}
          name={`start_time`}
          label={`Start Time`}
          value={formik?.values?.start_time}
          onBlur={formik.handleBlur(`start_time`)}
          className={`pro-input lg ${
            formik.touched?.start_time && formik.errors?.start_time && " error"
          }`}
          {...formik.getFieldProps(`start_time`)}
          error={
            formik.touched?.start_time &&
            formik.errors?.start_time && (
              <span className="error-text">{formik.errors?.start_time}</span>
            )
          }
          errorMessage={
            formik.touched?.start_time &&
            formik.errors?.start_time && (
              <span className="error-text">{formik.errors?.start_time}</span>
            )
          }
          onChange={(e) => handleChange(e, "start_time")}
        />
      </div>

      <div className="col-md-12">
        <Input
          type="time"
          id={`end_time`}
          name={`end_time`}
          label={`End Time`}
          value={formik?.values?.end_time}
          onBlur={formik.handleBlur(`end_time`)}
          className={`pro-input lg ${
            formik.touched?.end_time && formik.errors?.end_time && " error"
          }`}
          {...formik.getFieldProps(`end_time`)}
          error={
            formik.touched?.end_time &&
            formik.errors?.end_time && (
              <span className="error-text">{formik.errors?.end_time}</span>
            )
          }
          errorMessage={
            formik.touched?.end_time &&
            formik.errors?.end_time && (
              <span className="error-text">{formik.errors?.end_time}</span>
            )
          }
          onChange={(e) => handleChange(e, "end_time")}
        />
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Participants *
          </label>
          <Select
            id="participants"
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.errors.participants &&
              formik.touched.participants &&
              "error"
            }`}
            classNamePrefix="pro-input"
            isMulti={true}
            name="participants"
            options={agentList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={agentList?.filter((m) =>
              formik.values.participants?.includes?.(m?.value)
            )}
            onChange={(value) =>
              formik.setFieldValue(
                "participants",
                value.map((v) => v?.value)
              )
            }
            menuPlacement="auto"
          />
          {formik.touched.participants && formik.errors.participants && (
            <span className="error-text">{formik.errors.participants}</span>
          )}
        </div>
      </div>

      {/* toggle button start  */}

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default TrainingSessionForm;
