import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteData,
  updateConfig,
} from "../../../store/slices/program/programSlice";
import { addDays } from "date-fns";
import { useState, useMemo, useEffect } from "react";
import {
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/global";
import Style from "./user.module.scss";
import { useGetProgramListDataQuery } from "../../../store/queries/program";
import { useNavigate } from "react-router-dom";
import {
  getProgramDetails,
  updateConfig as formUpdateconfig,
} from "../../../store/slices/program/programFormSlice";
import { toast } from "react-toastify";

const usePrograms = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.program);
  const { showEditModal } = useSelector((state) => state.global);
  const { showCreateModal, editProgram } = useSelector(
    (state) => state.program
  );
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const { data: formData = {} } = useGetBasicDataQuery();
  const navigate = useNavigate();
  const [filterShow, setFilterShow] = useState(false);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetProgramListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState?.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    category: menuState.category,
    status: menuState.status,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      module_description: (field, data) => (
        <p className="pro-mb-0">
          {(data[field] || "").split(" ").slice(0, 30).join(" ") +
            ((data[field] || "").split(" ").length > 30 ? "..." : "")}
        </p>
      ),
      status_label: (field, data) => {
        // Determine the class based on the status
        const statusValue = data?.status_label?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "requested":
            statusClass = "badge-warning-outline";
            break;
          case "active":
            statusClass = "badge-success-outline";
            break;
          case "deleted":
            statusClass = "badge-info-outline";
            break;
          case "rejected":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[field]}</span>
          </p>
        );
      },
      module_name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/program-details/${data?.id}`,
              });

              sessionStorage.setItem("active", `${data?.id}`);
            }}
          >
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium pro-pnt`}
            >
              {data?.[feild]}
            </span>
          </div>
        );
      },
      category_name: (feild, data) => {
        return <div>{data?.["category"]?.category_name}</div>;
      },
      description: (feild, data) => {
        return <div>{data?.["category"]?.description}</div>;
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (data) => {
    dispatch(getProgramDetails(data?.[0]));

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.editProgram = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.editProgram = false;
        state.userEditData = {};
        state.clearSelection = true;
        state.formActiveTab = "Basic";
        state.selectedData = "";
      })
    );
    dispatch(
      formUpdateconfig((state) => {
        state.editProgram = false;
        state.formActiveTab = "Basic";
        state.selectedData = "";
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };
  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };
  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({ id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    mainData,
    isLoading,
    isFetching,
    hasEditPermission,
    hasViewPermission,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    activeFilter: menuState?.currentFilter,
    formData,
    showCreateModal,
    showEditModal,
    date,
    filters,
    handleDateRangeChange,
    handleFilter,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleCreateClick,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
    editProgram,
    setFilterShow,
    filterShow,
  };
};

export default usePrograms;
