import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("order_id")}${getParams(
          "start_date"
        )}${getParams("end_date")}${getParams("package_type")}${getParams(
          "filter"
        )}${getParams("sort_by")}&sort_order=${
          params?.sort_order
            ? params?.sort_order
            : (params?.product_sort_order
                ? params?.product_sort_order
                : "desc") || "desc"
        }&length=${params?.per_page || "10"}&${getParams("search")}&page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const packages = createApi({
  reducerPath: "packageApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["package", "country", "packagePrice"],
  endpoints: (builder) => ({
    getPackageListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-package`,
      }),
      providesTags: ["package"],
    }),
    getCountryListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin/country-list`,
      }),
      providesTags: ["country"],
    }),
    getPackageBasicData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `/v1/admin-package/show/${id}`,
      }),
      providesTags: ["package"],
    }),
    getPackagePriceListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `v1/admin-package/price?package_id=${values.package_id}`,
      }),
      providesTags: ["packagePrice"],
    }),
    createPackagePrice: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin-price/create`,
      }),
      invalidatesTags: ["packagePrice"],
    }),
    updatePackagePrice: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin-price/update`,
      }),
      invalidatesTags: ["packagePrice"],
    }),
    deletePackagePrice: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin-price/delete`,
      }),
      invalidatesTags: ["packagePrice"],
    }),
  }),
});

export const {
  useGetPackageListDataQuery,
  useGetPackageBasicDataQuery,
  useGetPackagePriceListDataQuery,
  useGetCountryListDataQuery,
  useCreatePackagePriceMutation,
  useUpdatePackagePriceMutation,
  useDeletePackagePriceMutation,
} = packages;
