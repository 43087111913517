import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createNewSellerCoupon,
  updateConfig,
  updateSellerCoupon,
} from "../../../../../store/slices/SellerModule/SellerCoupons/sellerCouponsSlice";

const useAddAddons = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { is_edit, selectedItemsDetails } = useSelector(
    (state) => state.sellerCoupons
  );
  const initialData = {
    type: is_edit ? selectedItemsDetails?.type : 2,
    code: is_edit ? selectedItemsDetails?.code : "",
    discount_percentage: is_edit
      ? selectedItemsDetails?.discount_percentage
      : 10,
    description: is_edit ? (selectedItemsDetails?.description !== null ?  selectedItemsDetails?.description : "" ): "",
    available_count: is_edit ? selectedItemsDetails?.available_count : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      code: Yup.string().required("*Required"),
      type: Yup.string().required("*Please select a type"),
      description: Yup.string()
        .min(2, "Minimum 2character needed")
        .max(500, "Maximum 500 character allowed"),
      discount_percentage: Yup.number()
        .typeError("Discount percentage must be a number")
        .min(0, "Minimum value is 0")
        .required("*Discount percentage required"),

      available_count: Yup.number().when("type", {
        is: "1",
        then: (schema) =>
          schema
            .required("Available Count is Required")
            .typeError("Must be a number"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),

    onSubmit: (values) => {
      const obj = {
        type: values?.type,
        discount_percentage: values?.discount_percentage,
        code: values?.code,
      };
      if (values?.description !== "") {
        obj.description = values?.description;
      }
      if (values?.type === 1) {
        obj.available_count = values?.available_count;
      }

      if (is_edit) {
        obj.id = selectedItemsDetails?.id;
      }

      if (is_edit) {
        dispatch(updateSellerCoupon(obj))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success("Coupon updated successfully");
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              closeModal();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewSellerCoupon(obj))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success("Coupon created successfully");
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              closeModal();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleButtonClick = (button) => {
    formik.setFieldValue("type", button === "b1" ? 2 : 1);
    formik?.setFieldValue("discount_percentage", 10);
    formik?.setFieldValue("description", "");
    formik.setFieldValue("available_count", "");
    formik?.setFieldValue("code", "");
  };

  return {
    formik,
    is_edit,
    getFieldError,
    handleButtonClick,
  };
};

export default useAddAddons;
