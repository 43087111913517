import {
  HeadingGroup,
  Permissions,
} from "@wac-ui-dashboard/wac_component_library";
import useManageRoles from "./useManageRoles";
import Style from "./manageRoles.module.scss";
// import { RolePermissions } from "../../../Global/RolePermissions";
const ManageRoles = () => {
  const {
    state,
    role,
    name,
    error,
    status,
    update,
    handleNameChange,
    handlePermissionChange,
    handleSave,
    setStatus,
  } = useManageRoles();
  return (
    <>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={update ? "Update" : "Create"}
        handleClick={handleSave}
        extraClassName={`pro-justify-between pro-pb-4`}
      />

      <div className={`row`}>
        <div className={`col-4`}>
          <div className="input-wrap pro-mb-5">
            <label
              htmlFor="role_name_input"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Name
            </label>
            <input
              id="role_name_input"
              type="text"
              value={name}
              // disabled={update ? true : ""}
              onChange={handleNameChange}
              className={`pro-input lg ${error && "error"}`}
            />
            {error && <span className="error-text">{error}</span>}
          </div>
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={Boolean(status) ?? false}
                onChange={(e) => {
                  setStatus(!Boolean(status) ?? 0);
                }}
                // disabled={hasEditPermission ? false : true}
              />
              <span></span>
            </div>
            <label>Status</label>
          </div>
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.menu_permissions?.map?.(
            (permission, index) => (
              <div className={Style.root}>
                {}
                <Permissions
                  title={permission?.menu_name}
                  permissions={permission?.submenus}
                  defaultCheckedList={
                    state.rolePermission?.[role]?.modules?.filter?.(
                      (m) => m.module_id === permission.id
                    )?.[0]?.permissions || []
                  }
                  key={index}
                  uniqueID={"id"}
                  handleChange={(item) =>
                    handlePermissionChange(item, permission.id)
                  }
                />
              </div>
            )
          )}
      </div>
    </>
  );
};

export default ManageRoles;
