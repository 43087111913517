import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import useSellerPayout from "./useSellerPayout";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../../Global/EmptyData";
import OrderColumn from "../../../Global/OrderColumn";
// import AddAddons from "./AddAddons";

const SellerPayout = () => {
  const {
    mainData,
    menuState,
    isLoading,
    isFetching,
    currentPage,
    paginationOptions,
    showDeleteConfirm,
    handleEditAction,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    showEditModal,
    handleSearch,
    handleClearClick,
    getRow,
    handleSort,
    handlePagination,
    handlePageSize,
    handleCreateClick,
    handleEditColumnsClick,
    updateTableFields,
    closeEditModal,
    refetch,
  } = useSellerPayout();

  return (
    <>
      <HeadingGroup
        title={"Payouts"}
        extraClassName={`pro-mb-4`}
        // buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />
      <div className="row">
        <div className="col">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={true}
            handleActionClick={handleEditColumnsClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            handleClear={handleClearClick}
            searchInputProps={{ value: menuState?.search }}
          />
        </div>
      </div>

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {mainData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Table
              data={mainData?.data?.data || []}
              uniqueID={"id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              showCheckBox={false}
              deletable={true}
              editable={true}
              handleEdit={handleEditAction}
              handleDelete={handleDelete}
              clear={menuState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.field}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          </>
        )}

        {mainData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.total_count}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.field ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default SellerPayout;
