import style from "./WebinarShimmer.module.scss";
const WebinarShimmer = () => {
  return (
    <>
      <div className={` ${style.shimmer_container} col pro-d-flex  pro-h-100 pro-justify-between  `}>
        <div className="pro-w-25 col-auto pro-d-flex pro-justify-center  pro-flex-column pro-pe-4">
            <div className="shimmer pro-p-1 pro-w-50 letter para  pro-mx-auto"></div>
            <div className="shimmer pro-p-3 pro-w-25 letter para pro-my-4 pro-mx-auto"></div>
            <div className="shimmer pro-p-1 pro-w-75 letter para  pro-mx-auto"></div>
        </div>
        <div className="col">
          <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
          <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-2"></div>
          <div className="shimmer pro-p-1 pro-w-25 letter para pro-mt-1"></div><br/>
          <div className="shimmer pro-p-1 pro-w-50 letter para pro-mt-2 "></div><br/>
          <div className=" pro-w-50">
            <div className="shimmer pro-p-4 pro-w-75 letter para pro-mt-3 "></div>
          </div>
        </div>
      </div>
      <div className={` ${style.shimmer_container} col  pro-d-flex  pro-h-100 pro-justify-between `}>
        <div className="pro-w-25 col-auto pro-d-flex pro-justify-center  pro-flex-column pro-pe-4">
            <div className="shimmer pro-p-1 pro-w-50 letter para  pro-mx-auto"></div>
            <div className="shimmer pro-p-3 pro-w-25 letter para pro-my-4 pro-mx-auto"></div>
            <div className="shimmer pro-p-1 pro-w-75 letter para  pro-mx-auto"></div>
        </div>
        <div className="col">
          <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
          <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-2"></div>
          <div className="shimmer pro-p-1 pro-w-25 letter para pro-mt-1"></div><br/>
          <div className="shimmer pro-p-1 pro-w-50 letter para pro-mt-2 "></div><br/>
          <div className=" pro-w-50">
            <div className="shimmer pro-p-4 pro-w-75 letter para pro-mt-3 "></div>
          </div>
        </div>
      </div>
      <div className={` ${style.shimmer_container} col pro-d-flex  pro-h-100 pro-justify-between  `}>
        <div className="pro-w-25 col-auto pro-d-flex pro-justify-center  pro-flex-column pro-pe-4">
            <div className="shimmer pro-p-1 pro-w-50 letter para  pro-mx-auto"></div>
            <div className="shimmer pro-p-3 pro-w-25 letter para pro-my-4 pro-mx-auto"></div>
            <div className="shimmer pro-p-1 pro-w-75 letter para  pro-mx-auto"></div>
        </div>
        <div className="col">
          <div className="shimmer pro-p-2 pro-w-100 letter para pro-mt-1 "></div>
          <div className="shimmer pro-p-1 pro-w-100 letter para pro-mt-2"></div>
          <div className="shimmer pro-p-1 pro-w-25 letter para pro-mt-1"></div><br/>
          <div className="shimmer pro-p-1 pro-w-50 letter para pro-mt-2 "></div>
          <div className=" pro-w-50">
            <div className="shimmer pro-p-4 pro-w-75 letter para pro-mt-3 "></div>
          </div>
          
        </div>
      </div>
    </>
      
      
  );
};

export default WebinarShimmer;
