import React from "react";
import {
  Header,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useStudentDetailsLayout from "./useStudentDetailsLayout";
import profileStyle from "./studentDetailsLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../../../utils/components/ProtectRoute/ProtectRoute";
import Assets from "../../../../../assets/Assets";
import HeaderActions from "../../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../../pages/Layouts/CommonLayout/useCommonLayout";

const StudentDetailsLayout = () => {
  const {
    activeProfile,
    isFetching,
    basicData,
    basicDetails,
    handleEditClick,
    navigation,
  } = useStudentDetailsLayout();

  const { navigations, globalState } = useCommonLayout();


  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(basicData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5 left-sidebar`}>
                    <div className={`pro-pt-2 pro-pb-1 pro-mb-4 `}>
                      <Link to={`/student-activity/my-student`} className="pro-back-btn pro-items-center pro-d-flex pro-items-center pro-mb-2">
                        <i className="material-symbols-outlined x4">
                          {" "}
                          keyboard_arrow_left{" "}
                        </i>
                        <span>{`Back`}</span>  
                      </Link>
                    </div>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            basicData?.data?.profile_photo_url ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <h2 className={profileStyle.left_sidebar_title}>{basicData?.data?.name}</h2>
                      <p className={profileStyle.left_sidebar_joined_date}>Joined on {basicData?.data?.date_of_join}</p>
                      <div className="pro-d-flex  pro-justify-between pro-border-top pro-pt-4">
                        
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Basic Details"}
                    data={basicDetails}
                    // editIcon={
                    //     <span className="material-symbols-outlined">
                    //       {basicData?.result?.permissions["can_edit"]  ? `edit_square` : ``}
                    //     </span>
                    // }
                    handleEdit={() => handleEditClick()}
                    extraClassNames={`multi_column_table_item_details `}
                  />
                      </div> 
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <div className={` tab_wrapper`}>
                <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
                <Outlet context={{ activeProfile }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default StudentDetailsLayout;
