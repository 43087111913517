import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useBonusProgram from "./useBonusProgram";

const BonusProgram = () => {
  const { drawerMenu } = useBonusProgram();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default BonusProgram;
