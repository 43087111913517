import React, { useEffect, useState } from "react";
import {
  ModalLayout,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Dropdown from "react-bootstrap/Dropdown";
import Assets from "../../../../assets/Assets";
import ManageProfileForm from "../../../../components/Auth/ManageProfile";
import GlobalSearch from "../../../../components/Global/GlobalSearch";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";

const HeaderActions = () => {
  const {
    isModalOpen,
    userData,
    searchInputRef,
    globalState,
    lastElement,
    closeModal,
    handleSearchData,
    activeUser,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleProfileRedirect,
    handleCloseProfileView,
    showModal,
    updateUserDetails,
  } = useHeaderActions();

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    // Detect scrollbar presence and calculate its width
    const documentWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    const scrollbarWidth = windowWidth - documentWidth;

    setScrollbarWidth(scrollbarWidth);
    setHasScrollbar(scrollbarWidth > 0);
  }, []);

  return (
    <div
      className={`pro-ms-auto pro-d-flex pro-items-center  ${Style.root}`}
      style={{ paddingRight: hasScrollbar ? `${scrollbarWidth}px` : "" }}
    >
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            {activeUser?.profile_image ? (
              <img src={activeUser.profile_image} alt="user profile" />
            ) : activeUser?.gender ? (
              activeUser.gender === 1 ? (
                <img src={Assets.MALEPROFILE} alt="male" />
              ) : activeUser.gender === 2 ? (
                <img src={Assets.FEMALEPROFILE} alt="female" />
              ) : (
                <img src={Assets.GENDER_IMAGE} alt="other" />
              )
            ) : (
              <img src={Assets.GENDER_IMAGE} alt="other" />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleViewProfile}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3">
              {" "}
              account_circle{" "}
            </span>
            <span className="pro-ms-2">Profile</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleSettings}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> settings </span>
            <span className="pro-ms-2">Settings</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <OffCanvasLayout
        show={globalState.isProfileViewVisible}
        handleClose={handleCloseProfileView}
        title={"Manage Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop="static"
      >
        <ManageProfileForm
          closeModal={handleCloseProfileView}
          updateUserDetails={updateUserDetails}
        />
      </OffCanvasLayout>

      <ModalLayout
        handleClose={closeModal}
        show={isModalOpen}
        backdrop="static"
      >
        <GlobalSearch
          handleSearchData={handleSearchData}
          lastElement={lastElement}
          searchKey={globalState.searchKey}
          userData={userData}
          ref={searchInputRef}
          handleProfileRedirect={handleProfileRedirect}
        />
      </ModalLayout>

      {/* why the below modal, is this required ....????? */}
      <ModalLayout
        show={showModal}
        handleClose={closeModal}
        title={"Manage Profile"}
        // closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        className="profile-modal"
      >
        <div className="pro-p-5">
          <ManageProfileForm
            closeModal={closeModal}
            updateUserDetails={updateUserDetails}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default HeaderActions;
