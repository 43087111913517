import { getAxiosInstance } from "../../../api";

export const updateTermsAndConditions = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/cms/terms-and-conditions/update`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
