import { Button } from "@wac-ui-dashboard/wac_component_library";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Style from "./editpolicy.module.scss";
import useEditPolicy from "./useEditPolicy";

const EditPolicy = ({ refetch }) => {
  const { formik, handleCloseModal, handleContentChange } = useEditPolicy({
    refetch,
  });

  return (
    <>
      <div className="pro-p-5">
        <div className={Style.editor_combined_main}>
          <div className={Style.editor_half}>
            {formik?.values?.description ? (
              <div className={Style.editor_container}>
                <Editor
                  wrapperClassName={Style.pro_editor_wrapper}
                  editorClassName={Style.pro_editor_main}
                  toolbarClassName={Style.pro_editor_toolbar}
                  editorState={formik.values.description}
                  readOnly={false}
                  onEditorStateChange={handleContentChange}
                  toolbar={{
                    blockType: {
                      inDropdown: true,
                      className: Style.dropdown_wrapper,
                      dropdownClassName: Style.dropdown_menu,
                    },
                    fontSize: {
                      className: Style.dropdown_wrapper,
                      dropdownClassName: Style.dropdown_menu,
                    },
                    fontFamily: {
                      className: Style.dropdown_wrapper,
                      dropdownClassName: Style.dropdown_menu,
                    },
                  }}
                />
                {formik.touched.description && formik.errors.description && (
                  <span className={Style.error_text}>
                    {formik.errors.description}
                  </span>
                )}
              </div>
            ) : (
              // <span>Loading...</span>
              <div className={Style.editor_shimmer}>
                <figure className={Style.preloader}>
                  <div className="app-loading"></div>
                </figure>
              </div>
            )}
          </div>
        </div>
        <div className="pro-d-flex pro-justify-end pro-pt-4">
          <button
            className={"pro-btn-link lg "}
            onClick={handleCloseModal}
            type="button"
          >
            Cancel
          </button>
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditPolicy;
