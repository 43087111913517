import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  isLoading:false,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  categoriesList: [],
  customerDetails: {},
  editProgram: false,
  selectedData: "",
  formActiveTab: "Basic",
  selectedId: ""
};

export const getCategories = createAsyncThunk(
  "programForm/categories",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-module/category-listing");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const createNewProgram = createAsyncThunk(
  "new/createNewProgram",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-module/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProgram = createAsyncThunk(
  "new/updateProgram",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-module/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPrice = createAsyncThunk(
  "new/getPrice",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-module-price/change?mrp=${params?.mrp}&offer_percentage=${params?.offer_percentage}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProgramDetails = createAsyncThunk(
  "v1/admin-module/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/admin-module/show/${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const programFormSlice = createSlice({
  name: "programForm",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
    resetEditAction: (state, action) => {
      state.customerDetails = {};
      state.editCustomer = false;
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => {},
    [getCategories.fulfilled]: (state, action) => {
      state.categoriesList = action.payload.data.data;
    },
    [getCategories.rejected]: (state, action) => {},


    [createNewProgram.pending]: (state, action) => {
      state.isLoading=true
    },
    [createNewProgram.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [createNewProgram.rejected]: (state, action) => {
      state.isLoading=false
    },
    
  },
  extraReducers: (builder) => {
    builder
    .addCase(getProgramDetails.pending, (state, action) => {
         state.isTravelLoading = true;
       })
      .addCase(getProgramDetails.fulfilled, (state, action) => {
        state.editProgram = true;
        state.selectedData = action?.payload?.data?.data
      })
      .addCase(getProgramDetails.rejected, (state, action) => {
        state.isTravelLoading = false;
      })
      
  },
});

export const { updateConfig, setEditTestimonail, resetEditAction } =
  programFormSlice.actions;

export default programFormSlice.reducer;
