import {
   HeadingGroup,
   ModalLayout,
   Pagination,
   SearchFilters,
   OffCanvasLayout,
   Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import useCustomers from './useCustomers'
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import Filter from "./Filter";

const Customers = ({dashboard = false}) => {
   const {
      mainData,
      menuState,
      actionOptions,
      isLoading,
      hasEditPermission,
      hasViewPermission,
      isFetching,
      handleDateChange,
      currentPage,
      formData,
      showEditModal,
      showCreateModal,
      paginationOptions,
      activeFilter,
      date,
      handleDateRangeChange,
      handleFilter,
      updateTableFields,
      closeEditModal,
      handleSearch,
      handleEditClick,
      handleActionChange,
      handleClearClick,
      getRow,
      handleSort,
      handleEditAction,
      handlePagination,
      handlePageSize,
      closeModal,
      refetch,
      filterShow,
    setFilterShow,
      handleDashboardRedirect,
      handleExportClick
   } = useCustomers({dashboard});

   return (
      <>
      {!dashboard && (
         <HeadingGroup
            title={"Customers"}
            className={`pro-mb-4`}
         />
      )}

         <div className={`col-auto pro-pt-3 pro-pb-6`}>
         {!dashboard && (
            <div className="row">
               <div className="col">
                  <SearchFilters
                     onSearchInput={handleSearch}
                     showActions={true}
                     handleActionClick={handleEditClick}
                     loading={isLoading}
                     SearchIcon={
                        <span className="material-symbols-outlined"> search </span>
                     }
                     handleClear={handleClearClick}
                     searchInputProps={{ value: menuState?.search }}
                     extraFilters={
                        <>
                        <div className="col-auto">
                          <button
                            className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                            onClick={() => setFilterShow(true)}
                          >
                            <span className="material-symbols-outlined">tune</span>
                            <span>Filter</span>
                          </button>
                          
                        </div>
                        <div className="col-auto">
                        <button
                            className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `}
                            onClick={() => handleExportClick()}
                          >
                            <span className="material-symbols-outlined">download</span>
                            <span>Export</span>
                          </button>
                          </div>
                        </>
                        
                        
                      }
                  />
               </div>
            </div>
         )}
            {mainData?.data?.rows?.data?.length === 0 ? (
               <EmptyData />
            ) : (
               <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            >
               <Table
                  data={mainData?.data?.rows?.data || []}
                  uniqueID={"id"}
                  editIcon={<span className="material-symbols-outlined">edit</span>}
                  showCheckBox={hasViewPermission === 1 ? true : false}
                  deletable={false}
                  handleEdit={handleEditAction}
                  clear={menuState.clearSelection}
                  multiSelect={false}
                  assignable={false}
                  fields={mainData?.data?.field}
                  SortIcon={!dashboard && <FaSort />}
                  handleSort={handleSort}
                  getRow={getRow}
                  loading={isFetching}
                  perpage={menuState?.currentPageSize}
               />
               </div>
            )}

            {mainData?.data?.rows?.data?.length > 0 && !dashboard && (
               <Pagination
                  currentPage={currentPage}
                  defaultValue={paginationOptions?.filter(
                     (item) => item.value === menuState?.currentPageSize
                  )}
                  totalPageCount={mainData?.data?.rows?.last_page}
                  onPageChange={handlePagination}
                  options={paginationOptions}
                  onActionChange={handlePageSize}
                  center
               />
            )}

            {/* <OffCanvasLayout
               show={showCreateModal}
               handleClose={closeModal}
               // title={"Add Branch"}
               title={menuState?.is_edit ? "Update User" : "Add User"}
               closeIcon={<span className="material-symbols-outlined">close</span>}
               backdrop="static"
            >
               <UserForm
                  refetch={refetch}
                  closeModal={closeModal}
                  formData={formData?.data}
                  hasEditPermission = {hasEditPermission}
               />
            </OffCanvasLayout> */}

            <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
               <div className="pro-m-5">
                  <OrderColumn
                     title={"Choose which columns you see"}
                     refetch={refetch}
                     tableFields={Object.keys(mainData?.data?.field ?? {})
                        .reduce((filteredObj, property) => {
                           filteredObj[property] = mainData?.data?.field[property];
                           return filteredObj;
                        }, {})}
                     moduleId={mainData?.data?.module_id}
                     updateData={updateTableFields}
                  />
               </div>
            </ModalLayout>
            <OffCanvasLayout
          centered={true}
          show={filterShow}
          handleClose={() => {
            setFilterShow(false);
          }}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterShow} refetch={refetch} />
        </OffCanvasLayout>
         </div>
      </>
   )
}

export default Customers
