import Style from './AnnouncementShimmer.module.scss';

const AnnouncementShimmer = () => {
  return (
    <>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4  `}>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-2 pro-mb-1 pro-w-75'></span>
            </div>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-1  pro-mb-1 pro-w-50'></span>
            </div>
            <div className='pro-w-25'>
              <span className='shimmer pro-d-block pro-p-1 pro-w-75'></span>
            </div>
          </div>
          <div  className={`  pro-ps-4 pro-pe-5 pro-w-25`}>
            <span className='shimmer pro-d-block pro-p-1 pro-ms-auto pro-w-75'>
              <span  className=' pro-d-block pro-pt-1'></span>
            </span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4  `}>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-2 pro-mb-1 pro-w-75'></span>
            </div>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-1  pro-mb-1 pro-w-50'></span>
            </div>
            <div className='pro-w-25'>
              <span className='shimmer pro-d-block pro-p-1 pro-w-75'></span>
            </div>
          </div>
          <div  className={`  pro-ps-4 pro-pe-5 pro-w-25`}>
            <span className='shimmer pro-d-block pro-p-1 pro-ms-auto pro-w-75'>
              <span  className=' pro-d-block pro-pt-1'></span>
            </span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4  `}>
            <div className='pro-w-50'> 
              <span className='shimmer pro-d-block pro-p-2 pro-mb-1 pro-w-75'></span>
            </div>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-1  pro-mb-1 pro-w-50'></span>
            </div>
            <div className='pro-w-25'>
              <span className='shimmer pro-d-block pro-p-1 pro-w-75'></span>
            </div>
          </div>
          <div  className={`  pro-ps-4 pro-pe-5 pro-w-25`}>
            <span className='shimmer pro-d-block pro-p-1 pro-ms-auto pro-w-75'>
              <span  className=' pro-d-block pro-pt-1'></span>
            </span>
          </div>
        </div>
      </div>
      <div className={`${Style.root} pro-border-bottom  `}>
        <div className=" pro-px-4 pro-py-3 pro-d-flex pro-justify-between ">
          <div className={` ${Style.image} pro-flex-shrink-0 blink pro-rounded-circle pro-me-4`} ></div>
          <div className={` ${Style.text} flex-fill pro-me-4  `}>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-2 pro-mb-1 pro-w-75'></span>
            </div>
            <div className='pro-w-50'>
              <span className='shimmer pro-d-block pro-p-1  pro-mb-1 pro-w-50'></span>
            </div>
            <div className='pro-w-25'>
              <span className='shimmer pro-d-block pro-p-1 pro-w-75'></span>
            </div>
          </div>
          <div  className={`  pro-ps-4 pro-pe-5 pro-w-25`}>
            <span className='shimmer pro-d-block pro-p-1 pro-ms-auto pro-w-75'>
              <span  className=' pro-d-block pro-pt-1'></span>
            </span>
          </div>
        </div>
      </div>
    </>
        
  )
}

export default AnnouncementShimmer