import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/Testimonial/testimonialSlice.js";

const useTestimonialForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.testimonial);
  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails ? selectedItemsDetails?.image : ""
  );

  const [thumbnail, setThumbnail] = useState(
    selectedItemsDetails ? selectedItemsDetails?.video : null
  );
  const [isType, setIsType] = useState("")

	useEffect(() => {
		if (selectedItemsDetails !== "") {
			setImagePreview(selectedItemsDetails?.image ?? "");
      setThumbnail(selectedItemsDetails?.video ?? "");
		}
	}, [selectedItemsDetails]);

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Required"),
      comment: Yup.string()
      .min(2, "The comment must be at least 2 characters")
      .max(500, "max 500 characters allowed")
      .required("*Required"),
      place: Yup.string()
      .required("*Required"),
      type: Yup.string()
      .required("*Select one"),
      video: isType === 1 && Yup.string().required("*Required") 
    
  });


  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
      ? selectedItemsDetails.id
      : "",
      
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      comment:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.comment
          : "",
      place:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.place
          : "",
      type: is_edit ? selectedItemsDetails?.type : "",
      video: "",
      
      status: selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : '',
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        name: values.name,
        comment: values.comment,
        place: values.place,
        type: values.type,
        
      };
      if(is_edit) {
        obj.status = +values.status;
      }
      if(values?.image) {
        obj.image = values?.image;
      }
      if(values?.type == 1 && values?.video != "") {
        obj.video = values?.video;
      }
      
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        // formData.append("_method", "PUT");
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "image",
          "The image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "image",
        "The image must be less than 5MB in size."
      );
    }
  };


  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };


  const handleVideosChange = async (e) => {
    const files = e?.target?.files[0];
    const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError("video", "invalid file type");
      return;
      // errors.push(`File ${file.name} is not a valid video format`);
    } else if (files.size > maxSizeInBytes) {
      // errors.push(`File ${file.name} exceeds the 10 MB limit`);
      formik.setFieldError("video", "invalid file size");
      return;
    }

    if (allowedFormats.includes(files.type)) {
      formik.setFieldValue("video", e?.target?.files?.[0]);
    }
    const thumbnailFile = await generateThumbnail(files);

    const reader = new FileReader();

    reader.onloadend = () => {
      setThumbnail(reader.result);
    };

    if (thumbnailFile) {
      reader.readAsDataURL(thumbnailFile);
    }

    // Function to generate thumbnail using async/await
    async function generateThumbnail(file) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadeddata = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.currentTime = 2; // Set the time to 1 second (adjust if needed)
          video.play().then(async () => {
            video.pause();
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // Here, you can use imlet gData or perform further actions with the second frame
            let blob = await dataURLtoBlob(canvas.toDataURL());
            const thumbnailFile = new File([blob], `thumbnail.jpg`, {
              type: "image/jpeg",
            });
            resolve(thumbnailFile);
          });
        };
        video.src = URL.createObjectURL(file);
        video.load();
      });
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    handleImage,
    imagePreview,
    is_edit,
    setIsType,
    handleVideosChange,
    thumbnail
  };
};

export default useTestimonialForm;
