import { useGetOrdererDetailDataQuery } from "../../../../store/queries/order";
import { useParams } from "react-router-dom";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Order/ordersSlice";

const useOrderDetailLayout = () => {
  const { orderID } = useParams();
  const dispatch = useDispatch();
  const { checkIfActiveRoute } = useRouteUtils();
  const { data: orderDetailsData = {}, isFetching } =
    useGetOrdererDetailDataQuery({
      order_id: orderID,
    });

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.productData = orderDetailsData?.data?.products;
        state.transactionData = orderDetailsData?.data?.transaction;
      })
    );
    //eslint-disable-next-line
  }, [orderDetailsData]);

  const navigation = [
    {
      label: "Products",
      title: "Products",
      link: `/order/order-details/${orderID}`,
      active: checkIfActiveRoute(`/order/order-details/${orderID}`, true),
    },
    {
      label: "Transaction",
      title: "Transaction",
      link: `/order/order-details/${orderID}/transaction`,
      active: checkIfActiveRoute(
        `/order/order-details/${orderID}/transaction`,
        true
      ),
    },
  ];

  const AddressLabel = {
    name: "Name",
    email: "Email",
    phone_number: "Phone Number",
    city: "City",
    state: "State",
    country: "Country",
    pin_code: "Pincode",
  };

  const AddressValue = {
    name: `${
      orderDetailsData?.data?.address?.name !== ""
        ? orderDetailsData?.data?.address?.name
        : "Not specified"
    }`,
    email: `${
      orderDetailsData?.data?.address?.email !== ""
        ? orderDetailsData?.data?.address?.email
        : "Not specified"
    }`,
    phone_number: `${
      orderDetailsData?.data?.address?.phone_number !== ""
        ? orderDetailsData?.data?.address?.phone_number
        : "Not specified"
    }`,
    city: `${
      orderDetailsData?.data?.address?.city !== ""
        ? orderDetailsData?.data?.address?.city
        : "Not specified"
    }`,
    state: `${
      orderDetailsData?.data?.address?.state !== ""
        ? orderDetailsData?.data?.address?.state
        : "Not specified"
    }`,
    country: `${
      orderDetailsData?.data?.address?.country !== ""
        ? orderDetailsData?.data?.address?.country
        : "Not specified"
    }`,
    pin_code: `${
      orderDetailsData?.data?.address?.pin_code !== ""
        ? orderDetailsData?.data?.address?.pin_code
        : "Not specified"
    }`,
  };

  const AddressDetails = Object.keys(AddressValue).map((key) => {
    return {
      label: AddressLabel[key],
      value:
        typeof AddressValue[key] === "string"
          ? AddressValue?.[key]
          : typeof AddressValue[key] === "number"
          ? AddressValue?.[key]
          : AddressValue?.[key]?.name,
    };
  });

  const OrderLabel = {
    id: "Order ID",
    order_number: "Order Number",
    customer: "Customer",
    purchase_date: "Purchase Date",
    expiry_date: "Expiry Date",
    payment_status: "Payment Status",
    discount_amount: "Discount Amount",
    total_amount: "Total Amount",
  };

  const OrderValue = {
    id: `${
      orderDetailsData?.data?.id !== ""
        ? orderDetailsData?.data?.id
        : "Not Specified"
    }`,
    order_number: `${
      orderDetailsData?.data?.order_number !== ""
        ? orderDetailsData?.data?.order_number
        : "Not Specified"
    }`,
    customer: `${
      orderDetailsData?.data?.customer !== ""
        ? orderDetailsData?.data?.customer
        : "Not Specified"
    }`,
    purchase_date: `${
      orderDetailsData?.data?.purchase_date !== ""
        ? orderDetailsData?.data?.purchase_date
        : "Not Specified"
    }`,
    expiry_date: `${
      orderDetailsData?.data?.expiry_date !== ""
        ? orderDetailsData?.data?.expiry_date
        : "Not specified"
    }`,
    payment_status: `${
      orderDetailsData?.data?.payment_status !== ""
        ? orderDetailsData?.data?.payment_status
        : "Not specified"
    }`,
    discount_amount: `${
      orderDetailsData?.data?.discount_amount !== ""
        ? orderDetailsData?.data?.discount_amount
        : "Not specified"
    }`,
    total_amount: `${
      orderDetailsData?.data?.total_amount !== ""
        ? orderDetailsData?.data?.total_amount
        : "Not specified"
    }`,
  };

  const OrderDetails = Object.keys(OrderValue).map((key) => {
    return {
      label: OrderLabel[key],
      value:
        typeof OrderValue[key] === "string"
          ? OrderValue?.[key]
          : typeof OrderValue[key] === "number"
          ? OrderValue?.[key]
          : OrderValue?.[key]?.name,
    };
  });
  return {
    orderDetailsData,
    AddressDetails,
    OrderDetails,
    isFetching,
    navigation,
  };
};

export default useOrderDetailLayout;
