import parse from "html-react-parser";
import { useGetTermsAndConditionDataQuery } from "../../../store/queries/cms";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateConfig } from "../../../store/slices/Cms/termsAndConditionSlice";

const useTermsAndCondition = () => {
  const dispatch = useDispatch();
  const termsAndConditionState = useSelector(
    (state) => state.termsAndCondition
  );
  const { data: termsAndConditionsData = {}, refetch } =
    useGetTermsAndConditionDataQuery({});
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.description = termsAndConditionsData?.data?.content;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAndConditionsData?.data?.content]);

  const handleOpenEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.editModal = true;
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.editModal = false;
      })
    );
  };
  return {
    termsAndConditionsData,
    termsAndConditionState,
    refetch,
    handleOpenEditModal,
    handleCloseModal,
    parse,
  };
};

export default useTermsAndCondition;
