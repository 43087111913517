import { getAxiosInstance } from "../../../../../api";

export const createPincode = async (data) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`/admin/branch/pincode/create`, data);
          return response;
     } catch (error) {
          return error?.response?.data;
     }
};

export const updatePincode = async (data) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`/admin/branch/pincode/update`, data);
          return response;
     } catch (error) {
          return error?.response?.data;
     }
};