import { Outlet } from "react-router-dom";
import CommonLayout from "../../../pages/Layouts/CommonLayout";
import useAgentTraining from "./useAgentTraining";

const AgentTraining = () => {
  const { drawerMenu } = useAgentTraining();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default AgentTraining;
