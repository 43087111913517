import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateConfig } from "../../../../../../store/slices/Branches/branchSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  updateRateDetails,
  updateFormData,
} from "../../../../../../store/slices/Branches/branchRateFormSlice";
import * as Yup from "yup";
import { useGetBasicDataQuery } from "../../../../../../store/queries/global";

const useBuy = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.branchRateForm);
  const { selectedId } = useSelector((state) => state.branch);
  const { data: formData = {} } = useGetBasicDataQuery();

  const validation = Yup.object({
    cash_markup_value: Yup.number()
      .min(0, "Value must be at least 0")
      .max(100, "Value must be at most 100"),
    travel_markup_value: Yup.number(),
    // .required("Travel markup percentage is Required"),
  });

  const formik = useFormik({
    initialValues: {
      cash_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[0]?.Cash
            ? selectedItemsDetails?.buy_details[0]?.Cash?.rate
            : selectedItemsDetails?.buy_details[1]?.Cash?.rate
          : "",
      cash_markup_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[0]?.Cash
            ? selectedItemsDetails?.buy_details[0]?.Cash?.markup_type
            : selectedItemsDetails?.buy_details[1]?.Cash?.markup_type
          : "",
      cash_markup_value:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[0]?.Cash
            ? selectedItemsDetails?.buy_details[0]?.Cash?.markup_value
            : selectedItemsDetails?.buy_details[1]?.Cash?.markup_value
          : "",
      cash_forex_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[0]?.Cash
            ? selectedItemsDetails?.buy_details[0]?.Cash?.total_amount
            : selectedItemsDetails?.buy_details[1]?.Cash?.total_amount
          : "",

      cash_status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[0]?.Cash?.status !== null
            ? selectedItemsDetails?.buy_details[0]?.Cash
              ? selectedItemsDetails?.buy_details[0]?.Cash?.status
              : selectedItemsDetails?.buy_details[1]?.Cash?.status
            : 0
          : 0,
      travel_card:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[1]["Travel Card"]
            ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.rate
            : selectedItemsDetails?.buy_details[0]["Travel Card"]?.rate
          : "",
      travel_markup_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[1]["Travel Card"]
            ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.markup_type
            : selectedItemsDetails?.buy_details[0]["Travel Card"]?.markup_type
          : "",
      travel_markup_value:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[1]["Travel Card"]
            ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.markup_value
            : selectedItemsDetails?.buy_details[0]["Travel Card"]?.markup_value
          : "",
      travel_forex_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[1]["Travel Card"]
            ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.total_amount
            : selectedItemsDetails?.buy_details[0]["Travel Card"]?.total_amount
          : "",
      travel_status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.status !== null
            ? selectedItemsDetails?.buy_details[1]["Travel Card"]
              ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.status
              : selectedItemsDetails?.buy_details[0]["Travel Card"]?.status
            : 0
          : 0,
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        branch_currency_id: selectedId,
        type: 1,
        product: [
          {
            id: selectedItemsDetails?.buy_details[0]["Cash"]
              ? selectedItemsDetails?.buy_details[0]["Cash"]?.id
              : selectedItemsDetails?.buy_details[1]["Cash"]?.id,
            markup_type: values?.cash_markup_type,
            markup_value: +values?.cash_markup_value ?? 0,
            rate: values?.cash_rate,
            total_amount: values.cash_forex_rate,
            status: +values?.cash_status ?? 0,
          },
          {
            id: selectedItemsDetails?.buy_details[1]["Travel Card"]
              ? selectedItemsDetails?.buy_details[1]["Travel Card"]?.id
              : selectedItemsDetails?.buy_details[0]["Travel Card"]?.id,
            markup_type: values?.travel_markup_type,
            markup_value: +values?.travel_markup_value ?? 0,
            rate: values?.travel_card,
            total_amount: values.travel_forex_rate,
            status: +values?.travel_status ?? 0,
          },
        ],
      };

      dispatch(updateRateDetails(obj)).then((response) => {
        if (response?.payload?.data?.success) {
          resetForm();
          closeModal?.();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.payload?.data?.message);
        } else if (!response?.payload?.status) {
			if(response?.payload?.data[`product.0.total_amount`]) {
				formik.setFieldError("cash_markup_value",response?.payload?.data[`product.0.total_amount`]);
			}
			if(response?.payload?.data[`product.1.total_amount`]) {
				formik.setFieldError("travel_markup_value",response?.payload?.data[`product.1.total_amount`]);
			}
			
        } else formik.setErrors(response?.payload?.message);
      });
    },
  });

  // updating the completed percentage of butfields
  const buyFields = ["cash_markup_value", "travel_markup_value"];

  var completedFields = buyFields?.filter((field) => {
    return !!!formik?.errors[field];
  });

  const completedPercentageBuy =
    (completedFields.length / buyFields.length) * 100;

  useEffect(() => {
    // Dispatch the action to update the Redux state
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Buy: completedPercentageBuy,
        };
      })
    );
    //eslint-disable-next-line
  }, [completedPercentageBuy]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const setTypeFieldValue = (id) => {
    // formik?.setFieldValue("cash_markup_type", value?.id || null)
    if (formik.values?.cash_markup_value) {
      handleChangeCashForex(formik.values?.cash_markup_value, id);
    }
  };

  const setTravelTypeFieldValue = (id) => {
    // formik?.setFieldValue("cash_markup_type", value?.id || null)
    if (formik.values?.travel_markup_value) {
      handleChangeTravelForex(formik.values?.travel_markup_value, id);
    }
  };

  const handleChangeCashForex = (e, id) => {
    if (e.target) {
      formik.setFieldValue("cash_markup_value", e.target.value);
    } else {
      formik.setFieldValue("cash_markup_value", e);
    }

    const percentage = Number(
      e?.target?.value ?? formik.values?.cash_markup_value
    );
    const cashRate = Number(formik.values?.cash_rate) || 0;
    const cashMarkup = Math.abs(percentage) || 0;
    let rate = 0;

    if (id === 1) {
      const formattedRate = percentage.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else if (id === 2) {
      if (percentage > 0) {
        rate = cashRate + cashRate * (cashMarkup / 100);
      } else if (percentage < 0) {
        rate = cashRate - cashRate * (cashMarkup / 100);
      } else {
        rate = cashRate;
      }
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else {
      rate = cashRate + percentage;
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    }
  };

  const handleChangeTravelForex = (e, id) => {
    if (e.target) {
      formik.setFieldValue("travel_markup_value", e.target.value);
    } else {
      formik.setFieldValue("travel_markup_value", e);
    }

    const percentage = Number(
      e?.target?.value ?? formik.values?.travel_markup_value
    );
    const TravelRate = Number(formik.values?.travel_card) || 0;
    const TravelMarkup = Math.abs(percentage) || 0;
    let rate = 0;
    if (id === 1) {
      const formattedRate = percentage.toFixed(4);
      formik.setFieldValue("travel_forex_rate", formattedRate);
    } else if (id === 2) {
      if (percentage > 0) {
        rate = TravelRate + TravelRate * (TravelMarkup / 100);
      } else if (percentage < 0) {
        rate = TravelRate - TravelRate * (TravelMarkup / 100);
      } else {
        rate = TravelRate;
      }
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("travel_forex_rate", formattedRate);
    } else {
      rate = TravelRate + percentage;
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("travel_forex_rate", formattedRate);
    }
  };

  return {
    handleCloseModal,
    formik,
    handleChangeCashForex,
    handleChangeTravelForex,
    formData,
    setTypeFieldValue,
    setTravelTypeFieldValue,
  };
};

export default useBuy;
