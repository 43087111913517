import React from 'react'
import { SearchFilters, Permissions, Button } from '@wac-ui-dashboard/wac_component_library'
import useBranchCurrency from './useBranchCurrency'
import Style from '../branches.module.scss';
const BranchCurrency = () => {
	const {
		menuState,
		mainData,
		isLoading,
		allCurrency,
		handleSearch,
		handleSelectall,
		isChecked,
		handleCheckboxChange,
		handleSave,
		getCoutryIconFromName,
		setIsChecked
	} = useBranchCurrency();




	return (
		<>
			<div className={`col-12 pro-pt-5`}>
						<div className='row pro-pb-5 pro-items-center'>
							<div className='col-auto'>
								<h5 className='pro-ttl pro-mb-0'>Currency</h5>
							</div>
							<div className='col'>
								<SearchFilters
									data={allCurrency?.data?.data}
									onSearchInput={handleSearch}
									showActions={false}

									loading={isLoading}
									SearchIcon={
										<span className="material-symbols-outlined"> search </span>
									}
									searchInputProps={{ value: menuState?.searchCurrency }}
								/>
							</div>
							<div className='col-auto'>
								<div className='pro-check-box'>
									<input
										type="checkbox"
										checked={isChecked}
										onChange={handleSelectall}
										className='pro-check'
										id='all'
									/>
									<label htmlFor='all' className='pro-check-label'>Select All</label>
								</div>
							</div>
						</div>
				{allCurrency?.data?.data?.length !== 0 && (
					<div className='row'>
						<div className='col'>

							<form>
								<div className='row row-cols-4 g-5'>
									{allCurrency?.data?.data?.map((item, index) => (
										<div key={index}>
											<div className='pro-check-box pro-items-center'>
												<input
													type="checkbox"
													value={item}
													checked={mainData?.map(currency => currency?.name).includes(item.currency_code)}
													onChange={(e) => handleCheckboxChange(e, item)}
													className='pro-check'
													id={index}
												/>
												<label className={`pro-d-flex pro-items-center pro-ps-4 ${Style.form_check_label}`} htmlFor={index}>
													<img src={getCoutryIconFromName(item?.currency_code)} alt="" />
													<span className='pro-ps-1'>{item?.currency_code}</span>
												</label>
											</div>
										</div>
									))}
								</div>
							</form>

						</div>
					</div>
				)}
				<div className={`col-12 pro-d-flex pro-justify-end pro-pt-4`} >
					<Button
						className={`pro-btn-primary lg pro-px-5 ${false ? "loading" : ""}`}
						type="submit"
						onClick={handleSave}
					>
						Save
					</Button>
				</div>
			</div>

		</>
	)
}

export default BranchCurrency
