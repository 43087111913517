import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createNewDaily, updateConfig, updateDaily } from "../../../../store/slices/dailyMeditation/dailyMeditationSlice";

const useAddAddons = ({refetch, setShowform}) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.dailyMeditation
  );

  // const [thumbnail, setThumbnail] = useState(selectedItemsDetails ? selectedItemsDetails?.file_path : null);

  const initialData = {
    title: is_edit ? selectedItemsDetails?.title : "",
    description: is_edit
      ? selectedItemsDetails?.description
      : "",
    order: is_edit ? selectedItemsDetails?.order : "",
    
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      title: Yup.string().required("*Required"),
      description: Yup.string().required("*Required"),
      order: Yup.number()
          .typeError('Order must be a number')
          .min(1, 'Minimum value is 1')
          .required("*Required"),
      file: is_edit
      ? Yup.mixed()
      : Yup.mixed().required("*Required"),
    }),

    onSubmit: (values) => {
      let formData = new FormData();
      // formData.append("program_id", programId);
      formData.append("title", values["title"]);
      formData.append(
        "description",
        values["description"]
      );
      formData.append("order", values["order"]);
      formData.append('file', values?.file ?? '');


      if (is_edit) {
        formData.append("id", selectedId);
        dispatch(updateDaily(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.data?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewDaily(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.data?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }

    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  const dataURLtoBlob = async (dataURL) => {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const handleVideosChange = async (e) => {
    
    const files = e?.target?.files[0];
    const allowedFormats = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB limit
    if (!allowedFormats.includes(files.type)) {
      formik.setFieldError("file", "invalid file type");
      return;
      // errors.push(`File ${file.name} is not a valid video format`);
    } else if (files.size > maxSizeInBytes) {
      // errors.push(`File ${file.name} exceeds the 10 MB limit`);
      formik.setFieldError("file", "invalid file size");
      return;
    }

    if(allowedFormats.includes(files.type)) {
      formik.setFieldValue("file", e?.target?.files?.[0]);
    }
    const thumbnailFile = await generateThumbnail(files);

    const reader = new FileReader();

    // reader.onloadend = () => {
    //   setThumbnail(reader.result);
    // };

    if (thumbnailFile) {
      reader.readAsDataURL(thumbnailFile);
    }

    // Function to generate thumbnail using async/await
    async function generateThumbnail(file) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.onloadeddata = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          video.currentTime = 2; // Set the time to 1 second (adjust if needed)
          video.play().then(async () => {
            video.pause();
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // Here, you can use imlet gData or perform further actions with the second frame
            let blob = await dataURLtoBlob(canvas.toDataURL());
            const thumbnailFile = new File([blob], `thumbnail.jpg`, {
              type: "image/jpeg",
            });
            resolve(thumbnailFile);
          });
        };
        video.src = URL.createObjectURL(file);
        video.load();
      });
    }
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
    handleVideosChange
  };
};

export default useAddAddons;
