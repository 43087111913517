import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FaCheck, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import Style from "./studentGoals.module.scss";
import { useGetStudentGoalsDataQuery } from "../../../../../store/queries/SellerModule/studentGoals";

const useStudentGoals = () => {
  const dispatch = useDispatch();
  const { studentID } = useParams();
  const navigate = useNavigate();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const goalState = useSelector((state) => state.studentGoals);
  const {
    data: goalList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetStudentGoalsDataQuery({
    customer_id: activeProfile,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
        navigate("/");
    }
  }, [isError]);

  const [daysToShow, setDaysToShow] = useState([]);

  useEffect(() => {
    if (goalList && goalList.data) {
      const processedData = goalList.data.map((item) => {
        if (item.customer_daily_goal) {
          let startDate = moment(item.customer_daily_goal.content_started_at);
          const watchedDates = item.customer_daily_goal.customer_daily_goal_logs.map(log => moment(log.watched_at));
          const days = [];

          if (item.customer_daily_goal.is_goal_restarted && item.customer_daily_goal.restart_date) {
            const restartDate = moment(item.customer_daily_goal.restart_date);
            const endDate = restartDate.clone().add(21, 'days');
            for (let date = startDate; date.isBefore(endDate); date.add(1, 'day')) {
              const isWatched = watchedDates.some(watchedDate => watchedDate.isSame(date, 'day'));
              days.push({ date: date.clone(), isWatched });
            }
          } else {
            for (let i = 0; i < 21; i++) {
              const date = startDate.clone().add(i, 'days');
              const isWatched = watchedDates.some(watchedDate => watchedDate.isSame(date, 'day'));
              days.push({ date, isWatched });
            }
          }

          return { ...item, days };
        }
        return item;
      });

      setDaysToShow(processedData);
    }
  }, [goalList]);

  const renderDays = (days) => {
    return days.map((day, index) => {
      const isFutureDate = day.date.isAfter(moment(), 'day');
      return (
        <div key={index} className={isFutureDate ? Style.dayContainerDisabled : Style.dayContainer}>
          <span className={Style.date}>{day.date.format('YYYY-MM-DD')}</span>
          {!isFutureDate && (day.isWatched ? <FaCheck color="green" /> : <FaTimes color="red" />)}
        </div>
      );
    });
  };

  return {
    isLoading,
    isFetching,
    goalList,
    goalState,
    refetch,
    daysToShow,
    renderDays
  };
};

export default useStudentGoals;
