import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global/index.js";
import { getFormatedDate } from "../../../../utils/functions/table.js";
import { addDays } from "date-fns";
import {
  updateConfig,
  deleteData,
  getEditDataByID,
} from "../../../../store/slices/SellerModule/MyStudents/myStudentsSlice.js";
import {
  useGetListDataQuery,
} from "../../../../store/queries/SellerModule/myStudents/index.js";
import { toast } from "react-toastify";
import Style from "./myStudents.module.scss";
import { useNavigate } from "react-router-dom";
import { useUpdateTableFieldsDataMutation } from "../../../../store/queries/global/index.js";

const useMyStudents = ({dashboard}) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.myStudents);
  const { showCreateModal, showOtpModal, selctedId } = useSelector(
    (state) => state.myStudents
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const navigate = useNavigate();

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [filterShow, setFilterShow] = useState(false);

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    payment_method: menuState.payment_method,
    purchase_from: menuState.purchase_from,
    purchase_to: menuState.purchase_to 

  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const hasCreatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permissions?.[0]?.can_create ? 1 : 0;
  }, [mainData]);

  const hasUpdatePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permissions?.[0]?.can_edit ? 1 : 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permissions = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permissions?.[0]?.can_delete ? 1 : 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(getEditDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (field, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
              onClick={() => {
                navigate({
                  pathname: `/student-details/${data?.id}`,
                });

                sessionStorage.setItem("active", `${data?.id}`);
              }}
            >
              <span className="pro-ms-1 pro-align-self-center pro-pnt">
                {data[field]}
              </span>
            </div>
          </>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const closeOtpModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showOtpModal = false;
        state.enteredEmail = "";
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/student-activity/my-student");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };


  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    showImageModal,
    selectedOption,
    tableRef,
    showDeleteConfirm,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    imageData,
    showOtpModal,
    closeOtpModal,
    handleEditAction,
    closeImageModal,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleDelete,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleDashboardRedirect,
    handleEditColumnsClick,
    setFilterShow,
    filterShow,
    
  };
};

export default useMyStudents;
