import { useDispatch, useSelector } from "react-redux";
import { useGetBranchRateDataQuery } from "../../../../store/queries/branches";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { updateConfig } from "../../../../store/slices/Branches/branchSlice";
import { getRateDetails } from "../../../../store/slices/Branches/branchRateFormSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";

const useBranchRate = () => {
  const dispatch = useDispatch();
  const { branch_id } = useParams();
  const activeProfile = sessionStorage.getItem("active");

  const menuState = useSelector((state) => state.branch);
  const { showCreateModal } = useSelector((state) => state.branch);
  const { showEditModal } = useSelector((state) => state.global);

  const {
    isFetching,
    isLoading,
    data: mainData = {},
    refetch,
  } = useGetBranchRateDataQuery({
    branch_id: branch_id ?? activeProfile,
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.rateSearch,
    page_size: menuState.currentRatePageSize,
    page: menuState.currentRatePage,
  });

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleEditAction = (data) => {
    dispatch(getRateDetails(data?.[0]));

    dispatch(
      updateConfig((state) => {
        // state.selectedItemsDetails = tempResponseData;
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentRatePage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentRatePageSize = page_size;
        state.currentRatePage = 1;
      })
    );
  };
  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.rateSearch = e.target.value;
      })
    );
  };

  return {
    mainData,
    showCreateModal,
    hasEditPermission,
    menuState,
    isFetching,
    currentPage: menuState?.currentRatePage,
    paginationOptions,
    showEditModal,
    isLoading,
    handleSearch,
    closeEditModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleEditAction,
    getRow,
    closeModal,
  };
};

export default useBranchRate;
