import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
     currentPage: 1,
     currentRatePage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     currentRatePageSize: 10,
     startDate: null,
     endDate: null,
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     rateSearch:"",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     searchCurrency:""
}

export const createNewBranch = createAsyncThunk(
     "/admin/branch/create",
     async (data, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post("/admin/branch/create", data)
               return response.data
          } catch (error) {
               return rejectWithValue(error.response.data)
          }
     }
)

export const updateBranch = createAsyncThunk(
     "/admin/branch/update",
     async ({ data }, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post(`admin/branch/update`, data)
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

export const getAllCurrency = createAsyncThunk(
     "/admin/branch/currency/all",
     async (rejectWithValue) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.get(`admin/branch/currency/all`);
         return response;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );

   export const getBranchCurrency = createAsyncThunk(
     "/admin/branch/currency/view",
     async (id, rejectWithValue) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.get(`admin/branch/currency/view?branch_id=${id}`);
         return response;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );


export const deletePincode = createAsyncThunk(
     "/admin/branch/pincode/delete",
     async (body, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post(`/admin/branch/pincode/delete`, body)
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

export const editBranchCurrencyData = createAsyncThunk(
     '/admin/branch/currency/edit',
     async (body, { rejectWithValue }) => {
          const api = await getAxiosInstance();
          try {
               const response = await api.post(`/admin/branch/currency/edit`, body)
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data);
          }
     }
)

export const currencySave = createAsyncThunk(
     "/admin/branch/currency/create",
     async (params, { rejectWithValue }) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.post(`/admin/branch/currency/create`,params);
         return response.data;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );




const branchSlice = createSlice({
     name: "branch",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     }
})

export const { updateConfig } = branchSlice.actions;

export default branchSlice.reducer;