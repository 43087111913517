import { Auth, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useAuth from "./useAuth";
import { useState, useRef } from "react";

const Login = () => {
  const { emailRef } = useOutletContext();
  const {
    formik,
    handleForgotPassword,
    loginStatus,
    forgotPasswordStatus,
  } = useAuth(emailRef);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const passwordInputRef = useRef(null);  // Reference to the password input field

  const handlePasswordVisibility = (e) => {
    e.preventDefault();  // Prevent the default form submission behavior
    setIsPasswordVisible((prevState) => !prevState);
    
    // Temporarily change the input type without resetting the value
    if (passwordInputRef.current) {
      const input = passwordInputRef.current;
      const currentValue = input.value;
      input.type = isPasswordVisible ? "password" : "text";
      input.value = currentValue;
    }
  };

  return (
    <>
      <Auth
        title={"Login"}
        buttonText={"Login"}
        handleClick={formik.handleSubmit}
        actionText="Forgot Password?"
        handleActionClick={handleForgotPassword}
        loading={loginStatus === "pending"}
        actionLoading={forgotPasswordStatus === "pending"}
        type="button"
      >
        <div className="input-wrap pro-mb-5">
          <label htmlFor="email" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Email
          </label>
          <input
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            className={`pro-input lg ${
              formik.errors.email &&
              (formik.touched.email || formik.values.email) &&
              "error"
            } ${
              loginStatus === "pending" || forgotPasswordStatus === "pending"
                ? "disabled"
                : ""
            } `}
          />
          {formik.errors.email &&
            (formik.touched.email || formik.values.email) && (
              <span className="error-text">{formik?.errors?.email}</span>
            )}
        </div>

        <div className="input-wrap pro-mb-5 p-re">
          <label htmlFor="password" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Password
          </label>
          <div className="password-wrapper pro-w-100">
            <input
              name="password"
              id="password"
              ref={passwordInputRef}  // Attach the ref to the password input
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={isPasswordVisible ? "text" : "password"}
              className={`pro-input lg pro-w-100 ${
                formik.errors.password && formik.touched.password && "error"
              } ${
                loginStatus === "pending" || forgotPasswordStatus === "pending"
                  ? "disabled"
                  : ""
              } hide-icon-input`}
            />
            <button
              className={`hide-icon`}
              onClick={handlePasswordVisibility}
              type="button"
              tabIndex="-1"
            >
              <span className="material-symbols-outlined">
                {isPasswordVisible ? "visibility_off" : "visibility"}
              </span>
            </button>
          </div>
          {formik.errors.password && formik.touched.password && (
            <span className="error-text">{formik?.errors?.password}</span>
          )}
        </div>
      </Auth>
    </>
  );
};

export default Login;
