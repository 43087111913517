import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const { 
    allowedDashboardComponent,
    allowedConfigureComponents,
    allowedPackageComponent,
    allowedBonusComponents,
    allowedProgramComponents,
    allowedCustomerComponent,
    allowedReviewComponents,
    allowedAgentComponents,
    allowedAnnouncementComponents,
    allowedPayoutsComponents,
    allowedTrainingComponents,
    allowedStudentsActivityComponents,
    allowedCouponComponents,
    allowedOrderComponents
  } = useRoutes();


  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedPackageComponent?.length > 0) {
        navigate(
          `/${allowedPackageComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCustomerComponent?.length > 0) {
        navigate(
          `/${allowedCustomerComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedProgramComponents?.length > 0) {
        navigate(
          `/${allowedProgramComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedBonusComponents?.length > 0) {
        navigate(
          `/${allowedBonusComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedReviewComponents?.length > 0) {
        navigate(
          `/${allowedReviewComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedOrderComponents?.length > 0) {
        navigate(
          `/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedPackageComponent?.length > 0) {
        navigate(
          `/${allowedPackageComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedConfigureComponents?.length > 0) {
        navigate(
          `/configure/${allowedConfigureComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedAgentComponents?.length > 0) {
        navigate(
          `/${allowedAgentComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedAnnouncementComponents?.length > 0) {
        navigate(
          `/${allowedAnnouncementComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCouponComponents?.length > 0) {
        navigate(
          `/${allowedCouponComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedPayoutsComponents?.length > 0) {
        navigate(
          `/${allowedPayoutsComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedTrainingComponents?.length > 0) {
        navigate(
          `/${allowedTrainingComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (
        allowedStudentsActivityComponents?.length > 0) {
        navigate(
          `/student-activity/${allowedStudentsActivityComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      //  else {
      //   navigate(
      //     `/settings/${
      //       allowedSettingsComponent?.map((item) => item?.path)?.[0]
      //     }`
      //   );
      // }
    }
    // eslint-disable-next-line
  }, [allowedDashboardComponent , allowedConfigureComponents]);

  

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
