import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils.js";
import {
  // getEditStudentData,
  // getEducationLevelData,
  updateConfig,
} from "../../../../store/slices/packages/packageSlice.js";
import { useGetPackageBasicDataQuery } from "../../../../store/queries/packages/index.js";

const usePackageDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const ProfileState = useSelector((state) => state.package);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});
  // const [updateStudentStatus] = useUpdateStudentStatusMutation();

  const navigation = [
    {
      label: "Price",
      title: "Price",
      link: `/packages-details/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/packages-details/${activeProfile}`, true),
    },
    {
      label: "Modules",
      title: "Modules",
      link: `/packages-details/modules/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/modules/${activeProfile}`),
    },
  ];

  const navigate = useNavigate();

  const {
    data: packageData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetPackageBasicDataQuery(activeProfile);

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = packageData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [packageData]);

  useEffect(() => {
    if (packageData?.statusCode === 400 || packageData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [packageData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    gender: "Gender",
    email: "Email",
    education_level: "Education Level",
    location: "Location",
  };

  const value = {
    gender: `${packageData?.data?.student_detail?.gender ?? "Not specified"}`,
    email: `${packageData?.data?.student_detail?.email ?? "Not specified"}`,
    education_level: `${
      packageData?.data?.student_detail?.education_level ?? "Not specified"
    }`,
    location: `${
      packageData?.data?.student_detail?.location ?? "Not specified"
    }`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.isEdit = true;
        state.selectedID = packageData?.data?.student_detail?._id;
      })
    );
    // dispatch(getEducationLevelData());
    // dispatch(getEditStudentData(packageData?.data?.student_detail?._id));
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.isEdit = false;
        state.selectedID = {};
        state.imagePreview = "";
        state.imageName = "";
        state.studentData = [];
        state.educationLevel = [];
      })
    );
  };

  // useEffect(() => {
  //   if (statusUpdate) {
  //     updateStudentStatus(obj?.id).then((res) => {
  //       if (res?.data?.statusCode === 200) {
  //         toast.success(res?.data?.message);
  //         refetch();
  //       } else {
  //         toast.error(res?.data?.message);
  //       }
  //     });
  //     handleDeleteModal();
  //   }
  // }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };
  return {
    activeProfile,
    isFetching,
    printRef,
    closeModal,
    packageData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    popUpMessage,
    handleDeleteModal,
  };
};

export default usePackageDetailsLayout;
