import { getAxiosInstance } from "../../../../api";

export const getGlobalSearchData = async ({searchKey,page}) => {
   
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/global-search?search=${searchKey}&page_size=10&page=${page}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const markNotificationRead = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/v1/notification/change-read-status`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const logoutUser = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/logout`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};


