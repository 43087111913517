import { useEffect, useMemo, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Dashboard/dashboardSlice";
import { updateConfig as updateConfigSeller } from "../../../store/slices/SellerModule/SellerDashboard/sellerDashboardSlice";
import { updateConfig as updateConfigAgents } from "../../../store/slices/Agent/agentSlice";
import { useGetSellerDashboardDataQuery } from "../../../store/queries/SellerModule/sellerDashboard";
import { getFormatedDate } from "../../../utils/functions/table";
import { useLocation } from "react-router-dom";
import { colors } from "@mui/material";

const useDashboardListing = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const dashboardState = useSelector((state) => state.dashboard);
  const sellerDashboardState = useSelector((state) => state.sellerDashboard);
  const dispatch = useDispatch();

  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const [isSelectedPending, setIsSelectedPending] = useState(false);
  const [isSelectedRejected, setIsSelectedRejected] = useState(false);
  const [isSelectedApproved, setIsSelectedApproved] = useState(false);

  const activeUser = useMemo(
    () => JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found",
    []
  );
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({
    start_date: getFormatedDate(dashboardState?.startDate),
    end_date: getFormatedDate(dashboardState?.endDate),
    currency: dashboardState?.currency,
    country_code: dashboardState?.country_code,
  },
  {
    skip: activeUser?.id !== 1,
  });

  const {
    data: sellerDashboardData = {},
    isFetching: sellerFetching,
    isSuccess: sellerSuccess,
  } = useGetSellerDashboardDataQuery({
    start_date: getFormatedDate(sellerDashboardState?.startDate),
    end_date: getFormatedDate(sellerDashboardState?.endDate),
    country_code: sellerDashboardState?.country_code,
  },
  {
    skip: activeUser?.id === 1,
  }
);

  const [listingType, setListingType] = useState();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = activeTab;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (activeUser?.id == 1) {
      if (isSuccess && activeTab == "students") {
        setListingType(
          Object.keys(dashboardData?.data?.student || {})?.[0] ?? ""
        );
        handleTotalRevenue();
      } else {
        setListingType(
          Object.keys(dashboardData?.data?.agent || {})?.[0] ?? ""
        );
      }
    } else {
      if (sellerSuccess) {
        setListingType(Object.keys(sellerDashboardData?.data || {})?.[0] ?? "");
      }
      handleTotalRevenue();
    }

    //eslint-disable-next-line
  }, [isSuccess, sellerSuccess]);

  const handleTotalOrders = () => {
    setListingType("total_profit");
  };

  const handleTotalRevenue = () => {
    setListingType("total_sales");
  };
  const handleEnroll = () => {
    setListingType("student_count");
  };
  const handleTotalPrograms = () => {
    setListingType("total_orders_count");
  };
  const handleTotalAgents = () => {
    setListingType("total_agents");
  };

  const handleMonthlySale = () => {
    setListingType("agent_sales");
  };

  const handleCommission = () => {
    setListingType("total_commition");
  };

  const handleCourseSold = () => {
    setListingType("total_sales");
  };

  const handleActiveStudents = () => {
    setListingType("total_student_count");
  };

  const handleStudentRegistered = () => {
    setListingType("student_count");
  };

  const handleTotalAgentSale = () => {
    setListingType("agent_total_sales_amount");
  };

  const handleTotalProgramSold = () => {
    setListingType("agent_orders_total_count");
  };

  const handleActiveAgents = () => {
    setListingType("agent_count");
  };

  const handleTotalCommission = () => {
    setListingType("agent_total_commission");
  };

  let object = {
    total_sales: handleTotalRevenue,
    student_count: handleEnroll,
    total_profit: handleTotalOrders,
    total_orders_count: handleTotalPrograms,
    total_agents: handleTotalAgents,
    agent_count: handleActiveAgents,
    agent_orders_total_count: handleTotalProgramSold,
    agent_total_sales_amount: handleTotalAgentSale,
    agent_total_commission: handleTotalCommission,
    student_counts: handleStudentRegistered,
    agent_sales: handleMonthlySale,
    total_commition: handleCommission,
    total_sales: handleCourseSold,
    total_student_count: handleActiveStudents,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    total_sales: {
      label: "total_sales",
      title: "Total Revenue",
      icon: "person_raised_hand",
      handleClick,
    },
    student_count: {
      label: "student_count",
      title: "Enroll/Register",
      icon: "person",
      handleClick,
    },
    total_profit: {
      label: "total_profit",
      title: "Total Orders",
      icon: "person",
      handleClick,
    },
    total_orders_count: {
      label: "total_orders_count",
      title: "Total Programs",
      icon: "person",
      handleClick,
    },
    total_agents: {
      label: "total_agents",
      title: "No. of Agents",
      icon: "person",
      handleClick,
    },

    agent_count: {
      label: "agent_count",
      title: "Active Agents",
      icon: "person",
      handleClick,
    },
    agent_orders_total_count: {
      label: "agent_orders_total_count",
      title: "Total Programs Sold",
      icon: "person",
      handleClick,
    },
    agent_total_sales_amount: {
      label: "agent_total_sales_amount",
      title: "Total Agent Sales",
      icon: "person",
      handleClick,
    },
    agent_total_commission: {
      label: "agent_total_commission",
      title: "Total Commissions",
      icon: "person",
      handleClick,
    },
    student_counts: {
      label: "student_count",
      title: "Student Registered",
      icon: "person",
      handleClick,
    },
  };

  let sellerDashboardItems = {
    agent_sales: {
      label: "agent_sales",
      title: "Monthly Sales",
      icon: "person_raised_hand",
      handleClick,
    },
    total_commition: {
      label: "total_commition",
      title: "Monthly Commission",
      icon: "person",
      handleClick,
    },
    total_sales: {
      label: "total_sales",
      title: "Courses Sold",
      icon: "person",
      handleClick,
    },
    total_student_count: {
      label: "total_student_count",
      title: "Active Students",
      icon: "person",
      handleClick,
    },
  };

  const sellerBarData = {
    labels: sellerDashboardData?.data?.package_sales?.labels,
    datasets: [
      {
        label: "",
        data: sellerDashboardData?.data?.package_sales?.datasets?.length !== 0 ? sellerDashboardData?.data?.package_sales?.datasets : [],
        backgroundColor:
          sellerDashboardData?.data?.package_sales?.backgroundColor,
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const sellerBarOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
        grid: {
          display: false, // Hide x-axis grid lines
        },
        // Adjust bar thickness
        stacked: true, // Ensure bars stack on top of each other
        barThickness: 5, // Set the bar thickness
        minBarLength: 15, // Minimum length of bars to avoid disappearing
      },
      y: {
        display: true, // Hide y-axis
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          display: true, // Hide y-axis ticks
        },
      },
    },
  };

  const barData = {
    labels: dashboardData?.data?.student?.package_sold_list?.labels,
    datasets: [
      {
        label: "",
        data: dashboardData?.data?.student?.package_sold_list?.datasets?.length !== 0 ? dashboardData?.data?.student?.package_sold_list?.datasets?.map(
          (item) => item
        ) : [],
        backgroundColor:
          dashboardData?.data?.student?.package_sold_list?.backgroundColor,
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const barFunnelData = {
    labels: dashboardData?.data?.agent?.agent_package_sold_list?.labels,
    datasets: [
      {
        label: "",
        data: dashboardData?.data?.agent?.agent_package_sold_list?.datasets?.length !== 0 ? dashboardData?.data?.agent?.agent_package_sold_list?.datasets?.map(
          (item) => item
        ) : [],
        backgroundColor:
          dashboardData?.data?.agent?.agent_package_sold_list?.backgroundColor,
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const barOrderData = {
    labels: dashboardData?.data?.student?.module_sold_list?.labels,
    datasets: [
      {
        label: "",
        data: dashboardData?.data?.student?.module_sold_list?.datasets?.length !== 0 ? dashboardData?.data?.student?.module_sold_list?.datasets?.map(
          (item) => item
        ) : [],
        backgroundColor:
          dashboardData?.data?.student?.module_sold_list?.backgroundColor,
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const barModuleData = {
    labels: dashboardData?.data?.agent?.agent_module_sold_list?.labels,
    datasets: [
      {
        label: "",
        data: dashboardData?.data?.agent?.agent_module_sold_list?.datasets?.length !== 0 ? dashboardData?.data?.agent?.agent_module_sold_list?.datasets?.map(
          (item) => item
        ) : [],
        backgroundColor:
          dashboardData?.data?.agent?.agent_module_sold_list?.backgroundColor,
        borderWidth: 0,
        barThickness: 22,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
        grid: {
          display: false, // Hide x-axis grid lines
        },
        // Adjust bar thickness
        stacked: true, // Ensure bars stack on top of each other
        barThickness: 5, // Set the bar thickness
        minBarLength: 15, // Minimum length of bars to avoid disappearing
      },
      y: {
        display: true, // Hide y-axis
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          display: true, // Hide y-axis ticks
        },
      },
    },
  };

  const doughnutData = useMemo(() => {
    return {
      labels: dashboardData?.data?.student?.order_status_count?.labels?.map(
        (item) => `${item?.charAt(0).toUpperCase()}${item?.slice(1)}`
      ),
      datasets: [
        {
          label: "",
          data: dashboardData?.data?.student?.order_status_count?.datasets?.length !== 0 ? dashboardData?.data?.student?.order_status_count?.datasets?.map(
            (item) => item
          ) : [],
          pointRadius: 1,
          backgroundColor:
            dashboardData?.data?.student?.order_status_count?.backgroundColor,
        },
      ],
    };
    //eslint-disable-next-line
  }, [dashboardData?.data?.student]);

  const sellerDoughnutData = useMemo(() => {
    return {
      labels: sellerDashboardData?.data?.module_sales?.labels?.map(
        (item) => `${item?.charAt(0).toUpperCase()}${item?.slice(1)}`
      ),
      datasets: [
        {
          label: "",
          data: sellerDashboardData?.data?.module_sales?.datasets?.length !== 0 ? sellerDashboardData?.data?.module_sales?.datasets?.map(
            (item) => item
          ) : [],
          pointRadius: 1,
          backgroundColor:
            sellerDashboardData?.data?.module_sales?.backgroundColor,
        },
      ],
    };
    //eslint-disable-next-line
  }, [sellerDashboardData?.data?.module_sales]);

  const doughnutOptions = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 12,
            weight:'medium',
            color:'black',
            
          },
        },
      },
      afterDraw: () => {
        "";
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const lineData = useMemo(() => {
    return {
      labels: sellerDashboardData?.data?.monthly_sales?.labels,
      // datasets:
      //   sellerDashboardData?.data?.monthly_sales?.datasets?.map(
      //     (item, index) => ({
      //       label: "",
      //       data: item,
      //       // backgroundColor:
      //       //   sellerDashboardData?.data?.monthly_sales?.backgroundColor,
      //       fill: false,
      //       // pointRadius: 10,
      //       // pointHoverRadius: 15,
      //       pointBackgroundColor: "transparent",
      //       pointBorderColor: "transparent",
      //     })
      //   ) || [],
      datasets: [
        {
          label: "",
          data: sellerDashboardData?.data?.monthly_sales?.datasets?.length !== 0 ? sellerDashboardData?.data?.monthly_sales?.datasets?.map(
            (item) => item
          ) : [],
          pointRadius: 1,
          fill: true,
          backgroundColor:
          sellerDashboardData?.data?.monthly_sales?.backgroundColor,
        },
      ],
    };
  }, [sellerDashboardData?.data?.monthly_sales]);

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const handleCurrencyChange = (value) => {
    dispatch(updateConfig((state) => (state.currency = value?.value)));
  };

  const handleCountryChange = (value) => {
    dispatch(updateConfig((state) => (state.country_code = value?.value)));
  };

  const [date, setDate] = useState({
    startDate:
      activeUser.id === 1
        ? dashboardState.startDate
        : sellerDashboardState.startDate,
    endDate:
      activeUser.id === 1
        ? dashboardState.endDate
        : sellerDashboardState.endDate,
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    if (activeUser?.id == 1) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.startDate = startDate;
          state.endDate = endDate;
        })
      );
    } else {
      dispatch(
        updateConfigSeller((state) => {
          state.currentPage = 1;
          state.startDate = startDate;
          state.endDate = endDate;
        })
      );
    }
  };

  const navigation = [
    {
      label: "Students",
      title: "Students",
      link: `/dashboard?activeTab=students`,
      active: activeTab === "students",
    },
    {
      label: "Agents",
      title: "Agents",
      link: `/dashboard?activeTab=agents`,
      active: activeTab === "agents",
    },
  ];

  const setActiveTab = (tab) => {
    if (tab == "All") {
      setIsSelectedAll(true);
      setIsSelectedPending(false);
      setIsSelectedRejected(false);
      setIsSelectedApproved(false);
      dispatch(
        updateConfigAgents((state) => {
          state.currentPage = 1;
          state.verification_status = "";
        })
      );
    } else if (tab == "Pending") {
      setIsSelectedAll(false);
      setIsSelectedPending(true);
      setIsSelectedRejected(false);
      setIsSelectedApproved(false);
      dispatch(
        updateConfigAgents((state) => {
          state.currentPage = 1;
          state.verification_status = 0;
        })
      );
    } else if (tab == "Rejected") {
      setIsSelectedAll(false);
      setIsSelectedPending(false);
      setIsSelectedRejected(true);
      setIsSelectedApproved(false);
      dispatch(
        updateConfigAgents((state) => {
          state.currentPage = 1;
          state.verification_status = 2;
        })
      );
    } else {
      setIsSelectedAll(false);
      setIsSelectedPending(false);
      setIsSelectedRejected(false);
      setIsSelectedApproved(true);
      dispatch(
        updateConfigAgents((state) => {
          state.currentPage = 1;
          state.verification_status = 1;
        })
      );
    }
  };

  return {
    dashboardItems,
    sellerDashboardItems,
    sellerDashboardData,
    dashboardData,
    isFetching,
    sellerFetching,
    children:
      activeUser?.id === 1
        ? dashboardItems[listingType]?.component
        : sellerDashboardItems[listingType]?.component,
    listingType,
    doughnutData,
    doughnutOptions,
    barData,
    barOptions,
    dashboardState,
    handleCurrencyChange,
    handleCountryChange,
    handleDateRangeChange,
    date,
    activeUser,
    sellerBarData,
    sellerBarOptions,
    sellerDoughnutData,
    tabNavs: navigation,
    activeTab,
    barOrderData,
    barFunnelData,
    barModuleData,

    setActiveTab,
    isSelectedAll,
    isSelectedPending,
    isSelectedRejected,
    isSelectedApproved,
    lineData,
    options,
  };
};

export default useDashboardListing;
