import { Button, ConfirmationBox, Image, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../../assets/Assets";
import Style from "../manageProfileForm.module.scss";
import useProfile from "./useProfile";
import { Link } from "react-router-dom";

const Profile = ({ refetch, closeModal }) => {
  const {
    formik,
    imagePreview,
    selectedItemsDetails,
    handleProfileImage,
    activeUser,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    showDeleteConfirm
  } = useProfile({
    closeModal,
  });
  return (
    <div className={Style.form_wrap}>
      <div className=" pro-d-flex">
        <div className={`${Style.profile} ${Style.error}`}>
          <Image src={imagePreview ?? Assets.GENDER_IMAGE} />
          <div className={`${Style.profile_upload} pro-ps-4`}>
            <span className={Style.profile_upload_name}>
              {formik?.values?.first_name}
            </span>
            <label
              className={`${Style.profile_upload_btn} pro-btn`}
              htmlFor="profile_upload"
            >
              Upload Photo
            </label>
            <input
              className={Style.profile_upload_input}
              type="file"
              id="profile_upload"
              accept=".jpeg, .jpg , .png"
              onChange={(e) => handleProfileImage(e)}
            />
            <div className={Style.error_msg}>
              {formik?.errors?.profile_image && formik?.errors?.profile_image}
            </div>
          </div>
        </div>
      </div>
      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          First Name *
        </label>
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.first_name && formik.touched.first_name && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.first_name}
        />
        {formik.touched.first_name && formik.errors.first_name && (
          <div className="error-text">{formik.errors.first_name}</div>
        )}
      </div>
      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Last Name *
        </label>
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.last_name && formik.touched.last_name && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.last_name}
        />
        {formik.touched.last_name && formik.errors.last_name && (
          <div className="error-text">{formik.errors.last_name}</div>
        )}
      </div>

      <div className={`pro-mb-4 ${Style.form_input} input-wrap `}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Email *
        </label>
        <input
          type="textarea"
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.email && formik.touched.email && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.email}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="error-text">{formik.errors.email}</div>
        )}
      </div>

      {/* <div className="">
        <UploadPhoto
          formik={formik}
          documents={documents}
          handleChange={handleImageChange}
          title={"Documents"}
          handleDeleteClick={handleImageRemove}
          mutiple
          accept={".png,.jpeg,.jpg,.pdf"}
          addText={"Add Documents"}
          isError={getFieldError("documents") ? true : false}
          errorMsg={getFieldError("documents")}
        />
      </div> */}

      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          About me *
        </label>
        <textarea
          type="text"
          name="bio"
          placeholder="Write here ..."
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.bio && formik.touched.bio && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.bio}
        />
        {formik.touched.bio && formik.errors.bio && (
          <div className="error-text">{formik.errors.bio}</div>
        )}
      </div>
      {activeUser !== 1 && (
        <div className={`pro-my-2 ${Style.delete_text}`}>
          <button className="pro-btn-link" onClick={() => handleDelete()}>
            Delete your account ?
          </button>
        </div>
      )}

      <div className={` pro-d-flex  `}>
        <Button
          className={"pro-btn-primary lg pro-me-3"}
          onClick={formik.handleSubmit}
          type="button"
        >
          {"Update"}
        </Button>
      </div>
      <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
    </div>
  );
};

export default Profile;
