import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

export const getEditDataByID = createAsyncThunk(
  "/admin/products/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/products/edit?product_id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewEntry = createAsyncThunk(
  "/admin/staffs/create",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/staffs/create", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/admin/staffs/update",
  async (data , { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/staffs/update?`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/admin/staffs/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(`/admin/staffs/delete?staff_id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const staffsSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
    builder.addCase(getEditDataByID.pending, (state) => {
       state.isLoading = true;
       state.error = null;
     })
     .addCase(getEditDataByID.fulfilled, (state, action) => {
       state.selectedItemsDetails = action.payload.data.data;
     })
     .addCase(getEditDataByID.rejected, (state, action) => {
       state.isLoading = false;
       state.error = action.payload;
     });
     
   },
  
});

export const { updateConfig } = staffsSlice.actions;

export default staffsSlice.reducer;
