import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  customer_id: "",
  showOtpModal: false,
  enteredEmail: "",
  start_date: "",
    end_date: "",
    purchase_from: "",
    purchase_to: "",
    payment_method: ""
};

export const getEditDataByID = createAsyncThunk(
  "/v1/traning-video/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/traning-video/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewEntry = createAsyncThunk(
  "/v1/traning-video/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/traning-video/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateData = createAsyncThunk(
  "/v1/traning-video/update",
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/traning-video/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/traning-video/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/traning-video/delete?id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const myStudentsSlice = createSlice({
  name: "myStudents",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.start_date= action.payload.start_date;
      state.end_date= action.payload.end_date;
      state.payment_method= action.payload.payment_method;
      state.purchase_from = action.payload.purchase_from;
      state.purchase_to = action.payload.purchase_to
    },
    resetExtraFilters: (state, action) => {
      state.start_date= '';
      state.end_date= '';
      state.payment_method= '';
      state.purchase_from = '';
      state.purchase_to = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditDataByID.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEditDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getEditDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } = myStudentsSlice.actions;

export default myStudentsSlice.reducer;
