import React from 'react'
import {
  Input,
  Button
} from '@wac-ui-dashboard/wac_component_library'
import useSell from './useSell'
import { capitalizeOnSpace } from '../../../../../../utils/functions/table'
import Select from "react-select";
const Sell = ({ refetch, closeModal }) => {


  const { handleCloseModal, formik,handleChangeCashForex, formData, setTypeFieldValue } = useSell({ refetch, closeModal });
  return (
    <div className="row">
      <h6 className='pro-ttl pro-mb-4 h6'>Cash Details</h6>
      <div className='row gx-4'>
        <div className='col-6'>
          <Input
          label={"Cash Rate  "}
          type="number"
          id="cash-rate"
          name="cash-rate"
          disabled={true}
          className={`pro-input lg ${formik.errors.cash_rate && formik.touched.cash_rate && "error"
            }`}
          {...formik.getFieldProps("cash_rate")}
         />
        </div>  
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
          Margin Type*
          </label>
          <Select
            id="markup_type"
            name="markup_type"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.errors.markup_type &&
              formik.touched.markup_type &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={formData?.data?.margin_type}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formData?.data?.margin_type?.filter(
              (m) => formik.values.markup_type === m?.id
            )}
            onChange={(value) =>
				{
              formik?.setFieldValue("markup_type", value?.id || null)
			  setTypeFieldValue(value?.id);
			}
            }
          />
          {formik.touched.markup_type && formik.errors.markup_type && (
            <span className="error-text">{formik.errors.markup_type}</span>
          )}
        </div>
        <div className='col-6'>
          <Input
          label={"Cash Markup"}
          type="number"
          id="markup_value"
          name="markup_value"
          className={`pro-input lg ${formik.errors.markup_value && formik.touched.markup_value && "error"
            }`}
          {...formik.getFieldProps("markup_value")}
          onChange={(e)=>handleChangeCashForex(e, formik?.values?.markup_type)}
          error={formik.errors.markup_value && formik.touched.markup_value}
          errorMessage={formik.errors.markup_value}
          />
        </div>
      </div>

      <Input
        label={"Cash Forex Rate"}
        type="number"
        id="cash-markup"
        name="cash-markup"
        className={`pro-input lg ${formik.errors.cash_forex_rate && formik.touched.cash_forex_rate && "error"
          }`}
        {...formik.getFieldProps("cash_forex_rate")}
        disabled={true}
      />

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="cash_status"
            name="cash_status"
            type="checkbox"
            checked={Boolean(formik.values?.cash_status) ?? false}
            onChange={(e) => { formik.setFieldValue("cash_status", !Boolean(formik.values?.cash_status) ?? 0); }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-pt-4`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="button"
        >
          {"Update"}
        </Button>
      </div>
    </div>
  )
}

export default Sell
