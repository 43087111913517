import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createNewSession,
  updateConfig,
  updateSession,
} from "../../../../store/slices/program/programSessionSlice";
import { toast } from "react-toastify";
import {
  createNewAddon,
  updateAddons,
} from "../../../../store/slices/bonusProgram/bonusProgramSlice";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";

const useAddAddons = ({ refetch, setShowform }) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.bonusProgram
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setInitialData({
        name: selectedItemsDetails?.name,
        description: selectedItemsDetails?.description,
        display_order: selectedItemsDetails?.display_order,
        bonus_program_content:
          selectedItemsDetails?.add_on_contents?.length > 0
            ? selectedItemsDetails?.add_on_contents?.map((item, index) => ({
                content_id: item?.id,
                content_title: item?.content_title,
                content_description: item?.content_description,
                content_type: item?.content_type_value,
                content:
                  item?.content_type_value === 3
                    ? item?.content
                      ? convertHtmlToEditorState(item?.content)
                      : ""
                    : item?.content
                    ? item?.content
                    : "",
                content_text:
                  item?.content_type_value === 1 ||
                  item?.content_type_value === 2
                    ? item?.content_text
                      ? convertHtmlToEditorState(item?.content_text)
                      : ""
                    : item?.content_text
                    ? item?.content_text
                    : "",
              }))
            : [
                {
                  content_title: "",
                  content_description: "",
                  content_type: "",
                  content: "",
                  content_text: "",
                },
              ],
      });
    }
  }, [selectedItemsDetails]);

  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [initialData, setInitialData] = useState({
    name: "",
    description: "",
    display_order: "",
    bonus_program_content: [
      {
        content_title: "",
        content_description: "",
        content_type: "",
        content: "",
        content_text: "",
      },
    ],
  });

  const orderList = Array.from({ length: 20 }, (_, index) => ({
    label: index + 1,
    value: index + 1,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      name: Yup.string().required("*Required"),
      description: Yup.string().required("*Required"),
      display_order: Yup.number().required("*Required"),
      bonus_program_content: Yup.array().of(
        Yup.object().shape({
          content_title: Yup.string().required("*Required"),
          content_description: Yup.string().required("*Required"),
          content_type: Yup.string().required("*Required"),
          // content: Yup.string().required("*Required"),
        })
      ),
    }),

    validate: (values) => {
      let errors = {};
      // Check if content is not undefined and has length
      if (
        values.bonus_program_content &&
        values.bonus_program_content.length > 0
      ) {
        // Initialize bonus_program_content array in errors object
        errors.bonus_program_content = [];

        values.bonus_program_content.forEach((value, index) => {
			
          // Initialize an empty object for holding errors of this item
          let contentErrors = {};

          if (value?.content_type === 3) {
            // Assuming `content` is an EditorState object from Draft.js or similar library
            let plainText = "";
            if (
              typeof value?.content === Yup.string
            ) {
              plainText = value?.content
                ? value?.content?.getCurrentContent()?.getPlainText()
                : "";
            } else {
              plainText = "";
            }
            // Check if the bonus_program_content is empty or only contains whitespace
            if (!value?.content) {
              // Assign the error message to the content field of the current item
              contentErrors.content = "*Required1";
            }
          } else if (value?.content_type === 1 || value?.content_type === 2) {
            if (value?.content === "" || value?.content === undefined) {
              contentErrors.content = "*Required2";
            }
            // if (
            //   value?.content_text === "" ||
            //   value?.content_text === undefined
            // ) {
            //   const plainText = value?.content_text
            //     ? value?.content_text?.getCurrentContent().getPlainText()
            //     : "";

            //   // Check if the bonus_program_content is empty or only contains whitespace
            //   if (!plainText.trim()) {
            //     // Assign the error message to the content_text field of the current item
            //     contentErrors.content_text = "*Required";
            //   }
            // }
          }

          // If there are any errors for the current item, push them to the errors.bonus_program_content array
          if (Object.keys(contentErrors).length > 0) {
            errors.bonus_program_content[index] = contentErrors;
          }
        });
        // If after processing all bonus_program_content items there are no errors, remove the bonus_program_content key from errors
        if (errors.bonus_program_content.length === 0) {
          delete errors.bonus_program_content;
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      let formData = new FormData();
      // formData.append("program_id", programId);
      formData.append("name", values["name"]);
      formData.append("description", values["description"]);
      formData.append("display_order", values["display_order"]);

      values?.bonus_program_content?.forEach((value, index) => {
        if (value?.content_id) {
          formData.append(
            `bonus_program_content[${index}][id]`,
            value?.content_id
          );
        }
        formData.append(
          `bonus_program_content[${index}][content_title]`,
          value?.content_title
        );
        formData.append(
          `bonus_program_content[${index}][content_description]`,
          value?.content_description
        );
        formData.append(
          `bonus_program_content[${index}][content_type]`,
          value?.content_type
        );

        if (value?.content_type === 3) {
          let contentState;

          if (value.content.getCurrentContent) {
            contentState = value.content.getCurrentContent();
          } else {
            // Assuming value.content might directly be a string or another format in some cases
            contentState = value.content; // Or handle this case based on your actual data structure
          }
          const contentRaw = convertToRaw(contentState);
          const plainText = draftToHtml(contentRaw);

          formData.append(
            `bonus_program_content[${index}][content]`,
            plainText
          );
          formData.append(`bonus_program_content[${index}][content_text]`, "");
        } else if (value?.content_type === 1 || value?.content_type === 2) {
          formData.append(
            `bonus_program_content[${index}][content]`,
            value?.content
          );

          let contentState;

          if (value.content_text.getCurrentContent) {
            contentState = value.content_text.getCurrentContent();
          } else {
            // Assuming value.content_text might directly be a string or another format in some cases
            contentState = value.content_text; // Or handle this case based on your actual data structure
          }
          const contentRaw = convertToRaw(contentState);
          const plainText = draftToHtml(contentRaw);
          formData.append(
            `bonus_program_content[${index}][content_text]`,
            plainText
          );
        } else {
          formData.append(`bonus_program_content[${index}][content]`, "");
          formData.append(`bonus_program_content[${index}][content_text]`, "");
        }
      });

      if (is_edit) {
        formData.append("id", selectedId);
        dispatch(updateAddons(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewAddon(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }
    },
  });

  const handleAddField = () => {
    let { bonus_program_content } = formik?.values;
    bonus_program_content = [
      ...bonus_program_content,
      {
        content_title: "",
        content_description: "",
        content_type: "",
        content: "",
        content_text: "",
      },
    ];
    formik.setFieldValue("bonus_program_content", bonus_program_content);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  const handleContentChange = (editorState, index) => {
    const fieldName = `bonus_program_content[${index}][content]`;
    formik?.setFieldValue(fieldName, editorState);
  };

  const handleContentTextChange = (editorState, index) => {
    const fieldName = `bonus_program_content[${index}][content_text]`;
    formik?.setFieldValue(fieldName, editorState);
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
    handleAddField,
    orderList,
    handleContentChange,
    handleContentTextChange,
  };
};

export default useAddAddons;
