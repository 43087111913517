import { createSlice } from "@reduxjs/toolkit";

const today = new Date();
const startOfWeek = new Date(today); // Create a new Date object for the start of the week

// Set the start date to the previous Monday
startOfWeek.setDate(today.getDate() - 6);

const initialState = {
  currentPage: 1,
  detailCurrentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  detailCurrentPageSize: 10,
  startDate: startOfWeek,
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  detailSortBy: "",
  sortOrder: "desc",
  detailSortOrder: "desc",
  search: "",
  detailSearch: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  // agent_id: "",
  batch_id: "",
  status: "",
};

const payoutHistorySlice = createSlice({
  name: "payoutHistory",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.status = action.payload.status;
    },
    resetExtraFilters: (state, action) => {
      state.startDate = "";
      state.endDate = "";
      state.status = "";
    },
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  payoutHistorySlice.actions;

export default payoutHistorySlice.reducer;
