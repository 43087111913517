import {
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../../Global/OrderColumn";
import useStudentIssues from "./useStudentIssues";
import EmptyData from "../../../../Global/EmptyData";

const StudentIssues = () => {
  const {
    isFetching,
    issueList,
    paginationOptions,
    issueState,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
  } = useStudentIssues();
  
  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <div className={`pro-pb-5`}>
          {issueList?.data?.list?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={issueList?.data?.list || []}
              uniqueID={"id"}
              fields={issueList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={issueState?.clearSelection}
              perpage={issueState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>

        {issueList?.data?.list?.length > 0 && (
          <Pagination
            currentPage={issueState?.currentPage}
            totalPageCount={Math.ceil(
              issueList?.data?.total_count / issueState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(
                issueList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = issueList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={issueList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default StudentIssues;
