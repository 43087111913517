import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/TrainingVideo/trainingVideoSlice.js";

const useTrainingVideoForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.trainingVideo);

  const [imageThumbnailPreview, setImageCoverPreview] = useState(
    selectedItemsDetails ? selectedItemsDetails?.thumbnail : ""
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.thumbnail ?? "");
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Required"),
      description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .max(500, "max 500 characters allowed")
      .required("*Required"),
      video_url: Yup.string().required("*Required"),
      thumbnail: Yup.string().required("*Required"),
  });


  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
      ? selectedItemsDetails.id
      : "",
      
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      description:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.description
          : "",
      video_url:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.video_url
          : "",
      thumbnail: selectedItemsDetails !== ""
      ? selectedItemsDetails?.thumbnail
      : "",
      
      status: selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : '',
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        name: values.name,
        description: values.description,
        video_url: values.video_url,
        status: +values?.status
        
      };
      if(values?.thumbnail != "") {
        obj.thumbnail = values?.thumbnail;
      }
      
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        // formData.append("_method", "PUT");
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleThumbnailImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("thumbnail", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "thumbnail",
          "The Thumbnail image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError(
        "thumbnail",
        "The Thumbnail image must be less than 5MB in size."
      );
    }
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
    is_edit,
    handleThumbnailImage,
    imageThumbnailPreview
  };
};

export default useTrainingVideoForm;
