import { createSlice } from "@reduxjs/toolkit";

const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
  const startOfWeek = new Date(today); // Create a new Date object for the start of the week

  // Set the start date to the previous Monday
  startOfWeek.setDate(today.getDate() - 6);


const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: startOfWeek, 
  endDate: new Date(),
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  currentFormData: [null],
  isEditData: false,
  status: "",
  clearSelection: false,
  country_code: "INR"
};

const sellerDashboardSlice = createSlice({
  name: "sellerDashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = sellerDashboardSlice.actions;

export default sellerDashboardSlice.reducer;
