import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/program/programPriceSlice";
import { toast } from "react-toastify";
import { useGetGoalDataQuery } from "../../../../store/queries/goal";
import Assets from "../../../../assets/Assets";

import { ReactSVG } from 'react-svg'
import moment from 'moment';
import Style from "./userGoals.module.scss";

const useUserGoals = () => {
  const dispatch = useDispatch();
  const { programID } = useParams();
  const navigate = useNavigate();
  const activeProfile = programID ?? sessionStorage.getItem("active");
  const goalState = useSelector((state) => state.userGoals);
  const {
    data: goalList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetGoalDataQuery({
    customer_id: activeProfile,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
        navigate("/");
    }
  }, [isError]);

  const [daysToShow, setDaysToShow] = useState([]);

  useEffect(() => {
    if (goalList && goalList.data) {
      const processedData = goalList.data.map((item) => {
        if (item.customer_daily_goal) {
          let startDate = moment(item.customer_daily_goal.content_started_at);
          const watchedDates = item.customer_daily_goal.customer_daily_goal_logs.map(log => moment(log.watched_at));
          const days = [];

          if (item.customer_daily_goal.is_goal_restarted && item.customer_daily_goal.restart_date) {
            const restartDate = moment(item.customer_daily_goal.restart_date);
            const endDate = restartDate.clone().add(21, 'days');
            for (let date = startDate; date.isBefore(endDate); date.add(1, 'day')) {
              const isWatched = watchedDates.some(watchedDate => watchedDate.isSame(date, 'day'));
              days.push({ date: date.clone(), isWatched });
            }
          } else {
            for (let i = 0; i < 21; i++) {
              const date = startDate.clone().add(i, 'days');
              const isWatched = watchedDates.some(watchedDate => watchedDate.isSame(date, 'day'));
              days.push({ date, isWatched });
            }
          }

          return { ...item, days };
        }
        return item;
      });

      setDaysToShow(processedData);
    }
  }, [goalList]);

  const renderDays = (days) => {
    return days.map((day, index) => {
      const isFutureDate = day.date.isAfter(moment(), 'day');
      return (
        <div key={index} className={`${Style.day_list} ${isFutureDate ? Style.disabled : ''}  ${!isFutureDate && !day.isWatched ? Style.rejected : ''}`}>
          {/* <span className={Style.date}>{day.date.format('YYYY-MM-DD')}</span> */}
          <strong className={Style.day_month}>{day.date.format('DD')}</strong> 
          <span className={Style.day_week}>{day.date.format('ddd')}</span>
          <i className={Style.icon}>{!isFutureDate && (day.isWatched ? <ReactSVG src={Assets.CHECK_CIRCLE} /> : <ReactSVG src={Assets.CLOSE_CIRCLE} /> )}</i>
          
        </div>
      );
    });
  };

  return {
    isLoading,
    isFetching,
    goalList,
    goalState,
    refetch,
    daysToShow,
    renderDays
  };
};

export default useUserGoals;
