import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Assets from "../../../../assets/Assets";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import {
//   getProductDataByID,
//   updateConfig,
// } from "../../../store/slices/Orders/ordersSlice";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import { useGetBranchDataQuery } from "../../../../store/queries/branches";
import profileStyle from "./branchDetailLayout.module.scss";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";

const useBranchDetailLayout = () => {
  const { branch_id } = useParams();
  const { checkIfActiveRoute } = useRouteUtils();
  const activeProfile = branch_id ?? sessionStorage.getItem("active");
  const globalState = useSelector((state) => state.global);
  const location = useLocation();
  const savedBranchPermissions =
    JSON.parse(localStorage.getItem("branch_permissions")) || [];
  const {
    data: profileData,
    isFetching,
    isError,
    isSuccess,
  } = useGetBranchDataQuery({
    branch_id: activeProfile,
  });

  const label = {
    address: "Address",
    phone_number: "Phone Number",
    country: "Country",
    state: "State",
    district: "District",
  };

  const value = {
    address: ` ${profileData?.data?.address ?? "Not specified"}`,
    phone_number: ` ${profileData?.data?.phone_number ?? "Not specified"}`,
    country: ` ${profileData?.data?.country?.name ?? "Not specified"}`,
    state: ` ${profileData?.data?.state?.name ?? "Not specified"}`,
    district: ` ${profileData?.data?.district?.name ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const navigation = [
    {
      label: "Currency",
      title: "Currency",
      link: `/branches/branch-detail/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/branches/branch-detail/${activeProfile}`,
        true
      ),
    },
    {
      label: "Pincode",
      title: "Pincode",
      link: `/branches/branch-detail/pincode-detail/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/branches/branch-detail/pincode-detail/${activeProfile}`
      ),
    },
    {
      label: `Rate`,
      title: "Rate",
      link: `/branches/branch-detail/rate/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/branches/branch-detail/rate/${activeProfile}`
      ),
    },
  ];

  const branchTabPermission = useMemo(() => {
    let menus = savedBranchPermissions?.flatMap?.((menu) => [menu.label]);

    return navigation
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });

    //eslint-disable-next-line
  }, [globalState?.branch_permissions, location.pathname]);

  return {
    profileData,
    isFetching,
    basicDetails,
    navigation: branchTabPermission,
    activeProfile,
  };
};

export default useBranchDetailLayout;
