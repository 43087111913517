import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useReviews from "./useReviews";

const Reviews = () => {
  const { drawerMenu } = useReviews();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Reviews;
