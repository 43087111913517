import React from "react";
import { input, Button } from "@wac-ui-dashboard/wac_component_library";
import useBankInfo from "./useBankInfo";
import Select from "react-select";
import Style from "../manageProfileForm.module.scss";

const BankInfo = ({ closeModal, updateUserDetails }) => {
  const { handleCloseModal, formik } = useBankInfo({
    closeModal,
    updateUserDetails,
  });
  return (
    <div className={Style.form_wrap}>
      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Bank Account Name *
        </label>
        <input
          type="text"
          name="account_name"
          placeholder="Bank Account Name"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.account_name && formik.touched.account_name && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.account_name}
        />
        {formik.touched.account_name && formik.errors.account_name && (
          <div className="error-text">{formik.errors.account_name}</div>
        )}
      </div>

      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Bank Name *
        </label>
        <input
          type="text"
          name="bank_name"
          placeholder="Bank Name"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.bank_name && formik.touched.bank_name && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.bank_name}
        />
        {formik.touched.bank_name && formik.errors.bank_name && (
          <div className="error-text">{formik.errors.bank_name}</div>
        )}
      </div>

      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Bank Account Number *
        </label>
        <input
          type="text"
          name="bank_account"
          placeholder="Bank Account Number"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.bank_account && formik.touched.bank_account && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.bank_account}
        />
        {formik.touched.bank_account && formik.errors.bank_account && (
          <div className="error-text">{formik.errors.bank_account}</div>
        )}
      </div>
      <div className={`pro-mb-4 ${Style.form_input} input-wrap`}>
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          IFSC Code *
        </label>
        <input
          type="text"
          name="ifsc"
          placeholder="Bank Account Number"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.ifsc && formik.touched.ifsc && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik?.values?.ifsc}
        />
        {formik.touched.ifsc && formik.errors.ifsc && (
          <div className="error-text">{formik.errors.ifsc}</div>
        )}
      </div>

      <div className={` pro-d-flex `}>
        {/* <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button> */}

        <Button
          className={"pro-btn-primary lg pro-me-3"}
          onClick={formik?.handleSubmit}
          type="button"
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default BankInfo;
