import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// import Style from "../Students/students.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
// import Assets from "../../../assets/Assets";
import { updateConfig } from "../../../../store/slices/Students/studentsSlice";
import { toast } from "react-toastify";
import { useGetListDataQuery } from "../../../../store/queries/students";

const useStudents = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { agentId } = useParams();
  const navigate = useNavigate();
  // const [updateTableFields] = useUpdateStudentMyClassTableFieldsMutation();
  const activeProfile = agentId ?? sessionStorage.getItem("active");
  const studentState = useSelector((state) => state.students);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: studentList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetListDataQuery({
    agent_id: activeProfile,
    page_size: studentState?.currentPageSize,
    page: studentState?.currentPage,
    sort_by: studentState?.sortBy,
    sort_order: studentState?.sortOrder,
    search: studentState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (studentState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = studentState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (feild, data) => {
        return (
          <div
          >
              {data?.["country"]?.name}
          </div>
        );
      },
      
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    studentList,
    paginationOptions,
    studentState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    // updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
  };
};

export default useStudents;
