import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Image } from "@wac-ui-dashboard/wac_component_library";

// import Style from "../Students/students.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useGetProgramListDataQuery,
} from "../../../../store/queries/program";
// import Assets from "../../../assets/Assets";
import { updateConfig } from "../../../../store/slices/packages/packageModuleSlice";
import { toast } from "react-toastify";
import { useGetPackageBasicDataQuery } from "../../../../store/queries/packages";

const usePackageModules = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { packageID } = useParams();
  const navigate = useNavigate();
  // const [updateTableFields] = useUpdateStudentMyClassTableFieldsMutation();
  const activeProfile = packageID ?? sessionStorage.getItem("active");
  const myClassesState = useSelector((state) => state.programSession);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: packageData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetPackageBasicDataQuery(activeProfile);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  
  return {
    // isLoading,
    // isFetching,
    // tableFields,
    // showEditModal,
    // myClassesList,
    // paginationOptions,
    // myClassesState,
    // handlePagination,
    // handleClearClick,
    // handlePageSize,
    // handleSearch,
    // handleSort,
    // refetch,
    // getRow,
    // // updateTableFields,
    // closeEditModal,
    // handleEditColumnsClick,
    packageData,
    
  };
};

export default usePackageModules;
