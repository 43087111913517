import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     clearSelection: false,
     sortBy: "",
     sortOrder: "desc",
     search: "",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     clearSelection: false,
}

export const createNewDaily = createAsyncThunk(
  "new/createNewDaily",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-daily-meditation/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDaily = createAsyncThunk(
  "v1/admin-daily-meditation/update",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-daily-meditation/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMeditationDetails = createAsyncThunk(
  "v1/admin-daily-meditation/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/admin-daily-meditation/show/${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-daily-meditation/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-daily-meditation/delete`,params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dailyMeditationSlice = createSlice({
     name: "dailyMeditation",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     },
     extraReducers: (builder) => {
      builder
        .addCase(getMeditationDetails.fulfilled, (state, action) => {
          state.is_edit = true;
          state.selectedItemsDetails = action?.payload?.data?.data
        })
        
    },
})

export const { updateConfig } = dailyMeditationSlice.actions;

export default dailyMeditationSlice.reducer;