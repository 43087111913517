import React, {useState} from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const ReportedIssueFilterForm = ({ formik, handleReset }) => {
  
  const today = new Date();
  today.setDate(today.getDate() - 1); 
  const minDate = today.toISOString().split('T')[0];

  let statusList = [
    {
      id: "0",
      label: "Pending",
    },
    { id: "1", label: "Solved" },
  ];

  const [inputClasses, setInputClasses] = useState({
    expiry_from: "",
    expiry_to: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Update Formik field value
    formik.setFieldValue(name, value);

    // Update class based on whether a date is entered
    setInputClasses(prev => ({
      ...prev,
      [name]: value ? "date-selected" : ""
    }));
  };
  
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="status"
          options={statusList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.label}
          value={statusList?.filter((m) => formik.values.status === m.id)}
          onChange={(value) => formik.setFieldValue("status", value?.id)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          From Expiry date
        </label>
        <input
          className={`pro-input lg ${inputClasses.expiry_from}             `}
          type="date"
          name="start_date"
          max={minDate}
          onChange={handleChange}
          value={formik.values.start_date}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          To Expiry date
        </label>
        <input
          className={`pro-input lg ${inputClasses.expiry_to}`}
          type="date"
          name="end_date"
          min={formik.values.start_date}
          // max={new Date().toISOString().split("T")[0]}
          onChange={handleChange}
          value={formik.values.end_date}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={() => handleReset()}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ReportedIssueFilterForm;
