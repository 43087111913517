import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  description: "",
};

const termsAndConditionSlice = createSlice({
  name: "termsAndCondition",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = termsAndConditionSlice.actions;
export default termsAndConditionSlice.reducer;
