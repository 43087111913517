import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils.js";
import {
  docVerify,
  updateConfig,
} from "../../../../store/slices/Agent/agentSlice.js";
import { useGetAgentBasicDataQuery } from "../../../../store/queries/agent/index.js";

const useAgentDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const ProfileState = useSelector((state) => state.program);
  const { agentId } = useParams();
  const activeProfile = agentId ?? sessionStorage.getItem("active");
  const [showImageModal, setShowImageModal] = useState(false);
  const { showPayModal } = useSelector((state) => state.agent);

  const navigation = [
    {
      label: "My Students",
      title: "My Students",
      link: `/agent-details/students/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(
        `/agent-details/students/${activeProfile}`,
        true
      ),
    },
    {
      label: "Coupons",
      title: "Coupons",
      link: `/agent-details/coupons/${activeProfile}${window.location.search}`,
      active: checkIfActiveRoute(`/agent-details/coupons/${activeProfile}`),
    },
  ];

  const navigate = useNavigate();

  const {
    data: agentBasicData,
    isFetching,
    isError,
    refetch,
  } = useGetAgentBasicDataQuery({
    agent_id: activeProfile,
  });

  useEffect(() => {
    if (agentBasicData?.statusCode === 400 || agentBasicData === "") {
      toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [agentBasicData, activeProfile]);

  useEffect(() => {
    if (isError) {
      toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    first_name: "First Name",
    email: "Email",
    last_name: "Last Name",
  };

  const value = {
    first_name: `${
      agentBasicData?.data?.agent_data?.first_name ?? "Not specified"
    }`,
    last_name: `${
      agentBasicData?.data?.agent_data?.last_name ?? "Not specified"
    }`,

    email: `${agentBasicData?.data?.agent_data?.email ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.isEdit = true;
        state.selectedID = agentBasicData?.data?.student_detail?._id;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.isEdit = false;
        state.selectedID = {};
        state.imagePreview = "";
        state.imageName = "";
        state.studentData = [];
        state.educationLevel = [];
      })
    );
  };

  const handleDeleteModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };

  const handleViewImage = () => {
    setShowImageModal(() => true);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
  };

  const docAction = (val) => {
    const data = {
      status: val === "reject" ? 2 : 1,
      agent_id: activeProfile,
    };
    dispatch(docVerify(data))
      .unwrap()
      .then((result) => {
        if (result?.message === "Success") {
          toast.success(result?.message);
          refetch();
        } else {
          toast.error(result?.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleClickPayButton = () => {
    dispatch(
      updateConfig((state) => {
        state.showPayModal = true;
      })
    );
  };

  const handleClosePayButton = () => {
    dispatch(
      updateConfig((state) => {
        state.showPayModal = false;
      })
    );
  };

  return {
    activeProfile,
    isFetching,
    printRef,
    agentBasicData,
    basicDetails,
    ProfileState,
    navigation,
    showImageModal,
    showPayModal,
    handleClosePayButton,
    closeModal,
    handleClickPayButton,
    refetch,
    handleEditClick,
    handleDeleteModal,
    handleViewImage,
    closeImageModal,
    docAction,
  };
};

export default useAgentDetailsLayout;
