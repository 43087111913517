import { getAxiosInstance } from "../../../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/login", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const logout = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/logout", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/admin-auth/forgot-password", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/user/change/password`, params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const changePassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin-auth/forgot-password-change`,
      params
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const validateStudentEmail = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/v1/agent/students/otp/send", params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const validateStudentOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/v1/agent/students/otp/verify`, params);

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
