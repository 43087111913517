import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Image } from "@wac-ui-dashboard/wac_component_library";

// import Style from "../Students/students.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useGetProgramListDataQuery,
  useGetSessionDataQuery,
} from "../../../../store/queries/program";
// import Assets from "../../../assets/Assets";
import {
  getSessionDetails,
  updateConfig,
} from "../../../../store/slices/program/programSessionSlice";
import { toast } from "react-toastify";
import { deleteData } from "../../../../store/slices/program/programSessionSlice";

const useProgramSessions = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { studentID } = useParams();
  const navigate = useNavigate();
  // const [updateTableFields] = useUpdateStudentMyClassTableFieldsMutation();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const sessionState = useSelector((state) => state.programSession);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: sessionList = {},
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetSessionDataQuery({
    program_id: activeProfile,
    page_size: sessionState?.currentPageSize,
    page: sessionState?.currentPage,
    sort_by: sessionState?.sortBy,
    sort_order: sessionState?.sortOrder,
    search: sessionState?.search,
  });

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  useEffect(() => {
    if (sessionState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [sessionState.clearSelection]);

  useEffect(() => {
    if (isError) {
      toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (sessionState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = sessionState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.editProgram = false;
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(getSessionDetails(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };
  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({ id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    sessionList,
    paginationOptions,
    sessionState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    // updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
    handleCreateClick,
    closeModal,
    activeProfile,
    handleEditAction,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
  };
};

export default useProgramSessions;
