import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  tempPIds,
  isEdit
) => {
  const dispatch = useDispatch();
  const aboutUsState = useSelector((state) => state.packageForm);

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.package_price?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("package_price", data);
  };

  

  return {
    handleRemoveFieldCS,
    aboutUsState,
  };
};

export default useItemField;
