import { createSlice } from "@reduxjs/toolkit";
import { currency } from "../../queries/currency";

const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
  const startOfWeek = new Date(today); // Create a new Date object for the start of the week

  // Set the start date to the previous Monday
  startOfWeek.setDate(today.getDate() - 6);

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: startOfWeek, 
  endDate: new Date(),
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  currentFormData: [null],
  isEditData: false,
  status: "",
  clearSelection: false,
  currency: "INR",
  country_code: "INR",
  activeTab: "students"
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = dashboardSlice.actions;

export default dashboardSlice.reducer;
