import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentFormDataBuy: {},
  currentFormDataSell: {},
  selectedItemsDetails: "",
  selectedId: "",
  
  
  activeTab: "Buy",
  webId: "",
  completedTabs: {
    Buy: false,
    Sale: false,
  },
  CompletedPercentage: {
    Buy: 0,
    Sale: 0,
  },
};

export const getRateDetails = createAsyncThunk(
  "admin/branch/currency/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/branch/currency/edit?branch_currency_id=${id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRateDetails = createAsyncThunk(
  "/admin/branch/currency/update",
  async (values, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(`/admin/branch/currency/update`,values);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchRateFormSlice = createSlice({
  name: "branchRateForm",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRateDetails.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data
      // let formName = action.meta.arg.endpoint.split("-")[1];
      // let capitalizedFormName =
      //   formName.charAt(0).toUpperCase() + formName.slice(1);

      // state[`currentFormData${capitalizedFormName}`] = action.payload.data.data;
      // if (!!action.payload?.data?.data?.web_id) {
      //   state.webId = action.payload.data.data.web_id;
      // }
    });
  },
});

export const { updateFormData } = branchRateFormSlice.actions;

export default branchRateFormSlice.reducer;
